import React from "react";
import {
  Breadcrumb,
  Row,
  Col,
  Space,
  Card,
  DatePicker,
  Input,
  Button,
} from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { BrowserRouter as Router, NavLink } from "react-router-dom";

class NewRMReceipt extends React.Component {
  state = {
    size: "large",
  };

  render() {
    function onChange(date, dateString) {
      console.log(date, dateString);
    }
    const { size } = this.state;

    return (
      <div>
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>Logistics </Breadcrumb.Item>
          <Breadcrumb.Item>Assign Vehicle</Breadcrumb.Item>
        </Breadcrumb>
        <div className="site-layout-background">
          <h2 className="titles">
            ASSIGN <span>VEHICLE</span>
          </h2>

          <Row>
            <Col span={16}>
              <h2>I. Component Details</h2>

              <label>Component Name:</label>
              <Input placeholder="Component Name" />
              <label>Dispatch Quantity:</label>
              <Input placeholder="(in units)" />
              <label>Dispatch Quantity: </label>
              <Input placeholder="(in weight)" />
            </Col>
          </Row>
        </div>
        <div className="site-layout-background">
          <Row>
            <Col span={16}>
              <br></br>
              <h2>II. Select Transport Details</h2>
              <label>Pickup Point:</label>
              <Input placeholder="Pickup Point" />
              <label>Pickup Date:</label>
              <Input placeholder="Pickup Date" />
              <label>Drop Point:</label>
              <Input placeholder="Drop Point" />
              <label>Delivery Date:</label>
              <Input placeholder="Delivery Date" />
              <label>Heat / Batch No. </label>
              <Input placeholder="Heat / Batch No." />
              <label>Select Trsnporter </label>
              <Input placeholder="Select Trsnporter" />
              <label>Assign Vehicle </label>
              <Input placeholder="Assign Vehicle" />
              <Button type="secondary" shape="round" size={size}>
                CANCEL
              </Button>{" "}
              <Button type="primary" shape="round" size={size}>
                CREATE TRIP
              </Button>
            </Col>
          </Row>
          <br></br>
          <br></br>
        </div>
      </div>
    );
  }
}

export default NewRMReceipt;

import React, { useReducer, useState, useEffect } from "react";
import {
  Breadcrumb,
  Row,
  Col,
  Form,
  Input,
  Checkbox,
  Button,
  notification,
  Select,
} from "antd";
import { NavLink } from "react-router-dom";
import { getServerUrl } from "../config";
import axios from "axios";
const { Option } = Select;
const EditUser = (props) => {
  const { id } = props.match.params;
  const [form] = Form.useForm();
  const [checkedValues, setcheckedValues] = useState("");
  const [transport, settransport] = useState([""]);
  const [contractor, setcontractor] = useState([""]);
  const [driver, setdriver] = useState([""]);
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      id: "",
      first_name: "",
      last_name: "",
      usertype: "",
      email: "",
      number: "",
      password: "",
      linkto: "",
      linkname: "",
    }
  );

  useEffect(() => {
    axios
      .get(`${getServerUrl()}/${id}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const details = res.data;
        //console.log(details.data.t_id);
        if (res.data.success === 1) {
          setUserInput({
            id: details.data.id,
            first_name: details.data.firstName,
            last_name: details.data.lastName,
            usertype: details.data.userType,
            email: details.data.email,
            number: details.data.number,
            linkto: details.data.linkto,
            linkname: details.data.linkname,
          });
          setcheckedValues(details.data.t_id);
        } else {
          window.location.href = "/users";
        }
      });
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;
    setUserInput({ [name]: newValue });
  };

  function selecthandleChange(value) {
    setcheckedValues(value);
    userInput.usertype = value;
  }
  function selectlinktohandleChange(value) {
    userInput.linkto = value;
  }
  const openNotification = (type) => {
    notification[type]({
      message: "User Updated",
      description: "User details Updated successfully",
    });
  };
  const FailNotification = (type) => {
    notification[type]({
      message: "Error",
      description: "Please Fill out Required Fields",
    });
  };
  const onFinish = (values) => {
    console.log(userInput);
    if (userInput.first_name !== "") {
      axios
        .patch(`${getServerUrl()}/`, userInput, {
          headers: { "x-auth-token": localStorage.getItem("token") },
        })
        .then((res) => {
          const data = res.data;
          //console.log(data);
          if (data.success === 1) {
            form.resetFields();
            openNotification("success");
            window.location.reload();
          } else {
          }
        });
    } else {
      FailNotification("error");
    }
  };

  const onDelete = () => {
    axios
      .post(`${getServerUrl()}/deleteuser`, userInput, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const data = res.data;
        window.location.href = "/users";
      });
  };
  const onReset = () => {
    form.resetFields();
  };
  console.log(userInput);
  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Master Settings </Breadcrumb.Item>
        <Breadcrumb.Item>Create User</Breadcrumb.Item>
      </Breadcrumb>
      <Form form={form} name="control-hooks" onFinish={onFinish}>
        <div className="site-layout-background">
          <h2 className="titles">
            EDIT <span>USER</span>
          </h2>
          <Row>
            <Col span={16}>
              <h2>Users</h2>
              <label>First Name</label>
              <br></br>
              <Input
                name="first_name"
                value={userInput.first_name}
                onChange={handleChange}
              />
              <label>Last Name</label> <br></br>
              <Input
                name="last_name"
                value={userInput.last_name}
                onChange={handleChange}
              />
              <label>Email</label> <br></br>
              <Input
                name="email"
                value={userInput.email}
                onChange={handleChange}
              />
              <label>Phone </label>
              <span>(Only for record purpose. NOT for notification)</span>
              <Input
                name="number"
                value={userInput.number}
                onChange={handleChange}
              />
              <label>Password</label>
              <Input
                name="password"
                value={userInput.password}
                onChange={handleChange}
              />
              <label>User Type</label>
              <p>{userInput.usertype}</p>
              <br></br>
              <label>Link to</label>
              <br></br>
              <UserAccount
                type={userInput.usertype}
                linkto={userInput.linkto}
              ></UserAccount>
              <br></br> <br></br>
              <Button
                type="primary"
                shape="round"
                htmlType="submit"
                size="large"
                onClick={onFinish}
              >
                Update User
              </Button>{" "}
              <Button
                type="secondary"
                shape="round"
                size="large"
                onClick={onDelete}
              >
                Delete User
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};

export default EditUser;

const UserAccount = (props) => {
  const [name, setname] = useState("");
  if (props.type === "driver") {
    axios
      .get(`${getServerUrl()}/master/driver/${props.linkto}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const details = res.data;
        if (details.data) {
          setname(details.data.transporter_name);
        }
      });
  } else if (props.type === "contractor") {
    axios
      .get(`${getServerUrl()}/master/contractor/${props.linkto}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const details = res.data;
        if (details.data) {
          setname(details.data.contractor_name);
        }
      });
  }

  return <p>{name}</p>;
};

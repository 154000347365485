import React, { useReducer, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { getServerUrl } from "../config";
import axios from "axios";
import {
  Table,
  Space,
  Breadcrumb,
  Select,
  Input,
  Button,
  Row,
  Col,
  Tag,
  Modal,
} from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined, CheckCircleFilled } from "@ant-design/icons";
const { Option } = Select;

const OnGoingTrip = (props) => {
  const { id } = props.match.params;
  const [cancelModalVisible, setcancelModalVisible] = useState(false);
  const [data, setdata] = useState([""]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [transporter, settransporter] = useState([""]);
  const [vehicle, setvehicle] = useState([""]);
  const [driver, setdriver] = useState([""]);
  const [searchInput, setsearchInput] = useState([""]);
  const [searchText, setsearchText] = useState([""]);
  const [searchedColumn, setsearchedColumn] = useState([""]);
  const [dataValue, setdataValue] = useState([""]);
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      transporter: "",
      vehicle: "",
      driver: "",
      tripid: "",
      transid: "",
    }
  );
  const handleChange = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;
    setUserInput({ [name]: newValue });
  };

  const showModal = (transid, tripid) => {
    userInput.tripid = tripid;
    userInput.transid = transid;
    setIsModalVisible(true);
  };
  const cancelTrip = (transid, tripid, cs_id, instock) => {
    setcancelModalVisible(true);
    setdataValue({
      cs_id: cs_id,
      transid: transid,
      tripid: tripid,
      instock: instock,
    });
  };

  const handlecancelModalOk = () => {
    //console.log("dataValue", dataValue);
    if (dataValue.transid !== "" && dataValue.tripid !== "") {
      axios
        .post(`${getServerUrl()}/transcation/canceltrip/`, dataValue, {
          headers: { "x-auth-token": localStorage.getItem("token") },
        })
        .then((res) => {
          const data = res.data;
          if (data.success === 1) {
            window.location.reload();
          } else {
          }
        });
    }
    setcancelModalVisible(false);
  };
  const handlecancelModalCancel = () => {
    setcancelModalVisible(false);
  };

  const handleOk = () => {
    if (userInput.tripid !== "" && userInput.driver !== "") {
      axios
        .post(`${getServerUrl()}/transcation/updatetriptrans/`, userInput, {
          headers: { "x-auth-token": localStorage.getItem("token") },
        })
        .then((res) => {
          if (res.data.data) {
            let responsevalue = Object.values(res.data.data);
            const details = responsevalue;
            setdata(details);
          }
        });
      setIsModalVisible(false);
      window.location.reload();
    }
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/transcation/getongoingtrip/`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        if (res.data.data) {
          let responsevalue = Object.values(res.data.data);
          const details = responsevalue;
          setdata(details);
        }
      });
  }, []);
  /* Transport */
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/master/transport`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        if (res.data) {
          let responsevalue = res.data.data;
          const details = responsevalue;
          settransporter(details);
        } else {
          settransporter("");
        }
      });
  }, []);

  const TransporterOptions = transporter.map((dataDrop) => {
    return <Option value={dataDrop.t_id}>{dataDrop.transporter_name}</Option>;
  });
  function transporterChange(value) {
    userInput.transporter = value;
    axios
      .get(`${getServerUrl()}/master/getdriverbytransport/${value}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = res.data.data;
        const details = responsevalue;
        setdriver(details);
      });

    axios
      .get(`${getServerUrl()}/master/vehiclebytransporter/${value}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = res.data.data;
        const details = responsevalue;
        setvehicle(details);
      });
  }
  const VehicleOptions = vehicle.map((dataDrop) => {
    return (
      <Option value={dataDrop.vehicle_id}>
        {dataDrop.vehicle_type} - {dataDrop.vehicle_number}
      </Option>
    );
  });
  const DriverOptions = driver.map((dataDrop) => {
    return <Option value={dataDrop.d_id}>{dataDrop.driver_name}</Option>;
  });
  function vehicleChange(value) {
    userInput.vehicle = value;
  }
  function driverChange(value) {
    userInput.driver = value;
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setsearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        //setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setsearchedColumn(dataIndex);
    setsearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setsearchText("");
  };

  const columns = [
    {
      title: "Trip Date",
      dataIndex: "transdate",
      key: "transdate",
      ...getColumnSearchProps("transdate"),
    },
    {
      title: "Trip ID",
      dataIndex: "tripid",
      key: "tripid",
      render: (text, record) => (
        <Space size="middle">
          <Button type="secondary">{record.tripid}</Button>
        </Space>
      ),
      ...getColumnSearchProps("tripid"),
    },
    {
      title: "DC No",
      dataIndex: "transid",
      key: "transid",
      render: (text, record) => (
        <Space size="middle">
          <Button type="secondary">
            {record.tripid} / {record.transid}
          </Button>
        </Space>
      ),
      ...getColumnSearchProps("transid"),
    },
    {
      title: "Vendor DC",
      dataIndex: "vendor_dc",
      key: "vendor_dc",
      ...getColumnSearchProps("transid"),
    },
    {
      title: "Driver ",
      dataIndex: "driver_name",
      render: (text, record) => (
        <span>
          {record.vehicle_type} <br /> {record.vehicle_number} <br />
          <b>{record.driver_name}</b> <br /> {record.driver_phone}
        </span>
      ),
      ...getColumnSearchProps("driver_name"),
    },
    {
      title: "Source",
      dataIndex: "source_name",
      key: "source_name",
      ...getColumnSearchProps("source_name"),
    },

    {
      title: "Destination",
      dataIndex: "destination_name",
      key: "destination_name",
      render: (text, record) =>
        record.destination_name !== "" ? record.destination_name : "Sarang",
      ...getColumnSearchProps("destination_name"),
    },

    {
      title: "Component",
      dataIndex: "component_no",
      render: (text, record) => (
        <span>
          {record.component_no} <br /> {record.component_name}
        </span>
      ),
      ...getColumnSearchProps("component_no"),
    },
    {
      title: "Assigned Qty",
      dataIndex: "instock",
      key: "instock",
      ...getColumnSearchProps("instock"),
    },
    {
      title: "Source Confirmed Qty",
      dataIndex: "source_confirm_quantity",
      key: "source_confirm_quantity",
      ...getColumnSearchProps("source_confirm_quantity"),
    },
    {
      title: "Status",
      dataIndex: "transstatus",
      key: "transstatus",
      render: (text, record) =>
        record.transstatus ? (
          <Space size="middle">
            {record.transstatus !== "Unloaded"
              ? record.transstatus
              : record.transstatus === "Unloaded" && record.status === "1"
              ? "Reach Destination"
              : "Unloaded"}
          </Space>
        ) : (
          ""
        ),
    },
    {
      title: "Reassign",
      dataIndex: "transid",
      key: "transid",
      render: (text, record) =>
        record.transstatus ? (
          <Space size="middle">
            <Button
              type="secondary"
              onClick={() => showModal(record.transid, record.tripid)}
            >
              Reassign Trip
            </Button>
          </Space>
        ) : (
          ""
        ),
    },
    {
      title: "Cancel Trip",
      dataIndex: "transid",
      key: "transid",
      render: (text, record) =>
        record.transstatus === "Assigned" || record.transstatus === "Accept" ? (
          <Space size="middle">
            <Button
              type="secondary"
              onClick={() =>
                cancelTrip(
                  record.transid,
                  record.tripid,
                  record.cs_id,
                  record.instock
                )
              }
            >
              Cancel Trip
            </Button>
          </Space>
        ) : (
          ""
        ),
    },
    {
      title: "Download DC",
      dataIndex: "transid",
      key: "transid",
      render: (text, record) =>
        record.transstatus !== "Reject" && record.transstatus !== "Assigned" ? (
          <Space size="middle">
            <a
              href={"/dc/sarangdc.html?id=" + record.transid}
              target="_blank"
              type="secondary"
            >
              DOWNLOAD
            </a>
          </Space>
        ) : (
          ""
        ),
    },
  ];

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Logistics</Breadcrumb.Item>
        <Breadcrumb.Item>On Going Tripdetail </Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <h2 className="titles">
          ONGOING <span>TRIP</span>
        </h2>

        <Space></Space>
        <div style={{ textAlign: "right" }}>
          <NavLink to="/assign-transport" className="ant-btn sarangButton">
            Create a New Trip
          </NavLink>
        </div>

        <br />
        <Space></Space>
        <br />
        <Table scroll={{ x: 1300 }} columns={columns} dataSource={data}></Table>
      </div>
      <Modal
        title="Reassign the Trip"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <label>Transporter:</label>
        <br></br>
        <Select
          name="transporter"
          onChange={transporterChange}
          placeholder="Please Select Transporter"
          style={{ width: "250px" }}
        >
          {TransporterOptions}
        </Select>
        <br></br>
        <br></br>
        <label>Vehicle:</label>
        <br></br>
        <Select
          name="vehicle"
          onChange={vehicleChange}
          placeholder="Please Select Vehicle"
          style={{ width: "250px" }}
        >
          {VehicleOptions}
        </Select>
        <br></br>
        <br></br>
        <label>Driver:</label>
        <br></br>
        <Select
          name="driver_name"
          onChange={driverChange}
          placeholder="Please Select Transporter"
          style={{ width: "250px" }}
        >
          {DriverOptions}
        </Select>
        <br></br>
        <br></br>
      </Modal>
      <Modal
        title="Please Confirm"
        visible={cancelModalVisible}
        onOk={handlecancelModalOk}
        okText="Yes, Proceed"
        onCancel={handlecancelModalCancel}
        cancelText="No, Go Back"
      >
        <Row>
          <Col span={24}>
            <p
              style={{
                color: "#303030",
                fontSize: "22px",
                textAlign: "center",
              }}
            >
              <CheckCircleFilled
                style={{ fontSize: "36px", color: "#bc0067" }}
              />
              <br></br>
              Are you sure want to proceed?
            </p>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default OnGoingTrip;

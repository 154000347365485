import React, { useReducer, useState, useEffect } from "react";
import {
  Breadcrumb,
  Row,
  Col,
  Input,
  Button,
  Select,
  Checkbox,
  Form,
  Radio,
  notification,
  DatePicker,
  Modal,
  message,
} from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import { getServerUrl } from "../../config";
import axios from "axios";
const { Option } = Select;
const contractorlist = [];
const initialChildComponentList = [];
const initialpurchaseList = [];
const NewPurchase = () => {
  const [form] = Form.useForm();
  const [formone] = Form.useForm();
  const [formtwo] = Form.useForm();
  const [contractorList, setcontractorList] = React.useState(contractorlist);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [ComponentList, setComponentList] = React.useState(
    initialChildComponentList
  );
  const [component, setcomponent] = useState([""]);
  const [componentfamily, setcomponentfamily] = useState([""]);
  const [processValues, setprocessValues] = useState("");
  const [ProcessName, setProcessName] = useState("");
  const [PricingType, setPricingType] = useState("");
  const [process, setprocess] = useState([]);
  const [value, setValue] = React.useState(1);
  const [ChildName, setChildName] = useState("");

  const [PurchaseList, setPurchaseList] = React.useState(initialpurchaseList);
  const [BulkList, setBulkList] = React.useState(initialpurchaseList);
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      vendorname: "",
      pricing_type: "",
      component: "",
      bulkprocess: "",
      indiviualprocess: "",
      rate: "",
      effectivefrom: "",
      effectiveto: "",
      orders: "",
      processid: "",
      rorder: "",
    }
  );
  const handleChange = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;
    setUserInput({ [name]: newValue });
  };

  const onChange = (e) => {
    setValue(e.target.value);
    axios
      .get(
        `${getServerUrl()}/master/componentprocessdetail/${e.target.value}`,
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      )
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        userInput.indiviualprocess =
          responsevalue[0].processname + "  " + responsevalue[0].processdesc;
        userInput.processid = responsevalue[0].processid;
        userInput.rorder = responsevalue[0].rorder;
      });
  };
  const handleChangeNew = (e) => {
    setValue(e.target.value);
    axios
      .get(
        `${getServerUrl()}/master/componentprocessdetail/${e.target.value}`,
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      )
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        userInput.indiviualprocess =
          responsevalue[0].processname + "  " + responsevalue[0].processdesc;
        userInput.processid = responsevalue[0].processid;
        userInput.rorder = responsevalue[0].rorder;
      });
  };

  function bulkListinsert() {
    // eslint-disable-next-line no-lone-blocks
    {
      if (
        !BulkList.some((item) => item.rorder === userInput.rorder) &&
        userInput.rorder !== ""
      ) {
        setBulkList(
          BulkList.concat({
            id: Date.now(),
            processname: userInput.indiviualprocess,
            processid: userInput.processid,
            rorder: userInput.rorder,
          })
        );
      }
    }
  }

  function selecthandleChange(value) {
    setPricingType(value);
    userInput.pricing_type = value;
  }
  function onFromDateChange(date, dateString) {
    userInput.effectivefrom = dateString;
  }
  function onToDateChange(date, dateString) {
    console.log(date, dateString);
    date = dateString.split("-");
    var newDate = new Date(dateString);
    userInput.effectiveto = dateString;
  }
  const openNotification = (type) => {
    notification[type]({
      message: "Purchase Order Created",
      description: "Purchase Order Created successfully",
    });
  };
  const FailNotification = (type) => {
    notification[type]({
      message: "Error",
      description: "Please Fill out Required Fields",
    });
  };

  /* Contractor List */
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/master/contractorwithnopo`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        setcontractorList(details);
      });
  }, []);
  /* Contractor List */

  /*  Component */
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/master/componentfamily`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        setcomponentfamily(details);
      });
  }, []);

  function vendorNameChange(value) {
    userInput.vendorname = value;
    setPurchaseList(initialpurchaseList);
  }
  /*  Component Process */

  function ComponentFamilyChange(value) {
    userInput.indiviualprocess = "";
    userInput.processid = "";
    userInput.rorder = "";
    userInput.rate = "";
    formtwo.resetFields();
    setprocess(initialpurchaseList);
    setBulkList(initialpurchaseList);
    axios
      .get(`${getServerUrl()}/master/componentbyfamily/${value}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        setcomponent(details);
      });
  }
  function ComponentChange(value) {
    userInput.indiviualprocess = "";
    userInput.processid = "";
    userInput.rorder = "";
    userInput.rate = "";
    setProcessName("");
    setprocess(initialpurchaseList);
    setBulkList(initialpurchaseList);
    userInput.component = value;
    axios
      .get(
        `${getServerUrl()}/master/getpocompoent/${userInput.vendorname}/${
          userInput.component
        }`,
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      )
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        setprocess(details);
      });
    axios
      .get(`${getServerUrl()}/master/component/${userInput.component}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const details = res.data;
        setChildName(
          details.data.component_name + " " + details.data.component_no
        );
      });
  }

  /*  Add Purchase List */
  /* Child Component Change */
  if (BulkList.length && userInput.pricing_type !== "Individual Pricing") {
    axios
      .get(
        `${getServerUrl()}/master/getcpo/${userInput.component}/${
          BulkList[0].rorder
        }/${BulkList[BulkList.length - 1].rorder}`,
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      )
      .then((res) => {
        const details = res.data;
        if (details.data[0]) {
          setProcessName(
            details.data[0].process_name +
              "(" +
              details.data[0].rorder +
              ") - " +
              details.data[details.data.length - 1].process_name +
              "(" +
              details.data[details.data.length - 1].rorder +
              ")"
          );
        }
        console.log(ProcessName);
      });
  } else {
    axios
      .get(
        `${getServerUrl()}/master/getcpo/${userInput.component}/${
          userInput.rorder
        }/${userInput.rorder}`,
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      )
      .then((res) => {
        const details = res.data;
        if (res.data) {
          setProcessName(
            details.data[0].process_name + "(" + details.data[0].rorder + ")"
          );
        }
      });
  }

  const insertPuchaseList = () => {
    if (userInput.pricing_type !== "" && ProcessName !== "") {
      let Process;
      userInput.bulkprocess = processValues;
      if (
        userInput.pricing_type === "Individual Pricing" &&
        userInput.rate !== ""
      ) {
        Process = userInput.indiviualprocess;

        if (
          !PurchaseList.some(
            (item) =>
              item.component === userInput.component &&
              item.process_order === userInput.rorder
          )
        ) {
          setPurchaseList(
            PurchaseList.concat({
              id: Date.now(),
              pricing_type: userInput.pricing_type,
              component: userInput.component,
              componentName: ChildName,
              process: ProcessName,
              process_id: userInput.processid,
              process_order: userInput.rorder,
              end_process_id: userInput.processid,
              end_process_order: userInput.rorder,
              noofprocess: "1",
              rate: userInput.rate,
              effectivefrom: userInput.effectivefrom,
              effectiveto: userInput.effectiveto,
            })
          );
        }
      } else if (userInput.rate !== "") {
        BulkList.sort((a, b) => (a.rorder > b.rorder ? 1 : -1)).forEach(
          (items, index) => {
            if (
              !PurchaseList.some(
                (item) =>
                  item.component === userInput.component &&
                  item.process === ProcessName
              )
            ) {
              setPurchaseList(
                PurchaseList.concat({
                  id: Date.now(),
                  pricing_type: userInput.pricing_type,
                  component: userInput.component,
                  componentName: ChildName,
                  process: ProcessName,
                  process_id: BulkList[0].processid,
                  process_order: BulkList[0].rorder,
                  end_process_id: BulkList[BulkList.length - 1].processid,
                  end_process_order: BulkList[BulkList.length - 1].rorder,
                  noofprocess: BulkList.length,
                  rate: userInput.rate,
                  effectivefrom: userInput.effectivefrom,
                  effectiveto: userInput.effectiveto,
                })
              );
            }
          }
        );
        form.resetFields();
        setBulkList(initialpurchaseList);
      }
      userInput.child_component = "";
      userInput.child_component_quantity = "";
    }
  };
  const deletePurchase = (id) => {
    setPurchaseList(PurchaseList.filter((item) => item.id !== id));
  };

  const contractorOptions = contractorList.map((dataDrop) => {
    return (
      <Option value={dataDrop.contractor_id}>{dataDrop.contractor_name}</Option>
    );
  });

  const ComponentFamilyOptions = componentfamily.map((dataDrop) => {
    return <Option value={dataDrop.cf_id}>{dataDrop.component_family}</Option>;
  });

  const ComponentOptions = component.map((dataDrop) => {
    return (
      <Option value={dataDrop.component_id}>
        {dataDrop.component_name} - {dataDrop.component_no}
      </Option>
    );
  });
  const processOptions = process.map((dataDrop) => {
    return (
      <div>
        <Radio value={dataDrop.cp_id}>
          <b>{dataDrop.process_name}</b> - {dataDrop.processdesc} -{" "}
          {dataDrop.rorder}
        </Radio>
        <br></br>
      </div>
    );
  });
  const processRadioOptions = process.map((dataDrop) => {
    return (
      <div>
        <Radio value={dataDrop.cp_id}>
          <b>{dataDrop.process_name}</b> - {dataDrop.processdesc} -{" "}
          {dataDrop.rorder}
        </Radio>
        <br></br>
      </div>
    );
  });

  const handleSubmit = (e) => {
    userInput.orders = PurchaseList;
    e.preventDefault();
    if (userInput.orders.length > 0) {
      setIsConfirmModalVisible(true);
    } else {
      message.error("Please add atleast one component");
    }
  };

  const handleConfirmOk = () => {
    axios
      .post(`${getServerUrl()}/master/createpurchaseorder`, userInput, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const data = res.data;
        ////console.log(data);
        if (data.success === 1) {
          openNotification("success");
          window.location.href = "/purchase-list";
        } else {
        }
      });
    setIsConfirmModalVisible(false);
  };
  const handleConfirmCancel = () => {
    setIsConfirmModalVisible(false);
  };

  console.log("PurchaseList", PurchaseList);
  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Master Settings </Breadcrumb.Item>
        <Breadcrumb.Item>Purchase Settings</Breadcrumb.Item>
        <Breadcrumb.Item>Create New</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background">
        <h2 className="titles">
          ADD NEW <span>PURCHASE</span>
        </h2>
        <Row>
          <Col span={16}>
            <h2>I. Select Vendors</h2>
            <label>Vendor Name</label>
            <br></br>
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Select a Vendor"
              onChange={vendorNameChange}
            >
              {contractorOptions}
            </Select>
          </Col>
        </Row>
      </div>

      <div className="site-layout-background">
        <Form form={form}>
          <Row>
            <Col span={16}>
              <h2>II. Add Process</h2>
              <label>Pricing</label>
              <br></br>
              <Select
                name="contractor_type"
                onChange={selecthandleChange}
                placeholder="Please Select Pricing"
                style={{ width: "250px" }}
              >
                <Option value="Bulk Pricing">Bulk Pricing</Option>
                <Option value="Individual Pricing">Individual Pricing</Option>
              </Select>
              <Form layout="vertical" form={formone}>
                <Form.Item
                  name="componentfamily"
                  label="Select Component Family"
                >
                  <Select
                    onChange={ComponentFamilyChange}
                    placeholder="Select a Component Family"
                  >
                    {ComponentFamilyOptions}
                  </Select>
                </Form.Item>
              </Form>
              <Form layout="vertical" form={formtwo}>
                <Form.Item name="component" label="Select Component">
                  <Select
                    onChange={ComponentChange}
                    placeholder="Select a Component"
                  >
                    {ComponentOptions}
                  </Select>
                </Form.Item>
              </Form>
              <label>Select Process</label>
              <br></br>
              <div className={PricingType === "Bulk Pricing" ? "yes" : "no"}>
                <Radio.Group onChange={handleChangeNew} value={value}>
                  {processOptions}
                </Radio.Group>
                <br></br>
                <br></br>
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  onClick={bulkListinsert}
                >
                  Add to Group
                </Button>
                <br></br>
              </div>
              <ul className={PricingType === "Bulk Pricing" ? "yes" : "no"}>
                {BulkList &&
                  BulkList.sort((a, b) => (a.rorder > b.rorder ? 1 : -1)).map(
                    (list, i) => {
                      return (
                        <li key={i}>
                          <b>{list.rorder}</b> - {list.processname}
                        </li>
                      );
                    }
                  )}
                <br></br>{" "}
              </ul>
              {BulkList && PricingType === "Bulk Pricing"
                ? "No of Process: " + BulkList.length
                : ""}
              <div
                className={PricingType === "Individual Pricing" ? "yes" : "no"}
              >
                <Radio.Group onChange={onChange} value={value}>
                  {processRadioOptions}
                </Radio.Group>
              </div>
              <br></br>
              <br></br>
              <label>Rate</label>
              <Input
                placeholder="Rate"
                name="rate"
                value={userInput.rate}
                onChange={handleChange}
              />
              <br></br>
              <label>Effective From</label>
              <br></br>
              <DatePicker onChange={onFromDateChange} />
              <br></br>
              <label>Effective To</label>
              <br></br>
              <DatePicker onChange={onToDateChange} /> <br></br> <br></br>
              <Button
                type="primary"
                shape="round"
                size="large"
                onClick={insertPuchaseList}
              >
                Add Process
              </Button>
            </Col>
          </Row>
        </Form>
      </div>

      <div className="site-layout-background">
        <Row>
          <Col span={16}>
            <h2>Process</h2>
            <div
              className="ant-table-content"
              style={{ overflow: "auto hidden" }}
            >
              <table
                style={{
                  width: "100%",

                  tableLayout: "auto",
                }}
              >
                <tr>
                  <th>Pricing Type</th>
                  <th>Component</th>
                  <th>Process</th>
                  <th>Rate</th>
                  <th>No of Process</th>
                  <th>Effective From</th>
                  <th>Effective To</th>
                </tr>
                {PurchaseList.map((item) => (
                  <tr key={item.id}>
                    <td>{item.pricing_type}</td>
                    <td>{item.componentName}</td>
                    <td>{item.process}</td>
                    <td>{item.rate}</td>
                    <td>{item.noofprocess}</td>
                    <td>{item.effectivefrom}</td>
                    <td>{item.effectiveto}</td>
                    <td>
                      <button onClick={() => deletePurchase(item.id)}>X</button>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
            <br></br>
            <Button
              type="primary"
              shape="round"
              size="large"
              onClick={handleSubmit}
            >
              Create Purchase Order
            </Button>
          </Col>
        </Row>
      </div>
      <Modal
        title="Please Confirm"
        visible={isConfirmModalVisible}
        onOk={handleConfirmOk}
        okText="Yes, Proceed"
        onCancel={handleConfirmCancel}
        cancelText="No, Go Back"
      >
        <Row>
          <Col span={24}>
            <p
              style={{
                color: "#303030",
                fontSize: "22px",
                textAlign: "center",
              }}
            >
              <CheckCircleFilled
                style={{ fontSize: "36px", color: "#bc0067" }}
              />
              <br></br>
              Are you sure want to proceed?
            </p>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default NewPurchase;

import React, { useReducer, useEffect, useState } from "react";
import {
  Breadcrumb,
  Row,
  Col,
  Input,
  Form,
  Button,
  Select,
  Modal,
  notification,
  Table,
  Space,
  TimePicker,
  Result,
  message,
} from "antd";
import moment from "moment";
import {
  PlusCircleFilled,
  CheckCircleFilled,
  CloseCircleFilled,
  RollbackOutlined,
} from "@ant-design/icons";
import { getServerUrl } from "../../config";
import axios from "axios";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
const initialList = [];
const { Option } = Select;
const AssignTransport = () => {
  const [form] = Form.useForm();
  const [formnew] = Form.useForm();
  const [formtwo] = Form.useForm();
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [transporter, settransporter] = useState([""]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [roisModalVisible, setroisModalVisible] = useState(false);
  const [isChangeVisible, setisChangeVisible] = useState(false);
  const [contractor, setcontractor] = useState([]);
  const [Values, setValues] = useState("");
  const [vehicle, setvehicle] = useState([""]);
  const [driver, setdriver] = useState([""]);
  const [vendorPOlist, setvendorPOlist] = useState([""]);
  const [CPOlist, setCPOlist] = useState([""]);
  const [pickupList, setpickupList] = useState(initialList);
  const [CurrentQuantity, setCurrentQuantity] = useState([""]);
  const [readycomponent, setreadycomponent] = useState(initialList);
  const [processList, setprocessList] = useState(initialList);
  const [contractorlist, setcontractorlist] = useState(initialList);
  const [filtercontractorlist, setfiltercontractorlist] = useState(initialList);
  const [touchedcomponent, settouchedcomponent] = useState(initialList);
  const [FilterReadyComponent, setFilterReadyComponent] = useState(initialList);
  const [nextprocess, setnextprocess] = useState(initialList);
  const [searchInput, setsearchInput] = useState([""]);
  const [searchText, setsearchText] = useState([""]);
  const [searchedColumn, setsearchedColumn] = useState([""]);
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      rawmaterialtype: "",
      rawmaterial: "",
      rawvariant: "",
      rawbatch: "",
      contractor: "",
      rli_id: "",
      rmi_id: "",
      quantity: "",
      transporter: "",
      vehicle: "",
      driver: "",
      batch_no: "",
      current_contractor_id: "",
      current_component_name: "",
      complete_process: "",
      complete_process_order: "",
      component: "",
      componentName: "",
      purchase_item_id: "",
      rate: "",
      process: "",
      no_of_process: "",
      starting_process_id: "",
      start_process_rorder: "",
      end_process_id: "",
      end_process_rorder: "",
      pricing_type: "",
      component_id: "",
      component_name: "",
      component_no: "",
      component_weight: "",
      current_contractor_address: "",
      droptime: "",
      pickuptime: "",
      process_id: "",
      rorder: "",
      order: "",
      nextprocessorder: "",
      total_quantity: "",
      current_stock: "",
      used_stock: "",
      readystock: "",
      pickupList: "",
      readycomponent: "",
      aftertransit: "",
      in_transit: "",
      total_weight: "",
      rework: "",
    }
  );
  const handleChange = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;
    setUserInput({ [name]: newValue });
  };
  /* Transport */
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/master/transport`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = res.data.data;
        const details = responsevalue;
        if (details) {
          settransporter(details);
        }
      });
  }, []);

  const TransporterOptions = transporter.map((dataDrop) => {
    return <Option value={dataDrop.t_id}>{dataDrop.transporter_name}</Option>;
  });
  function transporterChange(value) {
    userInput.transporter = value;
    axios
      .get(`${getServerUrl()}/master/getdriverbytransport/${value}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = res.data.data;
        const details = responsevalue;
        setdriver(details);
      });
    axios
      .get(`${getServerUrl()}/master/vehiclebytransporter/${value}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = res.data.data;
        const details = responsevalue;
        setvehicle(details);
        // console.log(vehicle);
      });
  }
  const VehicleOptions = vehicle.map((dataDrop) => {
    return (
      <Option value={dataDrop.vehicle_id}>
        {dataDrop.vehicle_type} - {dataDrop.vehicle_number}
      </Option>
    );
  });
  const DriverOptions = driver.map((dataDrop) => {
    return <Option value={dataDrop.d_id}>{dataDrop.driver_name}</Option>;
  });
  function vehicleChange(value) {
    userInput.vehicle = value;
  }
  function driverChange(value) {
    userInput.driver = value;
  }
  function ContractorFilterChange(value) {
    if (value !== "All") {
      var newlist = readycomponent.filter(
        (List) => Number(List.contractor) === Number(value)
      );
      setFilterReadyComponent(newlist);
    } else {
      setFilterReadyComponent(readycomponent);
    }
  }
  const addJob = (
    cid,
    order,
    quantity,
    completed,
    remainstock,
    aftertransit,
    in_transit,
    cs_id
  ) => {
    setIsModalVisible(true);
    setValues(cid);
    setCurrentQuantity(quantity);
    order = Number(order);
    userInput.order_type = "Order Task";
    userInput.component = cid;
    userInput.cs_id = cs_id;
    userInput.current_stock = remainstock;
    userInput.used_stock = completed;
    userInput.aftertransit = aftertransit;
    userInput.in_transit = in_transit;
    userInput.purchase_item_id = "";
    axios
      .get(
        `${getServerUrl()}/inventory/getcomponentprocessbyporder/${cid}/${order}`,
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      )
      .then((res) => {
        let responsevalue = res.data.data;
        const details = responsevalue;
        const nextid = Number(details[0].porder) + 1;
        axios
          .get(
            `${getServerUrl()}/inventory/getcomponentnextprocess/${cid}/${nextid}`,
            {
              headers: { "x-auth-token": localStorage.getItem("token") },
            }
          )
          .then((res) => {
            let responsevalue = res.data.data;
            const details = responsevalue;
            if (details.length) {
              setnextprocess(details);
              userInput.process_id = details[0].process_id;
              userInput.process_name = details[0].process_name;
              userInput.rorder = details[0].rorder;
              userInput.nextprocessorder = details[0].rorder;
              GetContractor(details[0].process_id);
            } else {
              setnextprocess("");
              userInput.process_id = "";
              userInput.process_name = "";
              userInput.rorder = "";
              GetContractor("");
            }
          });
      });

    axios
      .get(`${getServerUrl()}/inventory/getcomponentstockbyid/${cs_id}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = res.data.data;
        const details = responsevalue;
        if (responsevalue) {
          userInput.component_id = details[0].component_id;
          userInput.component_name = details[0].component_name;
          userInput.component_no = details[0].component_no;
          userInput.component_weight = details[0].rawmaterial_weight;
          userInput.complete_process = details[0].completed_process;
          userInput.complete_process_order = details[0].porder;
          userInput.current_contractor_id = details[0].contractor_id;
          userInput.current_contractor_name = details[0].contractor_name;
          userInput.current_contractor_address = details[0].address;
          userInput.total_quantity = details[0].quantity;
          userInput.readystock = details[0].readystock;
        }
      });
  };

  // Reassign Task

  const ReassignTask = (
    cid,
    order,
    quantity,
    completed,
    remainstock,
    aftertransit,
    in_transit,
    cs_id
  ) => {
    setroisModalVisible(true);
    setValues(cid);
    setCurrentQuantity(quantity);
    order = Number(order);
    userInput.order_type = "Order Task";
    userInput.component = cid;
    userInput.cs_id = cs_id;
    userInput.current_stock = remainstock;
    userInput.used_stock = completed;
    userInput.aftertransit = aftertransit;
    userInput.in_transit = in_transit;
    userInput.purchase_item_id = "";
    axios
      .get(
        `${getServerUrl()}/inventory/getcomponentlowprocessbyporder/${cid}/${order}`,
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      )
      .then((res) => {
        if (res.data.data) {
          let responsevalue = Object.values(res.data.data);
          const details = responsevalue;
          setprocessList(details);
          // console.log("Low Process", details);
        }
      });
    axios
      .get(`${getServerUrl()}/inventory/getcomponentstockbyid/${cs_id}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = res.data.data;
        const details = responsevalue;
        if (responsevalue) {
          userInput.component_id = details[0].component_id;
          userInput.component_name = details[0].component_name;
          userInput.component_no = details[0].component_no;
          userInput.component_weight = details[0].rawmaterial_weight;
          userInput.complete_process = details[0].completed_process;
          userInput.complete_process_order = details[0].porder;
          userInput.current_contractor_id = details[0].contractor_id;
          userInput.current_contractor_name = details[0].contractor_name;
          userInput.current_contractor_address = details[0].address;
          userInput.total_quantity = details[0].quantity;
          userInput.readystock = details[0].readystock;
        }
      });
  };

  // get Contractor
  function GetContractor(value) {
    if (value !== 0) {
      axios
        .get(`${getServerUrl()}/inventory/getprocesscontractor/${value}`, {
          headers: { "x-auth-token": localStorage.getItem("token") },
        })
        .then((res) => {
          if (res.data.data) {
            let responsevalue = Object.values(res.data.data);
            const details = responsevalue;
            setcontractor(details);
          }
        });
    }
  }
  const ContractorOptions = contractor.map((dataDrop) => {
    return (
      <Option value={dataDrop.contractor_id} key={dataDrop.contractor_id}>
        {dataDrop.contractor_id} - {dataDrop.contractor_name}
      </Option>
    );
  });

  const LowProcessList = processList.map((dataDrop) => {
    return (
      <Option value={dataDrop.process_id} key={dataDrop.rorder}>
        {dataDrop.rorder} - {dataDrop.process_name}
      </Option>
    );
  });

  function processChange(value) {
    userInput.start_process_rorder = value;
    // console.log(value, userInput);
    if (value && value !== "") {
      axios
        .get(`${getServerUrl()}/inventory/getcontractorofprocess/${value}`, {
          headers: { "x-auth-token": localStorage.getItem("token") },
        })
        .then((res) => {
          let responsevalue = Object.values(res.data.data);
          const details = responsevalue;
          setcontractor(details);
        });
    }
  }

  // Regular Order Contractor Change
  function ContractorChange(value) {
    setCPOlist([""]);
    userInput.contractor = value;
    userInput.rework = "normal";
    formtwo.resetFields();
    userInput.purchase_item_id = "";
    if (value && value !== "Sarang") {
      axios
        .get(`${getServerUrl()}/master/getactiveitemsofvendor/${value}`, {
          headers: { "x-auth-token": localStorage.getItem("token") },
        })
        .then((res) => {
          let responsevalue = Object.values(res.data.data);
          const details = responsevalue;
          // console.log(details);
          if (details.length) {
            setCPOlist(
              details.filter(
                (List) =>
                  Number(List.component) === Number(userInput.component) &&
                  Number(List.start_process_rorder) ===
                    Number(userInput.nextprocessorder)
              )
            );
          } else {
            setCPOlist([""]);
          }
        });
      axios
        .get(`${getServerUrl()}/master/contractor/${value}`, {
          headers: { "x-auth-token": localStorage.getItem("token") },
        })
        .then((res) => {
          if (res.data.data) {
            let responsevalue = res.data.data;
            const details = responsevalue;
            userInput.contractor_id = details.contractor_id;
            userInput.contractor_name = details.contractor_name;
            userInput.contractor_address = details.address;
          }
        });
      userInput.vendorname = value;
    } else {
      userInput.contractor_id = "Sarang";
      userInput.contractor_name = "Sarang";
      userInput.contractor_address = "";
    }
  }

  // Reassign Contractor Change
  function ReassignContractorChange(value) {
    setCPOlist([""]);
    userInput.contractor = value;
    userInput.rework = "Rework";
    formtwo.resetFields();
    userInput.purchase_item_id = "";
    if (value && value !== "Sarang") {
      axios
        .get(`${getServerUrl()}/master/getactiveitemsofvendor/${value}`, {
          headers: { "x-auth-token": localStorage.getItem("token") },
        })
        .then((res) => {
          let responsevalue = Object.values(res.data.data);
          const details = responsevalue;

          if (details.length) {
            setCPOlist(
              details.filter(
                (List) => Number(List.component) === Number(userInput.component)
              )
            );
          } else {
            setCPOlist([""]);
          }
        });
      // console.log(CPOlist);
      axios
        .get(`${getServerUrl()}/master/contractor/${value}`, {
          headers: { "x-auth-token": localStorage.getItem("token") },
        })
        .then((res) => {
          if (res.data.data) {
            let responsevalue = res.data.data;
            const details = responsevalue;
            userInput.contractor_id = details.contractor_id;
            userInput.contractor_name = details.contractor_name;
            userInput.contractor_address = details.address;
          }
        });
      userInput.vendorname = value;
    } else {
      userInput.contractor_id = "Sarang";
      userInput.contractor_name = "Sarang";
      userInput.contractor_address = "";
    }
  }

  function handleChangeNew(value) {
    var newlist = CPOlist.filter(
      (List) => Number(List.purchase_item_id) === Number(value)
    );
    newlist.map((item) => {
      userInput.componentName = item.componentName;
      userInput.component = item.component;
      userInput.purchase_item_id = item.purchase_item_id;
      userInput.rate = item.rate;
      userInput.process = item.process;
      userInput.no_of_process = item.no_of_process;
      userInput.starting_process_id = item.starting_process_id;
      userInput.start_process_rorder = item.start_process_rorder;
      userInput.end_process_id = item.end_process_id;
      userInput.end_process_rorder = item.end_process_rorder;
      userInput.pricing_type = item.pricing_type;
    });
  }

  function DropTimeChange(time, timeString) {
    userInput.droptime = timeString;
  }
  function PickUpTimeChange(time, timeString) {
    userInput.pickuptime = timeString;
  }

  const handleOk = () => {
    if (
      Number(userInput.readystock) - Number(userInput.in_transit) >=
        Number(userInput.quantity) &&
      userInput.quantity !== "" &&
      /*Number(userInput.aftertransit) >= Number(userInput.quantity)*/
      userInput.purchase_item_id !== ""
    ) {
      if (userInput.contractor_id !== "" && userInput.contractor_name !== "") {
        setpickupList(
          pickupList.concat({
            id: Date.now(),
            component_id: userInput.component_id,
            component_name: userInput.component_name,
            component_batch: userInput.batch_no,
            component_quantity: userInput.quantity,
            cs_id: userInput.cs_id,
            component_no: userInput.component_no,
            process_id: userInput.process_id,
            reworkStatus: userInput.rework,
            process_name:
              userInput.rework === "Rework"
                ? userInput.process_name
                : userInput.process_name + "Rework",
            complete_process: userInput.complete_process,
            complete_process_order: userInput.complete_process_order,
            process_order: userInput.rorder,
            contractor_id: userInput.contractor_id,
            contractor_name: userInput.contractor_name,
            contractor_address: userInput.contractor_address,
            current_contractor_id: userInput.current_contractor_id,
            current_contractor_name: userInput.current_contractor_name,
            current_contractor_address: userInput.current_contractor_address,
            weight: userInput.component_weight * userInput.quantity,
            droptime: userInput.droptime,
            pickuptime: userInput.pickuptime,
            componentName: userInput.componentName,
            component: userInput.component,
            purchase_item_id: userInput.purchase_item_id,
            rate: userInput.rate,
            process:
              userInput.rework === "Rework"
                ? userInput.process + "- Rework"
                : userInput.process,
            no_of_process: userInput.no_of_process,
            starting_process_id: userInput.starting_process_id,
            start_process_rorder: userInput.start_process_rorder,
            end_process_id: userInput.end_process_id,
            end_process_rorder: userInput.end_process_rorder,
            pricing_type: userInput.pricing_type,
          })
        );
      }
      userInput.current_stock =
        Number(userInput.aftertransit) - Number(userInput.quantity);
      userInput.used_stock =
        Number(userInput.in_transit) + Number(userInput.quantity);
      setreadycomponent(
        readycomponent.map((item) => {
          if (item.cs_id === userInput.cs_id) {
            return {
              ...item,
              in_transit: userInput.used_stock,
              aftertransit: userInput.current_stock,
            };
          } else {
            return item;
          }
        })
      );
      setFilterReadyComponent(readycomponent);
    }
    userInput.quantity = "";
    userInput.component = "";
    setIsModalVisible(false);
    setroisModalVisible(false);
    formnew.resetFields();
    formtwo.resetFields();
  };
  console.log("pickupList", pickupList);
  const deletepickupList = (id) => {
    pickupList
      .filter((x) => x.id === id)
      .map((x) => updateStock(x.cs_id, x.component_quantity));
    setpickupList(pickupList.filter((item) => item.id !== id));
  };

  function updateStock(cs_id, quantity) {
    setreadycomponent(
      readycomponent.map((item) => {
        if (item.cs_id === cs_id) {
          return {
            ...item,
            in_transit: Number(item.in_transit) - Number(quantity),
            aftertransit: Number(item.aftertransit) + Number(quantity),
          };
        } else {
          return item;
        }
      })
    );
  }

  const handleCancel = () => {
    userInput.quantity = "";
    userInput.component = "";
    setIsModalVisible(false);
    setisChangeVisible(false);
    setroisModalVisible(false);
    setCPOlist([""]);
    formnew.resetFields();
    formtwo.resetFields();
  };

  /* Open Notifications */
  const openNotification = (type) => {
    notification[type]({
      message: "Trip Created",
      description: "Trip created successfully",
      placement: "bottomRight",
    });
  };

  useEffect(() => {
    axios
      .get(`${getServerUrl()}/inventory/getcomponentstocktrip/`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = res.data.data;
        const details = responsevalue;
        setreadycomponent(details);
        setcontractorlist(details);
        const result = [];
        const map = new Map();
        for (const item of details) {
          if (!map.has(item.contractor)) {
            map.set(item.contractor, true); // set any value to Map
            result.push({
              text: item.contractor_name,
              value: item.contractor_name,
            });
          }
        }
        setfiltercontractorlist(result);
        setFilterReadyComponent(details);
      });
  }, []);

  //console.log("filtercontractorlist", filtercontractorlist);

  /* const ContractorList = filtercontractorlist.map((dataDrop) => {
    return (
      <Option value={dataDrop.id} key={dataDrop.id}>
        {dataDrop.name}
      </Option>
    );
  });*/
  const handleSubmit = () => {
    userInput.pickupList = pickupList;
    userInput.readycomponent = readycomponent;
    if (pickupList.length <= 0) {
      message.error("Please add atleast one trip to transporter");
    }
    if (
      userInput.transporter !== "" &&
      userInput.vehicle !== "" &&
      userInput.driver !== "" &&
      userInput.readycomponent !== "" &&
      pickupList.length > 0
    ) {
      setIsConfirmModalVisible(true);
    } else {
      message.error("please select transporter information");
    }
  };

  const handleConfirmOk = () => {
    axios
      .post(`${getServerUrl()}/transcation/createtripsheet`, userInput, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const data = res.data;
        if (data.success === 1) {
          openNotification("success");
          setInterval(() => (window.location.href = "/ongoingtrip/"), 1000);
        } else {
        }
      });
    setIsConfirmModalVisible(false);
  };
  const handleConfirmCancel = () => {
    setIsConfirmModalVisible(false);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setsearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        //setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setsearchedColumn(dataIndex);
    setsearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setsearchText("");
  };

  const columns = [
    {
      title: "Contractor Name",
      dataIndex: "contractor_name",
      key: "contractor_name",
      filters: filtercontractorlist,
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      onFilter: (value, record) => record.contractor_name.indexOf(value) === 0,
    },
    /* {
      title: "Contractor Address",
      dataIndex: "address",
      key: "address",
    },
     {
      title: "Batch",
      dataIndex: "batch_no",
      key: "batch_no",
    },
    {
      title: "Rec Date",
      dataIndex: "create_date",
      key: "create_date",
    }, */
    {
      title: "Date",
      dataIndex: "updatedtime",
      key: "updatedtime",
      ...getColumnSearchProps("updatedtime"),
    },
    {
      title: "Component No",
      dataIndex: "component_no",
      render: (text, record) => record.component_no,
      ...getColumnSearchProps("component_no"),
    },
    {
      title: "Component Name",
      dataIndex: "component_name",
      render: (text, record) => record.component_name,
      ...getColumnSearchProps("component_name"),
    },
    {
      title: "Completed Process",
      dataIndex: "processName",
      key: "processName",
      ...getColumnSearchProps("processName"),
    },
    /* {
      title: "Opening Stock",
      dataIndex: "quantity",
      key: "quantity",
    }, */
    {
      title: "Ready Stock",
      dataIndex: "readystock",
      key: "readystock",
      ...getColumnSearchProps("readystock"),
    },

    /*{
      title: "Closing Stock",
      dataIndex: "remainstock",
      key: "remainstock",
    }, */
    {
      title: "In Transit",
      dataIndex: "in_transit",
      key: "in_transit",
    },
    {
      title: "Delivered",
      dataIndex: "completed",
      key: "completed",
    },
    {
      title: "Component. Weight",
      dataIndex: "rawmaterial_weight",
      key: "rawmaterial_weight",
    },
    {
      title: "Approx. Weight",
      dataIndex: "rawmaterial_weight",
      key: "rawmaterial_weight",
      render: (text, record) => (
        <Space size="middle">
          {(
            Number(record.quantity) * Number(record.rawmaterial_weight)
          ).toFixed(2)}
        </Space>
      ),
    },
    {
      title: "Add to Trip",
      dataIndex: "rawmaterial_weight",
      key: "rawmaterial_weight",
      fixed: "right",
      render: (text, record) =>
        Number(record.readystock) > Number(record.in_transit) ? (
          <PlusCircleFilled
            style={{ fontSize: "2em", color: "#bc0067" }}
            onClick={() =>
              addJob(
                record.component_id,
                record.porder,
                record.quantity,
                record.completed,
                record.remainstock,
                record.aftertransit,
                record.in_transit,
                record.cs_id
              )
            }
          />
        ) : (
          <PlusCircleFilled style={{ fontSize: "2em", color: "#000000" }} />
        ),
    },
    // {
    //   title: "Reassign Task",
    //   dataIndex: "rawmaterial_weight",
    //   key: "rawmaterial_weight",
    //   fixed: "right",
    //   render: (text, record) =>
    //     Number(record.readystock) > Number(record.in_transit) &&
    //     record.type == "rework" ? (
    //       <RollbackOutlined
    //         style={{ fontSize: "2em", color: "#bc0067" }}
    //         onClick={() =>
    //           ReassignTask(
    //             record.component_id,
    //             record.porder,
    //             record.quantity,
    //             record.completed,
    //             record.remainstock,
    //             record.aftertransit,
    //             record.in_transit,
    //             record.cs_id
    //           )
    //         }
    //       />
    //     ) : (
    //       ""
    //     ),
    // },
  ];

  function onChange(pagination, filters, sorter, extra) {
    // console.log("params", pagination, filters, sorter, extra);
  }
  const processOptions = CPOlist.map((dataDrop) => {
    if (Number(dataDrop.purchase_item_id) > 0) {
      return (
        <Option value={dataDrop.purchase_item_id}>
          {dataDrop.pricing_type} - {dataDrop.process}
        </Option>
      );
    }
  });

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Inventory</Breadcrumb.Item>
        <Breadcrumb.Item>Assign Transport</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background">
        <h2 className="titles">CREATING TRIP</h2>

        <Row>
          {" "}
          <Col span={24}>
            <h2>I. Select Transportation Details </h2>
          </Col>
          <Col xs={24} xl={8}>
            <label>Transporter:</label>
            <br></br>
            <Select
              name="transporter"
              onChange={transporterChange}
              placeholder="Please Select Transporter"
              style={{ width: "250px" }}
            >
              {TransporterOptions}
            </Select>
            <br></br>
            <br></br>
          </Col>
          <Col xs={24} xl={8}>
            <label>Vehicle:</label>
            <br></br>
            <Select
              name="vehicle"
              onChange={vehicleChange}
              placeholder="Please Select Vehicle"
              style={{ width: "250px" }}
            >
              {VehicleOptions}
            </Select>
            <br></br>
            <br></br>
          </Col>
          <Col xs={24} xl={8}>
            <label>Driver:</label>
            <br></br>
            <Select
              name="driver_name"
              onChange={driverChange}
              placeholder="Please Select Transporter"
              style={{ width: "250px" }}
            >
              {DriverOptions}
            </Select>
            <br></br>
            <br></br>
          </Col>
          <Col xs={24} xl={24}>
            <h2>II. Select & Add Ready Components</h2>
          </Col>
          {/* <Col xs={24} xl={8}>
            <label>Filter by Contractor:</label>
            <br></br>
            <Select
              placeholder="Please Select Contractor"
              style={{ width: "250px" }}
              onChange={ContractorFilterChange}
            >
              <Option value="All">All</Option>
              {ContractorList}
            </Select>
            <br></br>
            <br></br>
  </Col> */}
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={readycomponent}
              scroll={{ x: 1300 }}
              onChange={onChange}
            />{" "}
          </Col>
          <Col xs={24} xl={24}>
            <h2>III. Trip Summary</h2>

            <br></br>
            <div
              className="ant-table-content"
              style={{ overflow: "auto hidden" }}
            >
              <table
                style={{
                  width: "100%",

                  tableLayout: "auto",
                }}
              >
                <tr>
                  <th>PO_ID</th>
                  <th>Pickup Location</th>
                  <th>Drop Location</th>
                  <th>Component</th>
                  <th>Quantity</th>
                  <th>Total. Weight</th>
                  <th>Pickup Time</th>
                  <th>Drop Time</th>
                </tr>
                {pickupList &&
                  pickupList.map((item) => (
                    <tr key={item.id}>
                      <td>{item.purchase_item_id}</td>
                      <td>{item.current_contractor_name}</td>
                      <td>{item.contractor_name}</td>
                      <td>
                        {item.component_no} {item.component_name}
                      </td>
                      <td>{item.component_quantity}</td>
                      <td>{item.weight}</td>
                      <td>{item.pickuptime}</td>
                      <td>{item.droptime}</td>
                      <td>{item.effectiveto}</td>
                      <td>
                        <button onClick={() => deletepickupList(item.id)}>
                          X
                        </button>
                      </td>
                    </tr>
                  ))}
              </table>
            </div>
            <br></br>
            <Button
              type="primary"
              shape="round"
              size="large"
              onClick={handleSubmit}
            >
              Create Trip
            </Button>
            <div></div>
          </Col>
        </Row>
        <Modal
          title={"Add Destination"}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          {nextprocess &&
            nextprocess.map((item) => (
              <div>
                <p style={{ color: "#303030" }}>
                  Process Name: <b>{item.process_name}</b>
                </p>
                <p style={{ color: "#303030" }}>
                  Process Desc: <b>{item.processdesc}</b>
                </p>
                <p style={{ color: "#303030" }}>
                  Process Order:<b>{item.rorder}</b>
                </p>

                <Form layout="vertical" form={formnew}>
                  <Form.Item name="Contractor" label="Vendor Name">
                    <Select
                      placeholder="Select a Contractor"
                      onChange={ContractorChange}
                    >
                      <Option value="">Please Select Contractor</Option>
                      {ContractorOptions}
                    </Select>
                  </Form.Item>
                </Form>
                <Form layout="vertical" form={formtwo}>
                  <Form.Item name="Select Process" label="Select Process">
                    <Select onChange={handleChangeNew}>
                      <option value="">Select Process</option>
                      {processOptions}
                    </Select>
                  </Form.Item>
                  <Form.Item name="Quantity" label="Quantity">
                    Available Ready Qty.{" "}
                    <b style={{ color: "green" }}>
                      {Number(userInput.readystock) -
                        Number(userInput.in_transit)}
                    </b>
                    <Input
                      name="quantity"
                      placeholder="Quantity"
                      value={userInput.quantity}
                      onChange={handleChange}
                    ></Input>
                  </Form.Item>
                  <p>
                    {Number(userInput.readystock) -
                      Number(userInput.in_transit) <
                    Number(userInput.quantity)
                      ? "Component stock quantity less than input quantity"
                      : ""}
                    <br></br>

                    {userInput.aftertransit < Number(userInput.quantity) &&
                    userInput.in_transit !== "0"
                      ? "component quantity already in transit"
                      : ""}
                  </p>
                  <Form.Item name="Pickup Time" label="Pickup Time">
                    <TimePicker
                      use12Hours
                      format="h:mm A"
                      defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                      onChange={PickUpTimeChange}
                    />
                  </Form.Item>
                  <Form.Item name="Drop Time" label="Drop Time">
                    <TimePicker
                      use12Hours
                      format="h:mm A"
                      defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                      onChange={DropTimeChange}
                    />
                  </Form.Item>
                </Form>
              </div>
            ))}
          {/* nextprocess === "" ? (
            <div>
              <p style={{ color: "#303030" }}>
                Process Name: <b>Final Inspection</b>
              </p>

              <p style={{ color: "#303030" }}>
                <Select
                  placeholder="Select a Contractor"
                  onChange={ContractorChange}
                >
                  <Option value="Sarang">Sarang</Option>
                </Select>
              </p>
              <Form layout="vertical" form={form}>
                <Form.Item name="Quantity" label="Quantity">
                  <Input
                    name="quantity"
                    placeholder="Quantity"
                    value={userInput.quantity}
                    onChange={handleChange}
                  ></Input>
                </Form.Item>
                <p>
                  {Number(userInput.readystock) - Number(userInput.in_transit) <
                  Number(userInput.quantity)
                    ? "Component stock quantity less than input quantity"
                    : ""}
                  <br></br>
                  {userInput.aftertransit < Number(userInput.quantity) &&
                  userInput.in_transit !== "0"
                    ? "component quantity already in transit"
                    : ""}
                </p>
                <Form.Item name="Pickup Time" label="Pickup Time">
                  <TimePicker
                    use12Hours
                    format="h:mm A"
                    defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                    onChange={PickUpTimeChange}
                  />
                </Form.Item>
                <Form.Item name="Drop Time" label="Drop Time">
                  <TimePicker
                    use12Hours
                    format="h:mm A"
                    defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                    onChange={DropTimeChange}
                  />
                </Form.Item>
              </Form>
            </div>
          ) : (
            ""
          ) */}
        </Modal>

        <Modal
          title={"Reassign Task"}
          visible={roisModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form layout="vertical" form={formnew}>
            <Form.Item name="Process" label="Process Name">
              <Select placeholder="Select a Process" onChange={processChange}>
                <Option value="">Please Select Process</Option>
                {LowProcessList}
              </Select>
            </Form.Item>
          </Form>
          <Form layout="vertical" form={formnew}>
            <Form.Item name="Contractor" label="Vendor Name">
              <Select
                placeholder="Select a Contractor"
                onChange={ReassignContractorChange}
              >
                <Option value="">Please Select Contractor</Option>
                {ContractorOptions}
              </Select>
            </Form.Item>
          </Form>
          <Form layout="vertical" form={formtwo}>
            <Form.Item name="Select Process" label="Select Process">
              <Select onChange={handleChangeNew}>
                <option value="">Select Process</option>
                {processOptions}
              </Select>
            </Form.Item>
            <Form.Item name="Quantity" label="Quantity">
              Available Ready Qty.{" "}
              <b style={{ color: "green" }}>
                {Number(userInput.readystock) - Number(userInput.in_transit)}
              </b>
              <Input
                name="quantity"
                placeholder="Quantity"
                value={userInput.quantity}
                onChange={handleChange}
              ></Input>
            </Form.Item>
            <p>
              {Number(userInput.readystock) - Number(userInput.in_transit) <
              Number(userInput.quantity)
                ? "Component stock quantity less than input quantity"
                : ""}
              <br></br>

              {userInput.aftertransit < Number(userInput.quantity) &&
              userInput.in_transit !== "0"
                ? "component quantity already in transit"
                : ""}
            </p>
            <Form.Item name="Pickup Time" label="Pickup Time">
              <TimePicker
                use12Hours
                format="h:mm A"
                defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                onChange={PickUpTimeChange}
              />
            </Form.Item>
            <Form.Item name="Drop Time" label="Drop Time">
              <TimePicker
                use12Hours
                format="h:mm A"
                defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                onChange={DropTimeChange}
              />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Please Confirm"
          visible={isConfirmModalVisible}
          onOk={handleConfirmOk}
          okText="Yes, Proceed"
          onCancel={handleConfirmCancel}
          cancelText="No, Go Back"
        >
          <Row>
            <Col span={24}>
              <p
                style={{
                  color: "#303030",
                  fontSize: "22px",
                  textAlign: "center",
                }}
              >
                <CheckCircleFilled
                  style={{ fontSize: "36px", color: "#bc0067" }}
                />
                <br></br>
                Are you sure want to proceed?
              </p>
            </Col>
          </Row>
        </Modal>
      </div>
    </div>
  );
};

export default AssignTransport;

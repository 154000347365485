import React from "react";
import { Breadcrumb, Row, Col, Space, Card } from "antd";
import { BrowserRouter as Router, NavLink } from "react-router-dom";
function RMReceipt() {
  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Inventory</Breadcrumb.Item>
        <Breadcrumb.Item>RM Receipt Note</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <h2 className="titles">
          RM RECEIPT NOTE <span>MANAGEMENT</span>
        </h2>
        <p>Select View</p>
        <Row>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/new-rm-receipts">
                <span className="icons icon-new-receipt"></span>
                NEW RECEIPT NOTE
              </NavLink>
            </div>
          </Col>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/rm-receipts">
                <span className="icons icon-view-all-receipt"></span>
                VIEW ALL RECEIPT NOTES
              </NavLink>
            </div>
          </Col>
        </Row>
        <br></br>
        <br></br>
      </div>
    </div>
  );
}

export default RMReceipt;

import React, { useReducer, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { DownloadOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Row,
  Col,
  Input,
  Button,
  Select,
  Form,
  notification,
  DatePicker,
  Modal,
  message,
} from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import { getServerUrl } from "../../config";
import axios from "axios";
const { Option } = Select;
const contractorlist = [];
const initialList = [];
const NewRMReceipt = () => {
  const [form] = Form.useForm();
  const [formnew] = Form.useForm();
  const [formone] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [contractorList, setcontractorList] = React.useState(contractorlist);
  const [purchaseList, setpurchaseList] = React.useState(contractorlist);
  const [rawmaterial, setrawmaterial] = useState([""]);
  const [rawmaterialvariant, setrawmaterialvariant] = useState(initialList);
  const [rawmaterialvariantname, setrawmaterialvariantname] = useState([""]);
  const [currentQty, setcurrentQty] = useState("");
  const [listValue, setlistValue] = useState(initialList);
  const [list, setList] = React.useState(initialList);
  const [mlist, setmlist] = React.useState(initialList);
  const [errormessage, seterrormessage] = useState([""]);
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      vendorname: "",
      raw_material_diameter: "",
      quantity: "",
      mquantity: "",
      invoice_number: "",
      invoice_date: "",
      purchaseorderid: "",
      dc_number: "",
      heatnumber: "",
      rawmaterial_name: "",
      marawmaterial_name: "",
      rawmaterialprice: "",
      rawmaterialvariantname: "",
      rawmaterialquantity: "",
      lineitem: "",
      materialid: "",
      raw_material_id: "",
      price: "",
      purchaseorder: "",
      rpoli_id: "",
      currentQuantity: "",
      remainquantity: "",
      usedquantity: "",
      materialquantity: "",
      unit_type: "",
    }
  );
  function onChange(date, dateString) {
    userInput.invoice_date = dateString;
  }
  const handleChange = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;
    setUserInput({ [name]: newValue });
    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (format.test(userInput.heatnumber)) {
      message.error("No Special Character allowed");
      seterrormessage("No Special Character allowed");
    } else {
      seterrormessage("");
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  /* Contractor List */
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/inventory/rawmaterialsupplier/`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        setcontractorList(details);
      });
  }, []);

  const contractorOptions = contractorList.map((dataDrop) => {
    return (
      <Option value={dataDrop.contractor_id}>{dataDrop.contractor_name}</Option>
    );
  });

  /* Purchase List Material */
  function PurchaseOrderList(value) {
    form.resetFields();
    formone.resetFields();
    setlistValue(initialList);
    userInput.vendorname = value;
    axios
      .get(
        `${getServerUrl()}/inventory/getrmpurchaseorderbycontractor/${value}`,
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      )
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        setpurchaseList(details);
      });
    setList(initialList);
  }

  const purchaseOptions = purchaseList.map((dataDrop) => {
    return <Option value={dataDrop.p_id}>{dataDrop.vendorinvoice}</Option>;
  });

  function purchaseOrderChange(value) {
    setlistValue(initialList);
    userInput.purchaseorderid = value;
    if (value !== "manual") {
      axios
        .get(`${getServerUrl()}/inventory/getrawmaterialbypo/${value}`, {
          headers: { "x-auth-token": localStorage.getItem("token") },
        })
        .then((res) => {
          let responsevalue = Object.values(res.data.data);
          const details = responsevalue;
          setrawmaterial(details);
        });
    } else {
      axios
        .get(`${getServerUrl()}/inventory/getmanualrawmaterial/`, {
          headers: { "x-auth-token": localStorage.getItem("token") },
        })
        .then((res) => {
          let responsevalue = Object.values(res.data.data);
          const details = responsevalue;
          setrawmaterial(details);
        });
    }
    form.resetFields();
  }

  const rawOptions = rawmaterial.map((dataDrop) => {
    return (
      <Option value={dataDrop.materialid}>{dataDrop.raw_material_name}</Option>
    );
  });

  const manualrawOptions = rawmaterial.map((dataDrop) => {
    return (
      <Option value={dataDrop.raw_material_id}>
        {dataDrop.raw_material_name}
      </Option>
    );
  });

  /* Raw Change */
  function rawChange(value) {
    setlistValue(initialList);
    axios
      .get(
        `${getServerUrl()}/inventory/rawmaterial/${
          userInput.purchaseorderid
        }/${value}`,
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      )
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        setrawmaterialvariant(details);
      });
    userInput.rawmaterial_name = value;
    setcurrentQty("");
  }
  function ManualrawChange(value) {
    setlistValue(initialList);
    if (value) {
      axios
        .get(`${getServerUrl()}/master/rawmaterial/${value}`, {
          headers: { "x-auth-token": localStorage.getItem("token") },
        })
        .then((res) => {
          let responsevalue = Object.values(res.data);
          const details = responsevalue;
          setmlist(details);
          console.log("mlist", mlist);
        });
      userInput.raw_material_id = value;
    }
  }
  if (mlist.length > 0) {
    mlist.map((valuedetail) => {
      console.log(valuedetail.raw_material_name);
      userInput.marawmaterial_name = valuedetail.raw_material_name;
    });
  }

  const rawvariantOptions = rawmaterialvariant.map((dataDrop) => {
    return <Option value={dataDrop.rpoli_id}>{dataDrop.variant}</Option>;
  });
  function rawvariantChange(value) {
    setlistValue(initialList);
    if (value) {
      userInput.raw_material_diameter = value;
      axios
        .get(`${getServerUrl()}/inventory/rawmaterialprice/${value}`, {
          headers: { "x-auth-token": localStorage.getItem("token") },
        })
        .then((res) => {
          let responsevalue = Object.values(res.data.data);
          const details = responsevalue;
          setlistValue(details);
        });
      axios
        .get(`${getServerUrl()}/inventory/getpoitemquantity/${value}`, {
          headers: { "x-auth-token": localStorage.getItem("token") },
        })
        .then((res) => {
          let responsevalue = Object.values(res.data.data);
          const details = responsevalue;
          details.map((detail) => {
            if (detail.quantity) {
              userInput.currentQuantity = Number(detail.quantity);
              setcurrentQty(
                Number(userInput.rawmaterialquantity) -
                  Number(userInput.currentQuantity)
              );
            } else {
              userInput.currentQuantity = 0;
              setcurrentQty(
                Number(userInput.rawmaterialquantity) -
                  Number(userInput.currentQuantity)
              );
            }
          });
        });
    }
  }

  if (listValue.length > 0) {
    listValue.map((valuedetail) => {
      userInput.purchaseorder = valuedetail.p_id;
      userInput.rpoli_id = valuedetail.rpoli_id;
      userInput.materialid = valuedetail.materialid;
      userInput.rawmaterial_name = valuedetail.raw_material_name;
      userInput.remainquantity = valuedetail.poremainquantity;
      userInput.usedquantity = valuedetail.pousedquantity;
      userInput.materialquantity = valuedetail.quantity;
      userInput.rawmaterialprice = valuedetail.price;
      userInput.rawmaterialvariantname = valuedetail.variant;
      userInput.rawmaterialquantity = valuedetail.quantity;
      userInput.unit_type = valuedetail.raw_material_type_unit;
    });
  } else {
    userInput.purchaseorder = "";
    userInput.rpoli_id = "";
    userInput.materialid = "";
    userInput.rawmaterial_name = "";
    userInput.remainquantity = "";
    userInput.usedquantity = "";
    userInput.materialquantity = "";
    userInput.rawmaterialprice = "";
    userInput.rawmaterialvariantname = "";
    userInput.rawmaterialquantity = "";
    userInput.unit_type = "";
  }

  const insertLineItem = () => {
    if (
      userInput.rawmaterial_name !== "" &&
      userInput.quantity !== "" &&
      Number(userInput.remainquantity) >= Number(userInput.quantity)
    ) {
      if (
        !list.some((item) => item.rpoli_id === userInput.rpoli_id) &&
        !list.some((item) => item.heatnumber === userInput.heatnumber)
      ) {
        setList(
          list.concat({
            id: Date.now(),
            purchaseorder: userInput.purchaseorder,
            rpoli_id: userInput.rpoli_id,
            p_id: userInput.p_id,
            materialid: userInput.materialid,
            materialname: userInput.rawmaterial_name,
            variant: userInput.rawmaterialvariantname,
            quantity: userInput.quantity,
            price: userInput.rawmaterialprice,
            heatnumber: userInput.heatnumber,
            unit_type: userInput.unit_type,
            total:
              Number(userInput.rawmaterialprice) * Number(userInput.quantity),
          })
        );
      }
      form.resetFields();
      userInput.currentQuantity = "";
      setcurrentQty("");
      userInput.quantity = "";
    } else {
      message.error(
        "Quantity should be less than or equal to remaining quantity"
      );
    }
  };
  const insertManualLineItem = () => {
    if (userInput.mquantity !== "" && userInput.price !== "") {
      setList(
        list.concat({
          id: Date.now(),
          purchaseorder: "",
          rpoli_id: "",
          materialid: userInput.raw_material_id,
          materialname: userInput.marawmaterial_name,
          variant: "",
          quantity: userInput.mquantity,
          price: userInput.price,
          heatnumber: "",
          unit_type: "",
          total: Number(userInput.mquantity) * Number(userInput.price),
        })
      );
      form.resetFields();
    }
  };

  const deleteLineItme = (id) => {
    setList(list.filter((item) => item.id !== id));
  };
  const openNotification = (type) => {
    notification[type]({
      message: "Raw Material Receipt Note Added",
      description: "Raw Material Receipt Note added successfully",
    });
  };
  const FailNotification = (type) => {
    notification[type]({
      message: "Error",
      description: "Please Fill out Required Fields",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (list.length > 0) {
      userInput.lineitem = list;
    } else {
      message.error("Please enter atleast one quantity");
    }
    if (
      list.length > 0 &&
      userInput.vendorname !== "" &&
      userInput.dc_number !== "" &&
      userInput.invoice_date !== "" &&
      userInput.invoice_number !== ""
    ) {
      console.log(userInput);
      setIsConfirmModalVisible(true);
    } else {
      message.error("Please enter all require fields");
    }
  };

  const handleConfirmOk = () => {
    axios
      .post(`${getServerUrl()}/inventory/createinvoice`, userInput, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const data = res.data;
        //console.log(data);
        if (data.success === 1) {
          openNotification("success");
          window.setTimeout(function () {
            window.location.href = "/rm-receipts";
          }, 3000);
        } else {
        }
      });
    setIsConfirmModalVisible(false);
  };
  const handleConfirmCancel = () => {
    setIsConfirmModalVisible(false);
  };

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Inventory</Breadcrumb.Item>
        <Breadcrumb.Item>RM Receipt Note</Breadcrumb.Item>
        <Breadcrumb.Item>Create New</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background">
        <h2 className="titles">
          CREATE NEW RECEIPT <span>NOTE</span>
        </h2>

        <Row>
          <Col span={16}>
            <h2>I. Add Supplier Details</h2>

            <label>Supplier Name:</label>
            <br></br>
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Supplier Name"
              onChange={PurchaseOrderList}
            >
              {contractorOptions}
            </Select>
            <br></br>
            <label>Supplier Invoice No:</label>
            <Input
              placeholder="Supplier Invoice No"
              name="invoice_number"
              value={userInput.invoice_number}
              onChange={handleChange}
            />
            <label>Supplier Invoice Date: </label>
            <br></br>
            <DatePicker format={"DD-MM-YYYY"} onChange={onChange} />
            <br></br>
            <br></br>
            <label>Supplier DC No: </label>
            <Input
              placeholder="Supplier DC No"
              name="dc_number"
              value={userInput.dc_number}
              onChange={handleChange}
            />
          </Col>
        </Row>
      </div>
      <div className="site-layout-background">
        <Row>
          <Col span={16}>
            <br></br>
            <h2>II. Raw Material Details</h2>
            <label>Select Purchase Order</label>
            <br></br>
            <Form layout="vertical" form={formone}>
              <Form.Item name="Purchase Order">
                <Select
                  name="purchase_name"
                  onChange={purchaseOrderChange}
                  placeholder="Please Select Purchase Order"
                  style={{ width: "250px" }}
                >
                  <Option value="manual">Manual Products</Option>
                  {purchaseOptions}
                </Select>
              </Form.Item>
            </Form>
            <Form
              form={form}
              name="control-hooks"
              className={
                userInput.purchaseorderid === "manual" ? "display-none" : ""
              }
            >
              <label>Select Raw Material</label>
              <Form.Item name="rawmaterial_name">
                <Select
                  name="rawmaterial_name"
                  onChange={rawChange}
                  placeholder="Please Select Rawmaterial"
                  style={{ width: "250px" }}
                >
                  {rawOptions}
                </Select>
              </Form.Item>
              <label>Diameter / Variant</label>
              <Form.Item name="rawmaterial_variant">
                <Select
                  name="rawmaterial_variant"
                  onChange={rawvariantChange}
                  placeholder="Please Select Diameter"
                  style={{ width: "250px" }}
                >
                  {rawvariantOptions}
                </Select>
              </Form.Item>
              <label>Heat No</label>
              <Form.Item name="heatnumber">
                <Input
                  placeholder="Heat No."
                  name="heatnumber"
                  value={userInput.heatnumber}
                  onChange={handleChange}
                />
              </Form.Item>
              <p style={{ color: "red" }}>{errormessage}</p>
              <label>Quantity </label>
              <Form.Item name="quantity">
                <Input
                  placeholder="Quantity"
                  name="quantity"
                  value={userInput.quantity}
                  onChange={handleChange}
                />
              </Form.Item>

              {userInput.quantity &&
              Number(userInput.remainquantity) < Number(userInput.quantity) ? (
                <p style={{ color: "red" }}>
                  Quantity should be less than or equal to remaining quantity
                </p>
              ) : (
                ""
              )}
              <br></br>
              {listValue.length > 0 ? (
                <table style={{ fontSize: "18px" }}>
                  <tr>
                    <td>
                      <b>PO Quantity </b>
                    </td>
                    <td>:</td>
                    <td>
                      {Number(userInput.materialquantity)
                        ? Number(userInput.materialquantity)
                        : ""}{" "}
                      {userInput.unit_type}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Used Quantity</b>
                    </td>
                    <td>:</td>
                    <td>
                      {Number(userInput.usedquantity) > 0
                        ? Number(userInput.usedquantity)
                        : "0"}{" "}
                      {userInput.unit_type}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Remaining Quantity</b>
                    </td>
                    <td>:</td>
                    <td>
                      {Number(userInput.remainquantity)
                        ? Number(userInput.remainquantity)
                        : "0"}{" "}
                      {userInput.unit_type}
                    </td>
                  </tr>
                </table>
              ) : (
                ""
              )}
              <br></br>
              <Button
                type="primary"
                shape="round"
                size="large"
                onClick={insertLineItem}
              >
                Add Line Item
              </Button>
            </Form>
            <Form
              form={formnew}
              name="control-hooks"
              className={
                userInput.purchaseorderid === "manual" ? "" : "display-none"
              }
            >
              <label>Select Raw Material</label>
              <Form.Item name="rawmaterial_name">
                <Select
                  name="rawmaterial_name"
                  onChange={ManualrawChange}
                  placeholder="Please Select Rawmaterial"
                  style={{ width: "250px" }}
                >
                  {manualrawOptions}
                </Select>
              </Form.Item>

              <label>Price</label>
              <Form.Item name="price">
                <Input
                  placeholder="Price"
                  name="price"
                  value={userInput.price}
                  onChange={handleChange}
                />
              </Form.Item>

              <label>Quantity </label>
              <Form.Item name="mquantity">
                <Input
                  placeholder="Quantity"
                  name="mquantity"
                  value={userInput.mquantity}
                  onChange={handleChange}
                />
              </Form.Item>
              <br></br>

              <Button
                type="primary"
                shape="round"
                size="large"
                onClick={insertManualLineItem}
              >
                Add Line Item
              </Button>
            </Form>
          </Col>
        </Row>

        <br></br>
        <br></br>
      </div>
      <div className="site-layout-background">
        <Row>
          <table
            style={{
              width: "100%",

              tableLayout: "auto",
            }}
          >
            <tr>
              <th>Material Name</th>
              <th>Diameter</th>
              <th>Heat No</th>
              <th>Price</th>
              <th>Quantity</th>
              <th>Total</th>
            </tr>

            {list
              .sort((a, b) => a.order - b.order)
              .map((item) => (
                <tr key={item.id}>
                  <td>{item.materialname}</td>
                  <td>{item.variant}</td>
                  <td>{item.heatnumber}</td>
                  <td>{item.price}</td>
                  <td>
                    {item.quantity} {item.unit_type}
                  </td>
                  <td>{item.total}</td>
                  <td>
                    <button onClick={() => deleteLineItme(item.id)}>X</button>
                  </td>
                </tr>
              ))}
          </table>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <Button
            type="primary"
            shape="round"
            size="large"
            onClick={handleSubmit}
          >
            Create Receipt Note
          </Button>
        </Row>
      </div>
      <Modal
        title="Quantity Alert"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Item Quantity Greater than PO Quantity</p>
      </Modal>

      <Modal
        title="Please Confirm"
        visible={isConfirmModalVisible}
        onOk={handleConfirmOk}
        okText="Yes, Proceed"
        onCancel={handleConfirmCancel}
        cancelText="No, Go Back"
      >
        <Row>
          <Col span={24}>
            <p
              style={{
                color: "#303030",
                fontSize: "22px",
                textAlign: "center",
              }}
            >
              <CheckCircleFilled
                style={{ fontSize: "36px", color: "#bc0067" }}
              />
              <br></br>
              Are you sure want to proceed?
            </p>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default NewRMReceipt;

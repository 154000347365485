import React, { useReducer, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { DownloadOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Row,
  Col,
  Input,
  Button,
  Select,
  notification,
  DatePicker,
  Form,
} from "antd";

import { getServerUrl } from "../../config";
import axios from "axios";
const initialList = [
  {
    id: "1",
    vendor_name: "AVP Engineering",
    process: "Cutting",
    InStock: "100",
    Completed: "100",
    CurrentStock: "0",
    OutStock: "100",
  },
  {
    id: "2",
    vendor_name: "RK Engineering",
    process: "Turning",
    InStock: "50",
    Completed: "50",
    CurrentStock: "0",
    OutStock: "50",
  },
  {
    id: "3",
    vendor_name: "Arvind Engineering",
    process: "Turning",
    InStock: "50",
    Completed: "50",
    CurrentStock: "0",
    OutStock: "50",
  },
  {
    id: "4",
    vendor_name: "AVP Engineering",
    process: "Cutting 2",
    InStock: "100",
    Completed: "100",
    CurrentStock: "100",
    OutStock: "100",
  },
];
const ViewComponentStock = (props) => {
  const { id } = props.match.params;
  const { Option } = Select;
  const [form] = Form.useForm();
  let [responseData, setResponseData] = React.useState("");
  let [purchaseListItem, setpurchaseListItem] = React.useState(initialList);
  const [componentfamily, setcomponentfamily] = useState([""]);
  const [rawmaterialvalue, setrawmaterialvalue] = useState("");
  const [rawmaterialvariantvalue, setrawmaterialvariantvalue] = useState("");
  const [componentweight, setcomponentweight] = useState(0);
  const [contractor, setcontractor] = useState([]);
  const [enableQuantity, setenableQuantity] = useState(false);
  const [component, setcomponent] = useState([""]);
  const [list, setList] = React.useState(initialList);
  const [orderlist, setorderlist] = React.useState(initialList);
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      stockid: "",
      rawmaterialtype: "",
      rawmaterial: "",
      component_id: "",
      component_name: "",
      component_weight: "",
      rawvariant: "",
      rawbatch: "",
      process_id: "",
      process_name: "",
      contractor: "",
      rli_id: "",
      rmi_id: "",
      quantity: "",
      ctotal: "",
      cquantity: "",
      remainquantity: "",
      usedquantity: "",
    }
  );
  const handleChange = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;
    setUserInput({ [name]: newValue });
  };
  userInput.stockid = id;

  useEffect(() => {
    axios
      .get(`${getServerUrl()}/inventory/getcomponentstockbyid/${id}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        if (res.data.success === 1) {
          setResponseData(details[0]);
        } else {
          window.location.href = "/raw-material-by-stock";
        }
      });
  }, []);

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Inventory</Breadcrumb.Item>
        <Breadcrumb.Item>Component Stock</Breadcrumb.Item>
        <Breadcrumb.Item>View Component Progress</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background">
        <h2 className="titles">
          COMPONENT <span>PROGRESS DETAILS</span>
        </h2>
        <Row>
          <Col span={16}>
            <strong>First Process Completed By:</strong>{" "}
            {responseData.contractor_name}
          </Col>
          <Col span={16}>
            <strong>Batch: </strong>
            {responseData.batch_no}
          </Col>
          <Col span={16}>
            {" "}
            <strong>Initial Stock:</strong> {responseData.quantity}
          </Col>
          <Col span={16}>
            <strong>Componenent No & Name:</strong> {responseData.component_no}{" "}
            - {responseData.component_name}
          </Col>
        </Row>
        <Row>
          <Col span={16}>
            <strong>First Process Completed By:</strong>{" "}
            {responseData.contractor_name}
          </Col>
        </Row>
      </div>
      <div className="site-layout-background">
        <Row>
          <table
            style={{
              width: "100%",

              tableLayout: "auto",
            }}
          >
            <tr>
              <th>Vendor</th>
              <th>Process</th>
              <th>InStock</th>
              <th>Completed</th>
              <th>Current Stock</th>
              <th>OutStock</th>
            </tr>

            {list
              .sort((a, b) => a.order - b.order)
              .map((item) => (
                <tr key={item.id}>
                  <td>{item.vendor_name}</td>
                  <td>{item.process}</td>
                  <td>{item.InStock}</td>
                  <td>{item.Completed}</td>
                  <td>{item.CurrentStock}</td>
                  <td>{item.OutStock}</td>
                </tr>
              ))}

            {}
          </table>
          <Col span={16}>
            <br></br>
            <Button type="primary" shape="round" size="large">
              Finish & Close Componenent Stock
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ViewComponentStock;

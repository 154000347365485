import React from "react";
import { Breadcrumb, Row, Col } from "antd";
import { NavLink } from "react-router-dom";
function ClientSetting() {
  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Master Settings </Breadcrumb.Item>
        <Breadcrumb.Item>Client Settings</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <h2 className="titles">
          CLIENT MASTER <span>SETTINGS</span>
        </h2>
        <p>Select View</p>
        <Row>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/new-client">
                <span className="icons icon-new-receipt"></span>
                Add New Client
              </NavLink>
            </div>
          </Col>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/client-list">
                <span className="icons icon-view-all-receipt"></span>
                View / Manage Client
              </NavLink>
            </div>
          </Col>
        </Row>
        <br></br>
        <br></br>
      </div>
    </div>
  );
}

export default ClientSetting;

import React from "react";
import { Breadcrumb, Row, Col, Space, Card } from "antd";
import { BrowserRouter as Router, NavLink } from "react-router-dom";
function Inventory() {
  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Inventory</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background" style={{ padding: 24 }}>
        <h2 className="titles">
          INVENTORY <span>DATA</span>
        </h2>
        <p>Select</p>
        <Row>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/stock-statement">
                <span className="icons icon-component-data"></span>
                COMPONENT STOCK STATEMENT
              </NavLink>
            </div>
          </Col>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/vendor-stock-statement">
                <span className="icons icon-vendors"></span>
                VENDOR STOCK STATEMENT
              </NavLink>
            </div>
          </Col>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/components">
                <span className="icons icon-component-data"></span>
                BATCH WISE COMPONENT DATA
              </NavLink>
            </div>
          </Col>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/raw-material">
                <span className="icons icon-raw-matrial"></span>
                RAW MATERIAL DATA
              </NavLink>
            </div>
          </Col>

          {/* <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/physical-stock">
                <span className="icons icon-component-data"></span>
                OPENING STOCK
              </NavLink>
            </div>
  </Col> */}
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/stock-transfer">
                <span className="icons icon-finished-goods"></span>
                FINISHED GOODS
              </NavLink>
            </div>
          </Col>
        </Row>
      </div>
      <div className="site-layout-background" style={{ padding: 24 }}>
        <h2 className="titles">
          RAW MATERIAL <span>TASKS</span>
        </h2>
        <p>Select Task</p>
        <Row>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/rm-receipt">
                <span className="icons icon-rm-receipt"></span>
                RM RECEIPT NOTE
              </NavLink>
            </div>
          </Col>

          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/delivery-challan">
                <span className="icons icon-rm-receipt"></span>
                CREATE DELIVERY CHALAN
              </NavLink>
            </div>
          </Col>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/raw-material-by-stock">
                <span className="icons icon-raw-matrial"></span>
                VIEW DELIVERY CHALAN & CREATE RM SCHEDULE
              </NavLink>
            </div>
          </Col>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/vendor-data">
                <span className="icons icon-vendor-data"></span>
                VIEW VENDOR RM SCHEDULE
              </NavLink>
            </div>
          </Col>
          {/*    <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/components">
                <span className="icons icon-component-data"></span>
                COMPONENT STOCK
              </NavLink>
            </div>
  </Col> 

          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/scrap-data">
                <span className="icons icon-scrap-data"></span>
                SCRAP DATA
              </NavLink>
            </div>
          </Col>*/}
        </Row>
      </div>
    </div>
  );
}

export default Inventory;

import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { getServerUrl } from "../../config";
import axios from "axios";
import {
  Table,
  Space,
  Breadcrumb,
  Select,
  Input,
  Button,
  Row,
  Col,
  Tag,
} from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
const { Option } = Select;
class VendorData extends Component {
  constructor() {
    super();
  }
  state = {
    details: [],
    newdetails: [],
    data: [],
  };

  componentDidMount() {
    const details = [];
    axios
      .get(`${getServerUrl()}/inventory/getvendororders/`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = res.data.data;
        const details = responsevalue;
        this.setState({ details });
      });
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  render() {
    const data = this.state.details;

    const columns = [
      {
        title: "DC",
        dataIndex: "stid",
        key: "stid",
        ...this.getColumnSearchProps("stid"),
        render: (text, record) => "RM-" + record.stid,
      },
      {
        title: "Raw Material Name",
        dataIndex: "raw_material_name",
        key: "raw_material_name",
        ...this.getColumnSearchProps("raw_material_name"),
      },
      {
        title: "Raw Material Variant",
        dataIndex: "rawvariant",
        key: "rawvariant",
        ...this.getColumnSearchProps("rawvariant"),
      },
      {
        title: "Batch",
        dataIndex: "rawbatch",
        key: "rawbatch",
        ...this.getColumnSearchProps("rawbatch"),
      },
      {
        title: "Batch",
        dataIndex: "rawbatch",
        key: "rawbatch",
        ...this.getColumnSearchProps("rawbatch"),
      },
      {
        title: "Order No",
        dataIndex: "order_id",
        key: "order_id",
        ...this.getColumnSearchProps("order_id"),
      },
      {
        title: "Contractor",
        dataIndex: "contractor_name",
        key: "contractor_name",

        ...this.getColumnSearchProps("contractor_name"),
      },
      {
        title: "Componenent Name",
        dataIndex: "component_name",
        key: "component_name",

        ...this.getColumnSearchProps("component_name"),
      },
      {
        title: "Componenent No",
        dataIndex: "component_no",
        key: "component_no",

        ...this.getColumnSearchProps("component_no"),
      },

      {
        title: "Total Stock",
        dataIndex: "quantity",
        key: "quantity",
        sorter: {
          compare: (a, b) => a.quantity - b.quantity,
          multiple: 1,
        },
      },
      {
        title: "Completed Stock",
        dataIndex: "completed_quantity",
        key: "completed_quantity",
        sorter: {
          compare: (a, b) => a.completed_quantity - b.completed_quantity,
          multiple: 1,
        },
      },
      {
        title: "Remaining Stock",
        dataIndex: "remain_schedule_quantity",
        key: "remain_schedule_quantity",
        sorter: {
          compare: (a, b) =>
            a.remain_schedule_quantity - b.remain_schedule_quantity,
          multiple: 1,
        },
      },
      {
        title: "Non Conformance Stock",
        dataIndex: "non_conformance_quantity",
        key: "non_conformance_quantity",
        sorter: {
          compare: (a, b) =>
            a.non_conformance_quantity - b.non_conformance_quantity,
          multiple: 1,
        },
      },
      /* {
        title: "Order Status",
        dataIndex: "order_status",
        key: "order_status",
      },*/
    ];

    return (
      <div>
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>Inventory </Breadcrumb.Item>
          <Breadcrumb.Item>Vendor Tasks</Breadcrumb.Item>
        </Breadcrumb>
        <div
          className="site-layout-background"
          style={{ padding: 24, minHeight: 360 }}
        >
          <h2 className="titles">
            VENDOR <span>TASKS</span>
          </h2>

          <Space></Space>
          <br />
          <Table
            scroll={{ x: 1300 }}
            columns={columns}
            dataSource={data}
          ></Table>
        </div>
      </div>
    );
  }
}

export default VendorData;

import React, { useReducer, useEffect, useState } from "react";
import {
  Breadcrumb,
  Row,
  Col,
  Input,
  Form,
  Button,
  Select,
  notification,
  Modal,
  message,
} from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import { getServerUrl } from "../../config";
import axios from "axios";
const initialList = [];
const { Option } = Select;
const DeliveryChallan = () => {
  const [form] = Form.useForm();
  const [formone] = Form.useForm();
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [rawmaterialtype, setrawmaterialtype] = useState([""]);
  const [rawmaterial, setrawmaterial] = useState([""]);
  const [rawmaterialvariant, setrawmaterialvariant] = useState([""]);
  const [component, setcomponent] = useState([""]);
  const [componentweight, setcomponentweight] = useState(0);
  const [process, setprocess] = useState(0);
  const [unit, setunit] = useState("Kgs");
  const [contractor, setcontractor] = useState([]);
  const [ChildName, setChildName] = useState("");
  const [CPOlist, setCPOlist] = useState(initialList);
  const [componentfamily, setcomponentfamily] = useState([""]);
  const [batch, setbatch] = useState([""]);
  const [rawmaterialvalue, setrawmaterialvalue] = useState("");
  const [rawmaterialvariantvalue, setrawmaterialvariantvalue] = useState("");
  const [rawmaterialvariantstock, setrawmaterialvariantstock] = useState(0);
  const [batchstock, setbatchstock] = useState(0);
  const [usedbatchstock, setusedbatchstock] = useState(0);
  const [transporter, settransporter] = useState([""]);
  const [vehicle, setvehicle] = useState([""]);
  const [driver, setdriver] = useState([""]);
  const [POValue, setPOValue] = useState([""]);
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      rawmaterialtype: "",
      rawmaterial: "",
      rawvariant: "",
      rawbatch: "",
      contractor: "",
      rli_id: "",
      rmi_id: "",
      quantity: "",
      transporter: "",
      vehicle: "",
      driver: "",
      component: "",
      firstprocess: "",
      purchase_item_id: "",
    }
  );
  const handleChange = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;
    setUserInput({ [name]: newValue });
  };

  useEffect(() => {
    axios
      .get(`${getServerUrl()}/inventory/getmaterialstock/`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        if (res.data.data) {
          let responsevalue = Object.values(res.data.data);
          const details = responsevalue;
          setrawmaterialtype(details);
        }
      });
  }, []);

  const rawmaterialTypeOptions = rawmaterialtype.map((dataDrop) => {
    return (
      <Option value={dataDrop.rawtype_id}>
        {dataDrop.raw_material_type_name}
      </Option>
    );
  });

  function rawmaterialTypeOptionsChange(value) {
    form.resetFields();
    formone.resetFields();
    setrawmaterialvariantstock("0");
    axios
      .get(`${getServerUrl()}/inventory/getmaterialstock/${value}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        if (res.data.data) {
          let responsevalue = Object.values(res.data.data);
          const details = responsevalue;
          setrawmaterial(details);
        }
      });

    axios
      .get(`${getServerUrl()}/master/rawtype/${value}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        if (res.data.data) {
          let responsevalue = res.data.data;
          const details = responsevalue;
          console.log(details.raw_material_type_unit);
          setunit(details.raw_material_type_unit);
        }
      });
    userInput.rawmaterialtype = value;
  }

  const rawmaterialOptions = rawmaterial.map((dataDrop) => {
    return (
      <Option value={dataDrop.raw_material_id}>
        {dataDrop.raw_material_name}
      </Option>
    );
  });

  function rawmaterialOptionsChange(value) {
    setrawmaterialvalue(value);
    form.resetFields();
    axios
      .get(`${getServerUrl()}/inventory/getmaterialvariants/${value}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        setrawmaterialvariant(details);
      });
    userInput.rawmaterial = value;
  }
  const rawmaterialVariantOptions = rawmaterialvariant.map((dataDrop) => {
    return <Option value={dataDrop.variant}>{dataDrop.variant}</Option>;
  });
  function rawmaterialVariantChange(value) {
    setcomponentweight("");
    setbatchstock("");
    setrawmaterialvariantvalue(value);
    setrawmaterialvariantstock("0");
    setbatch(initialList);
    form.resetFields();
    axios
      .get(
        `${getServerUrl()}/inventory/getmaterialvariantsbatch/${rawmaterialvalue}/${value}`,
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      )
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        console.log(details);
        setbatch(details);
      });
    axios
      .get(
        `${getServerUrl()}/inventory/getmaterialvariantsstock/${rawmaterialvalue}/${value}`,
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      )
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        setrawmaterialvariantstock(details[0].remainquantity);
      });
    userInput.rawvariant = value;
  }
  const rawmaterialBatchOptions = batch.map((dataDrop) => {
    return <Option value={dataDrop.batch}>{dataDrop.batch}</Option>;
  });

  function BatchChange(value) {
    setcomponentweight("");
    setbatchstock("");
    value = value.replace(new RegExp("/", "g"), "-");
    axios
      .get(
        `${getServerUrl()}/inventory/getbatchstock/${rawmaterialvalue}/${rawmaterialvariantvalue}/${value}`,
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      )
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        userInput.rmi_id = details[0].rmi_id;
        userInput.rli_id = details[0].rli_id;
        setbatchstock(details[0].remainquantity);
        setusedbatchstock(details[0].usedquantity);
      });
    value = value.replace(new RegExp("-", "g"), "/");
    userInput.rawbatch = value;
  }

  /*  Component */
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/master/componentfamily`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        if (res.data.data) {
          let responsevalue = Object.values(res.data.data);
          const details = responsevalue;
          console.log(details);
          setcomponentfamily(details);
        }
      });
  }, []);
  function ComponentFamilyChange(value) {
    setcomponentweight("");

    axios
      .get(
        `${getServerUrl()}/inventory/getcomponentbyinputs/${rawmaterialvalue}/${rawmaterialvariantvalue}/${value}`,
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      )
      .then((res) => {
        if (res.data.data) {
          let responsevalue = Object.values(res.data.data);
          const details = responsevalue;
          setcomponent(details);
        }
      });
  }
  const ComponentFamilyOptions = componentfamily.map((dataDrop) => {
    return <Option value={dataDrop.cf_id}>{dataDrop.component_family}</Option>;
  });

  const ComponentOptions = component.map((dataDrop) => {
    return (
      <Option value={dataDrop.component_id}>
        {dataDrop.component_name} - {dataDrop.component_no}
      </Option>
    );
  });
  function ComponentChange(value) {
    setcomponentweight("");
    userInput.component = value;
    userInput.purchase_item_id = "";
    setPOValue("");
    axios
      .get(`${getServerUrl()}/inventory/getcomponentfirstprocess/${value}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        if (res.data.data) {
          let responsevalue = Object.values(res.data.data);
          const details = responsevalue;
          userInput.firstprocess = details[0].process_id;
          GetContractor(details[0].process_id);
        }
      });
    axios
      .get(`${getServerUrl()}/master/component/${value}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const details = res.data;
        setcomponentweight(details.data.rawmaterial_weight);
      });
  }
  function GetContractor(value) {
    if (value !== 0) {
      axios
        .get(`${getServerUrl()}/inventory/getprocesscontractor/${value}`, {
          headers: { "x-auth-token": localStorage.getItem("token") },
        })
        .then((res) => {
          if (res.data.data) {
            let responsevalue = Object.values(res.data.data);
            const details = responsevalue;
            setcontractor(details);
          }
        });
    }
  }
  const ContractorOptions = contractor.map((dataDrop) => {
    return (
      <Option value={dataDrop.contractor_id}>
        {dataDrop.contractor_id} - {dataDrop.contractor_name}
      </Option>
    );
  });

  function ContractorChange(value) {
    setCPOlist([""]);
    userInput.contractor = value;
    setPOValue("");
    userInput.purchase_item_id = "";
    axios
      .get(`${getServerUrl()}/master/getactiveitemsofvendor/${value}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        console.log(details);
        if (details.length) {
          setCPOlist(
            details.filter(
              (List) => Number(List.component) === Number(userInput.component)
            )
          );
        } else {
          setCPOlist([""]);
        }
      });
  }

  /*  Component */
  const processOptions = CPOlist.map((dataDrop) => {
    return (
      <Option value={dataDrop.purchase_item_id}>
        {dataDrop.pricing_type} - {dataDrop.process}
      </Option>
    );
  });
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/master/transport`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        if (res.data) {
          let responsevalue = res.data.data;
          const details = responsevalue;
          settransporter(details);
        }
      });
  }, []);

  const TransporterOptions = transporter.map((dataDrop) => {
    return <Option value={dataDrop.t_id}>{dataDrop.transporter_name}</Option>;
  });
  function transporterChange(value) {
    userInput.transporter = value;
    axios
      .get(`${getServerUrl()}/master/getdriverbytransport/${value}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        if (res.data) {
          let responsevalue = res.data.data;
          const details = responsevalue;
          setdriver(details);
        }
      });
    axios
      .get(`${getServerUrl()}/master/vehiclebytransporter/${value}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = res.data.data;
        const details = responsevalue;
        //console.log(details);
        setvehicle(details);
      });
  }
  const VehicleOptions = vehicle.map((dataDrop) => {
    return (
      <Option value={dataDrop.vehicle_id}>
        {dataDrop.vehicle_type} - {dataDrop.vehicle_number}
      </Option>
    );
  });
  const DriverOptions = driver.map((dataDrop) => {
    return <Option value={dataDrop.d_id}>{dataDrop.driver_name}</Option>;
  });
  function vehicleChange(value) {
    userInput.vehicle = value;
  }
  function driverChange(value) {
    userInput.driver = value;
  }
  const handleSubmit = (e) => {
    userInput.remainquantity = Number(batchstock) - Number(userInput.quantity);
    userInput.usedquantity =
      Number(usedbatchstock) + Number(userInput.quantity);
    if (Number(userInput.remainquantity) < 0) {
      message.error("Please check quantity");
    } else {
      if (
        userInput.component !== "" &&
        userInput.transporter !== "" &&
        userInput.contractor !== "" &&
        userInput.vehicle !== "" &&
        userInput.driver !== "" &&
        userInput.purchase_item_id !== "" &&
        Number(userInput.quantity) > 0
      ) {
        setIsConfirmModalVisible(true);
      } else {
        message.error("please enter all required fields");
      }
    }
  };

  const handleConfirmOk = () => {
    axios
      .post(`${getServerUrl()}/inventory/rawmaterialdc`, userInput, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const data = res.data;
        if (data.success === 1) {
          setUserInput({
            component_family: "",
          });
          openNotification("success");
          window.location.href = "/raw-material-by-stock";
        } else {
        }
      });
    setIsConfirmModalVisible(false);
  };
  const handleConfirmCancel = () => {
    setIsConfirmModalVisible(false);
  };

  /* Open Notifications */
  const openNotification = (type) => {
    notification[type]({
      message: "DC Added",
      description: "DC added successfully",
    });
  };
  const FailNotification = (type) => {
    notification[type]({
      message: "Error",
      description: "Please Fill out Required Fields",
    });
  };
  function handleChangeNew(value) {
    setPOValue(value);
    userInput.purchase_item_id = value;
  }
  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Inventory</Breadcrumb.Item>
        <Breadcrumb.Item>Stock Transfer</Breadcrumb.Item>
        <Breadcrumb.Item>Delivery Challan</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background">
        <h2 className="titles">RAW MATERIAL : DELIVERY CHALLAN</h2>
        <Row>
          <Col xs={24} xl={24}></Col>
          <Col xs={24} xl={8}>
            {" "}
            <h2>I. Select Raw Material & Vendor </h2>
            <label>Raw Material Type:</label>
            <br></br>
            <Select
              name="purchase_name"
              onChange={rawmaterialTypeOptionsChange}
              placeholder="Please Select Raw Material Type"
              style={{ width: "250px" }}
            >
              {rawmaterialTypeOptions}
            </Select>
            <br></br>
            <label>Raw Material:</label>
            <br></br>
            <Form layout="vertical" form={formone}>
              <Form.Item name="Select Raw Material">
                <Select
                  name="purchase_name"
                  onChange={rawmaterialOptionsChange}
                  placeholder="Please Select Raw Material"
                  style={{ width: "250px" }}
                >
                  {rawmaterialOptions}
                </Select>
              </Form.Item>
              <Form.Item
                name="Select Raw Material Variant"
                label="Raw Material Variant"
              >
                <Select
                  name="purchase_name"
                  placeholder="Please Select Raw Material Variant"
                  onChange={rawmaterialVariantChange}
                  style={{ width: "250px" }}
                >
                  {rawmaterialVariantOptions}
                </Select>
              </Form.Item>
            </Form>
            <p>
              Variant Total Stock :{" "}
              {rawmaterialvariantstock ? rawmaterialvariantstock : "0"} {unit}
            </p>
            <Form layout="vertical" form={formone}>
              <Form.Item
                name="Raw Material Variant Batch"
                label="Raw Material Variant Batch"
              >
                <Select
                  name="purchase_name"
                  placeholder="Please Select Variant Batch"
                  onChange={BatchChange}
                  style={{ width: "250px" }}
                >
                  <Option value="">please select</Option>
                  {rawmaterialBatchOptions}
                </Select>
              </Form.Item>
            </Form>
            <p>
              Batch Stock : {batchstock ? batchstock : ""} {unit}
            </p>
          </Col>
          <Col xs={24} xl={8}>
            <h2>II. Select Component & Quantity </h2>
            <Form layout="vertical" form={form}>
              <Form.Item name="componentfamily" label="Select Component Family">
                <Select
                  onChange={ComponentFamilyChange}
                  placeholder="Select a Component Family"
                >
                  {ComponentFamilyOptions}
                </Select>
              </Form.Item>
              <Form.Item name="component" label="Select Component">
                <Select
                  onChange={ComponentChange}
                  placeholder="Select a Component"
                >
                  {ComponentOptions}
                </Select>
              </Form.Item>
              <Form.Item name="contractor" label="Contractor">
                <Select
                  placeholder="Select a Contractor"
                  onChange={ContractorChange}
                >
                  {ContractorOptions}
                </Select>
              </Form.Item>
            </Form>
            <br></br>
            <label>Select PO Item:</label>
            <br></br>
            <Select onChange={handleChangeNew} value={POValue}>
              <option value="">Select PO Item</option>
              {processOptions}
            </Select>
            <br></br>
            <label>Raw Material Quantity (in {unit}):</label>
            <br></br>
            <Input
              name="quantity"
              placeholder="Quantity"
              value={userInput.quantity}
              onChange={handleChange}
            ></Input>
            {Number(userInput.quantity) > Number(batchstock)
              ? "Stock greater than Batch Quantity"
              : ""}
            <br></br>
            <table cellPadding="5">
              <tr>
                <td>Component Weight</td>
                <td>:</td>
                <td>{componentweight ? componentweight + " " + unit : ""} </td>
              </tr>

              <tr>
                <td>
                  Est. Component Count<br></br> (Based on Batch Qty)
                </td>
                <td>:</td>
                <td>
                  <b>
                    {" "}
                    {componentweight && batchstock
                      ? Number(batchstock / componentweight).toFixed(0) + " Nos"
                      : ""}{" "}
                  </b>
                </td>
              </tr>
              <tr>
                <td>
                  Approx. Component Count<br></br> (Based on DC Qty)
                </td>
                <td>:</td>
                <td>
                  <b>
                    {userInput.quantity && componentweight
                      ? Number(userInput.quantity / componentweight).toFixed(
                          0
                        ) + " Nos"
                      : ""}{" "}
                  </b>
                </td>
              </tr>
            </table>
          </Col>{" "}
          <Col xs={24} xl={24}>
            {" "}
            <h2>III. Select Transporter Information </h2>
            <label>Transporter:</label>
            <br></br>
            <Select
              name="transporter"
              onChange={transporterChange}
              placeholder="Please Select Transporter"
              style={{ width: "250px" }}
            >
              {TransporterOptions}
            </Select>
            <br></br>
            <br></br>
            <label>Vehicle:</label>
            <br></br>
            <Select
              name="vehicle"
              onChange={vehicleChange}
              placeholder="Please Select Vehicle"
              style={{ width: "250px" }}
            >
              {VehicleOptions}
            </Select>
            <br></br>
            <br></br>
            <label>Driver:</label>
            <br></br>
            <Select
              name="driver_name"
              onChange={driverChange}
              placeholder="Please Select Transporter"
              style={{ width: "250px" }}
            >
              {DriverOptions}
            </Select>
            <br></br>
            <br></br>
            <Button
              type="primary"
              shape="round"
              size="large"
              onClick={handleSubmit}
            >
              Create DC
            </Button>
          </Col>
        </Row>
      </div>
      <Modal
        title="Please Confirm"
        visible={isConfirmModalVisible}
        onOk={handleConfirmOk}
        okText="Yes, Proceed"
        onCancel={handleConfirmCancel}
        cancelText="No, Go Back"
      >
        <Row>
          <Col span={24}>
            <p
              style={{
                color: "#303030",
                fontSize: "22px",
                textAlign: "center",
              }}
            >
              <CheckCircleFilled
                style={{ fontSize: "36px", color: "#bc0067" }}
              />
              <br></br>
              Are you sure want to proceed?
            </p>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default DeliveryChallan;

import React from "react";
import { Breadcrumb, Row, Col } from "antd";
import { NavLink } from "react-router-dom";
function PurchaseSetting() {
  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Master Settings </Breadcrumb.Item>
        <Breadcrumb.Item>Purchase Settings</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background">
        <h2 className="titles">
          PURCHASE ORDER <span>SETTINGS</span>
        </h2>
        <p>Component View</p>
        <Row>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/new-purchase">
                <span className="icons icon-new-receipt"></span>
                Add New Component PO
              </NavLink>
            </div>
          </Col>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/purchase-list">
                <span className="icons icon-view-all-receipt"></span>
                View / Manage Component PO
              </NavLink>
            </div>
          </Col>
        </Row>
      </div>
      <div className="site-layout-background">
        <p>Raw Material View</p>
        <Row>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/new-rmpurchase">
                <span className="icons icon-new-receipt"></span>
                Add RM PO
              </NavLink>
            </div>
          </Col>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/rmpurchase-list">
                <span className="icons icon-view-all-receipt"></span>
                View RM PO
              </NavLink>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default PurchaseSetting;

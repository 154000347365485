import React, { useEffect, useState } from "react";
import { Breadcrumb, Row, Col, Statistic, Card, Space } from "antd";
import { BrowserRouter as Router, NavLink } from "react-router-dom";
import { getServerUrl } from "../config";
import axios from "axios";
function Home() {
  const [unassigned, setunassigned] = useState(0);
  const [completed, setcompleted] = useState(0);
  const [ongoingtrip, setongoingtrip] = useState(0);
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/inventory/getunassigned/`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        if (res.data.data) {
          let responsevalue = Object.values(res.data.data);
          const details = responsevalue;
          setunassigned(details[0].unassignedtrip);
        }
      });
  }, []);
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/transcation/getongoingtrip/`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        if (res.data.data) {
          let responsevalue = Object.values(res.data.data);
          const details = responsevalue;
          setongoingtrip(details.length);
        }
      });
  }, []);
  useEffect(() => {
    axios
      .get(
        `${getServerUrl()}/transcation/getcompletedtrip/${localStorage.getItem(
          "fromDateUnix"
        )}/${localStorage.getItem("toDateUnix")}`,
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      )
      .then((res) => {
        if (res.data.data) {
          let responsevalue = Object.values(res.data.data);
          const details = responsevalue;
          setcompleted(details.length);
        }
      });
  }, []);

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background" style={{ padding: 24 }}>
        <h2 className="titles">
          LOGISTICS <span>SUMMARY</span>
        </h2>
        <Row>
          <Col xs={24} xl={4}>
            <NavLink to="/assign-transport">
              <Card>
                <Statistic
                  title="Unassigned Trips"
                  value={unassigned}
                  valueStyle={{ color: "#ff0000" }}
                />
              </Card>
            </NavLink>
          </Col>
          <Col xs={24} xl={4}>
            <NavLink to="/ongoingtrip">
              <Card>
                <Statistic
                  title="Ongoing Trips"
                  value={ongoingtrip}
                  valueStyle={{ color: "#1167b1" }}
                />
              </Card>
            </NavLink>
          </Col>
          <Col xs={24} xl={4}>
            <NavLink to="/completedtrip">
              <Card>
                <Statistic
                  title="Completed Trips"
                  value={completed}
                  valueStyle={{ color: "#76ba1b" }}
                />
              </Card>
            </NavLink>
          </Col>
        </Row>
      </div>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <Row style={{ minHeight: 200 }}>
          <Col xs={24} xl={8}>
            <div className="LinksCard">
              <NavLink to="/inventory/" className="LinksCardInner">
                <span className="cardText">
                  <b>INVENTORY</b>
                </span>
                <span className="icons icon-inventory"></span>
              </NavLink>
            </div>
          </Col>
          <Col xs={24} xl={8}>
            <div className="LinksCard">
              <NavLink to="/logistics" className="LinksCardInner">
                <span className="cardText">
                  <b>LOGISTICS </b>
                </span>
                <span className="icons icon-logistics"></span>
              </NavLink>
            </div>
          </Col>
          <Col xs={24} xl={8}>
            <div className="LinksCard">
              <NavLink to="/vendors" className="LinksCardInner">
                <span className="cardText">
                  <b>VENDORS</b>
                </span>
                <span className="icons icon-vendors"></span>
              </NavLink>
            </div>
          </Col>
          <Col xs={24} xl={8}>
            <div className="LinksCard">
              <NavLink to="/setting" className="LinksCardInner">
                <span className="cardText">
                  <b>MASTER SETTINGS</b>
                </span>
                <span className="icons icon-setting"></span>
              </NavLink>
            </div>
          </Col>
          <Col xs={24} xl={8}>
            <div className="LinksCard">
              <NavLink to="/report" className="LinksCardInner">
                <span className="cardText">
                  <b>REPORT</b>
                </span>
                <span className="icons icon-purchase-order"></span>
              </NavLink>
            </div>
          </Col>
        </Row>
        <Row></Row>
      </div>
    </div>
  );
}

export default Home;

import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { getServerUrl } from "../config";
import { CSVLink } from "react-csv";
import axios from "axios";
import {
  Table,
  Space,
  Breadcrumb,
  Select,
  Input,
  Button,
  Row,
  Col,
  Tag,
} from "antd";

import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";

const { Option } = Select;
class CompletedTrips extends Component {
  constructor() {
    super();
  }
  state = {
    details: [],
    newdetails: [],
    data: [],
  };

  componentDidMount() {
    const details = [];
    axios
      .get(
        `${getServerUrl()}/transcation/getcompletedtrip/${localStorage.getItem(
          "fromDateUnix"
        )}/${localStorage.getItem("toDateUnix")}`,
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      )
      .then((res) => {
        let responsevalue = res.data.data;
        const details = responsevalue;

        this.setState({ details });
      });
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    const data = this.state.details;
    const columns = [
      {
        title: "Trip Date",
        dataIndex: "transdate",
        key: "transdate",
        ...this.getColumnSearchProps("transdate"),
      },
      {
        title: "Sarang DC",
        dataIndex: "transid",
        key: "transid",

        ...this.getColumnSearchProps("transid"),
      },
      {
        title: "Vendor DC",
        dataIndex: "vendor_dc",
        key: "vendor_dc",
        ...this.getColumnSearchProps("vendor_dc"),
      },
      {
        title: "Driver ",
        dataIndex: "driver_name",
        ...this.getColumnSearchProps("driver_name"),
        render: (text, record) => (
          <span>
            {record.vehicle_type} <br /> {record.vehicle_number} <br />
            <b>{record.driver_name}</b> <br /> {record.driver_phone}
          </span>
        ),
      },
      {
        title: "Transporter",
        dataIndex: "transporter_name",
        key: "transporter_name",
        ...this.getColumnSearchProps("transporter_name"),
      },
      {
        title: "Source",
        dataIndex: "source_name",
        key: "source_name",
        ...this.getColumnSearchProps("source_name"),
      },

      {
        title: "Destination",
        ...this.getColumnSearchProps("destination_name"),
        render: (text, record) =>
          record.destination_name !== "" ? record.destination_name : "Sarang",
      },

      {
        title: "Component",
        dataIndex: "component_no",
        render: (text, record) =>
          record.component_no + "\n" + record.component_name,
        ...this.getColumnSearchProps("component_no"),
      },
      {
        title: "Assigned Qty",
        dataIndex: "instock",
        key: "instock",
        ...this.getColumnSearchProps("instock"),
      },
      {
        title: "Source Confirmed Qty",
        dataIndex: "source_confirm_quantity",
        key: "source_confirm_quantity",
        sorter: {
          compare: (a, b) =>
            a.source_confirm_quantity - b.source_confirm_quantity,
          multiple: 1,
        },
      },
      {
        title: "Delivered Quantity",
        dataIndex: "destination_confirm_quantity",
        key: "destination_confirm_quantity",
        sorter: {
          compare: (a, b) =>
            a.destination_confirm_quantity - b.destination_confirm_quantity,
          multiple: 1,
        },
      },
      {
        title: "Difference in Qty",
        dataIndex: "rawmaterial_weight",
        key: "rawmaterial_weight",
        render: (text, record) => (
          <Space size="middle">
            {record.destination_confirm_quantity
              ? Number(record.source_confirm_quantity) -
                Number(record.destination_confirm_quantity)
              : ""}
          </Space>
        ),
        sorter: {
          compare: (a, b) =>
            Number(a.source_confirm_quantity - a.destination_confirm_quantity) >
            Number(b.source_confirm_quantity - b.destination_confirm_quantity),

          multiple: 1,
        },
      },
      {
        title: "Total. Weight",
        dataIndex: "rawmaterial_weight",
        key: "rawmaterial_weight",
        render: (text, record) => (
          <Space size="middle">
            {record.destination_confirm_quantity
              ? (
                  Number(record.destination_confirm_quantity) *
                  Number(record.rawmaterial_weight)
                ).toFixed(2)
              : ""}
          </Space>
        ),
      },
      {
        title: "Download DC",
        dataIndex: "transid",
        key: "transid",
        render: (text, record) => (
          <Space size="middle">
            <a
              href={"/dc/completeddc.html?id=" + record.transid}
              target="_blank"
              type="secondary"
            >
              DOWNLOAD
            </a>
          </Space>
        ),
      },
    ];
    var s = new Date(
      localStorage.getItem("fromDateUnix") * 1000
    ).toLocaleDateString("en-US");
    var e = new Date(
      localStorage.getItem("toDateUnix") * 1000
    ).toLocaleDateString("en-US");
    const TableHeaders = [
      { label: "Trip Date", key: "transdate" },
      { label: "Sarang DC", key: "transid" },
      { label: "Vendor DC", key: "vendor_dc" },
      { label: "Driver", key: "driver_name" },
      { label: "Transporter", key: "transporter_name" },
      { label: "Source", key: "source_name" },
      { label: "Destination", key: "destination_name" },
      { label: "Component No & Name", key: "componentName" },
      { label: "Assigned Qty", key: "instock" },
      { label: "Source Confirmed Qty", key: "source_confirm_quantity" },
      { label: "Delivered Quantity", key: "destination_confirm_quantity" },
      { label: "Difference in Quantity", key: "difference_quantity" },
    ];
    return (
      <div>
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>Logistics</Breadcrumb.Item>
          <Breadcrumb.Item>Completed Tripdetail </Breadcrumb.Item>
        </Breadcrumb>
        <div
          className="site-layout-background"
          style={{ padding: 24, minHeight: 360 }}
        >
          <h2 className="titles">
            COMPLETED <span>TRIP</span>
          </h2>
          <Space></Space>
          {data.length > 0 ? (
            <Button type="primary" shape="round" size="large">
              <CSVLink
                filename={("Completed Trip " + "-" + s + "-" + e).replaceAll(
                  ".",
                  ""
                )}
                data={data}
                headers={TableHeaders}
              >
                Download Data
              </CSVLink>
            </Button>
          ) : (
            ""
          )}

          <Space></Space>
          <br />
          <Table
            scroll={{ x: 1300 }}
            columns={columns}
            dataSource={data}
          ></Table>
        </div>
      </div>
    );
  }
}

export default CompletedTrips;

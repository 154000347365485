import React, { Component, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { getServerUrl } from "../config";
import axios from "axios";
import { Table, Space, Breadcrumb, Input, Button } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv";
// import ReactExport from "react-export-excel";

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const VendorComponentStock = (props) => {
  const { id } = props.match.params;
  const [details, setdetails] = useState("");
  const [newdetails, setnewdetails] = useState("");
  const [vendorName, setvendorName] = useState("");
  const [searchInput, setsearchInput] = useState([""]);
  const [searchText, setsearchText] = useState([""]);
  const [searchedColumn, setsearchedColumn] = useState([""]);
  const [data, setdata] = useState("");

  useEffect(() => {
    axios
      .get(
        `${getServerUrl()}/inventory/getcomponentstockstatementbycontractor/${id}`,
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      )
      .then((res) => {
        let responsevalue = res.data.data;
        const details = responsevalue;
        setvendorName(details[0].contractor_name);
        setdetails(details);
      });
  }, []);
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setsearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        //setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setsearchedColumn(dataIndex);
    setsearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setsearchText("");
  };

  const columns = [
    {
      title: "Component Family",
      dataIndex: "component_family",
      key: "component_family",
      ...getColumnSearchProps("component_family"),
    },
    {
      title: "Component ID",
      dataIndex: "component_no",
      key: "component_no",
      ...getColumnSearchProps("component_no"),
    },
    {
      title: "Component",
      dataIndex: "component_name",
      key: "component_name",
      ...getColumnSearchProps("component_name"),
    },
    {
      title: "Process",
      dataIndex: "process_name",
      key: "process_name",
      ...getColumnSearchProps("process_name"),
    },
    {
      title: "Inward",
      dataIndex: "instock",
      key: "quantity",
    },
    {
      title: "Outward",
      dataIndex: "completedstock",
      key: "completedstock",
    },
    {
      title: "Closing Stock",
      dataIndex: "completedstock",
      key: "completedstock",
      render: (text, record) =>
        Number(record.instock) - Number(record.completedstock),
    },
    {
      title: "Action",
      dataIndex: "contractorid",
      key: "contractorid",
      render: (text, record) => (
        <Space size="middle">
          <NavLink
            to={
              "/stockdetail/" +
              id +
              "/" +
              record.component_id +
              "/" +
              record.process_name
            }
          >
            View in details
          </NavLink>
        </Space>
      ),
    },
  ];
  const TableHeaders = [
    { label: "Component Family", key: "component_family" },
    { label: "Component ID", key: "component_no" },
    {
      label: "Component",
      key: "component_name",
    },
    { label: "Process", key: "process_name" },
    { label: "Inward", key: "instock" },
    { label: "Outward", key: "completedstock" },
    { label: "Closing Stock", key: "closing_stock" },
  ];
  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Inventory </Breadcrumb.Item>
        <Breadcrumb.Item>Vendor Stock Statement</Breadcrumb.Item>
        <Breadcrumb.Item>{vendorName}</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <h2 className="titles">
          VENDOR <span>COMPONENT STOCK</span> : {vendorName}
        </h2>
        {details.length > 0 ? (
          <Button type="primary" shape="round" size="large">
            <CSVLink
              filename={vendorName.replaceAll(".", "")}
              data={details}
              headers={TableHeaders}
            >
              Download Data
            </CSVLink>
          </Button>
        ) : (
          ""
        )}
        {/* <ExcelFile
          filename={vendorName}
          element={
            <Button type="primary" shape="round" size="large">
              Download Data
            </Button>
          }
        >
          <ExcelSheet data={details} name="Vendor Stock Detail">
            <ExcelColumn label="Component Family" value="component_family" />
            <ExcelColumn label="Component ID" value="component_no" />
            <ExcelColumn label="Component" value="component_name" />
            <ExcelColumn label="Process" value="process_name" />
            <ExcelColumn label="Inward" value="instock" />
            <ExcelColumn label="Outward" value="completedstock" />
            <ExcelColumn
              label="Closing Stock"
              value={(col) => Number(col.instock) - Number(col.completedstock)}
            />
          </ExcelSheet>
        </ExcelFile> */}
        <br />
        <Space></Space>
        <br />
        <Table
          scroll={{ x: 1300 }}
          columns={columns}
          dataSource={details}
        ></Table>
        <br></br>
      </div>
    </div>
  );
};

export default VendorComponentStock;

import React, { useReducer, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  PlusCircleFilled,
  DownloadOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  EditFilled,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Row,
  Col,
  Input,
  Button,
  Select,
  notification,
  DatePicker,
  Modal,
  Form,
  message,
} from "antd";
import { getServerUrl } from "../../config";
import axios from "axios";
const initialList = [];
const TaskToContractor = (props) => {
  const { id } = props.match.params;
  const { Option } = Select;
  const [form] = Form.useForm();
  const [formtwo] = Form.useForm();
  const [formthree] = Form.useForm();
  let [responseData, setResponseData] = React.useState("");
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [cancelModalVisible, setcancelModalVisible] = useState(false);
  const [updateModalVisible, setupdateModalVisible] = useState(false);
  let [purchaseListItem, setpurchaseListItem] = React.useState(initialList);
  const [componentfamily, setcomponentfamily] = useState([""]);
  const [rawmaterialvalue, setrawmaterialvalue] = useState("");
  const [rawmaterialvariantvalue, setrawmaterialvariantvalue] = useState("");
  const [componentweight, setcomponentweight] = useState(0);
  const [contractor, setcontractor] = useState([]);
  const [CPOlist, setCPOlist] = useState([]);
  const [componentstock, setcomponentstock] = useState(0);
  const [enableQuantity, setenableQuantity] = useState(false);
  const [component, setcomponent] = useState([""]);
  const [dataValue, setdataValue] = useState([""]);
  const [newquantity, setnewquantity] = useState(0);
  const [list, setList] = React.useState(initialList);
  const [orderlist, setorderlist] = React.useState(initialList);
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      stockid: "",
      rawmaterialtype: "",
      rawmaterial: "",
      component_id: "",
      component_name: "",
      component_weight: "",
      rawvariant: "",
      rawbatch: "",
      task: "",
      process_id: "",
      process_name: "",
      contractor: "",
      rli_id: "",
      rmi_id: "",
      quantity: "",
      ctotal: "",
      cquantity: "",
      remainquantity: "",
      usedquantity: "",
      poitemcode: "",
    }
  );
  const handleChange = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;
    setUserInput({ [name]: newValue });
  };
  userInput.stockid = id;

  useEffect(() => {
    axios
      .get(`${getServerUrl()}/inventory/getmaterialstockbytransfer/${id}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        if (res.data.success === 1) {
          setResponseData(details[0]);
          userInput.contractor = details[0].contractor;
        } else {
          window.location.href = "/raw-material-by-stock";
        }
      });
  }, []);

  /*  Component */
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/master/componentfamily`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;

        setcomponentfamily(details);
      });
  }, []);
  function ComponentFamilyChange(value) {
    setCPOlist(initialList);
    formtwo.resetFields();
    formthree.resetFields();
    userInput.poitemcode = "";
    axios
      .get(
        `${getServerUrl()}/inventory/getcomponentbyinputs/${
          responseData.raw_material_id
        }/${responseData.rawvariant}/${value}`,
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      )
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        setcomponent(details);
      });
  }
  const ComponentFamilyOptions = componentfamily.map((dataDrop) => {
    return <Option value={dataDrop.cf_id}>{dataDrop.component_family}</Option>;
  });
  const ComponentOptions = component.map((dataDrop) => {
    return (
      <Option value={dataDrop.component_id}>
        {dataDrop.component_name} - {dataDrop.component_no}
      </Option>
    );
  });

  function ComponentChange(value) {
    userInput.component = value;
    userInput.poitemcode = "";
    formthree.resetFields();
    axios
      .get(`${getServerUrl()}/inventory/getcomponentfirstprocess/${value}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        GetContractor(details[0].process_id);
        userInput.process_id = details[0].process_id;
      });
    axios
      .get(
        `${getServerUrl()}/inventory/getcomponentstockstatementbyid/${value}`,
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      )
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        if (details.length) {
          setcomponentstock(details[0].stock);
        } else {
          setcomponentstock("0");
        }
      });

    axios
      .get(`${getServerUrl()}/master/component/${value}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const details = res.data;

        userInput.component_id = details.data.component_id;
        userInput.component_name =
          details.data.component_name + " | " + details.data.component_no;
        userInput.component_weight = details.data.rawmaterial_weight;
        setcomponentweight(details.data.rawmaterial_weight);
      });
    setCPOlist(initialList);
    axios
      .get(
        `${getServerUrl()}/master/getactiveitemsofvendor/${
          userInput.contractor
        }`,
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      )
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        if (details.length) {
          setCPOlist(
            details.filter(
              (List) => Number(List.component) === Number(userInput.component)
            )
          );
        } else {
          setCPOlist([""]);
        }
      });
  }

  function GetContractor(value) {
    if (value !== 0) {
      axios
        .get(`${getServerUrl()}/inventory/getprocesscontractor/${value}`, {
          headers: { "x-auth-token": localStorage.getItem("token") },
        })
        .then((res) => {
          let responsevalue = Object.values(res.data.data);
          const details = responsevalue;

          details.map((record) => {
            if (
              Number(record.contractor_id) === Number(responseData.contractor)
            ) {
              setenableQuantity(true);
            }
          });
        });
      axios
        .get(`${getServerUrl()}/master/process/${value}`, {
          headers: { "x-auth-token": localStorage.getItem("token") },
        })
        .then((res) => {
          const details = res.data;
          userInput.process_name = details.data.process_name;
        });
    }
  }

  const POOptions = CPOlist.map((dataDrop) => {
    return (
      <Option value={dataDrop.purchase_item_id}>
        {dataDrop.pricing_type} - {dataDrop.process}
      </Option>
    );
  });
  function POchange(value) {
    userInput.poitemcode = value;
  }
  const insertLineItem = () => {
    let total = 0;
    for (var i = 0; i < list.length; i++) {
      total += Number(list[i].approxweight);
    }
    total = Number(total);

    if (
      userInput.component_id !== "" &&
      Number.isInteger(Number(userInput.quantity)) &&
      userInput.quantity !== "" &&
      Number(userInput.quantity) * Number(componentweight) <=
        Number(responseData.remainquantity) &&
      total + Number(userInput.quantity) * Number(componentweight) <=
        Number(responseData.remainquantity) &&
      userInput.poitemcode !== ""
    ) {
      setList(
        list.concat({
          id: Date.now(),
          component_id: userInput.component_id,
          component_name: userInput.component_name,
          component_weight: userInput.component_weight,
          poitemcode: userInput.poitemcode,
          process_id: userInput.process_id,
          process_name: userInput.process_name,
          quantity: userInput.quantity,
          approxweight: userInput.component_weight * userInput.quantity,
        })
      );
      form.resetFields();
      userInput.quantity = "";
      userInput.poitemcode = "";
    }
  };
  let ctotal = 0;
  let cquantity = 0;
  for (var i = 0; i < list.length; i++) {
    cquantity += Number(list[i].quantity);
  }
  for (var i = 0; i < list.length; i++) {
    ctotal += Number(list[i].approxweight);
  }

  /*  Component */
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/inventory/getordertocontractor/${id}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        setorderlist(details);
      });
  }, []);

  userInput.ctotal = Number(ctotal);
  userInput.cquantity = Number(cquantity);
  userInput.remainquantity =
    Number(responseData.remainquantity) - Number(userInput.ctotal);
  userInput.usedquantity =
    Number(responseData.usedquantity) + Number(userInput.ctotal);
  const handleSubmit = (e) => {
    userInput.task = list;
    if (userInput.task.length <= 0) {
      message.error("please add atleast on task to contractor");
    }
    userInput.contractor = responseData.contractor;
    if (userInput.stockid !== "" && userInput.task.length > 0) {
      console.log(userInput);
      setIsConfirmModalVisible(true);
    } else {
      message.error("please enter all required fields");
    }
  };
  /* Open Notifications */
  const openNotification = (type) => {
    notification[type]({
      message: "Task Added",
      description: "Task added successfully",
    });
  };
  const FailNotification = (type) => {
    notification[type]({
      message: "Error",
      description: "Please Fill out Required Fields",
    });
  };

  const handleConfirmOk = () => {
    axios
      .post(`${getServerUrl()}/inventory/ordertocontractor`, userInput, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const data = res.data;
        if (data.success === 1) {
          setUserInput({
            component_family: "",
          });

          window.location.reload();
        } else {
        }
      });
    setIsConfirmModalVisible(false);
  };
  const handleConfirmCancel = () => {
    setIsConfirmModalVisible(false);
  };
  const cancelOrder = (order_id, stid, quantity) => {
    setcancelModalVisible(true);
    setdataValue({
      order_id: order_id,
      stid: stid,
      quantity: quantity,
    });
  };
  const handlecancelModalOk = () => {
    //console.log("dataValue", dataValue);
    axios
      .post(`${getServerUrl()}/inventory/cancelorder`, dataValue, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const data = res.data;
        if (data.success === 1) {
          window.location.reload();
        } else {
        }
      });
    setcancelModalVisible(false);
  };
  const handlecancelModalCancel = () => {
    setcancelModalVisible(false);
  };

  const editOrder = (
    order_id,
    completed_quantity,
    quantity,
    component_weight,
    stid,
    weight
  ) => {
    //console.log(
    //  "order_id,completed_quantity, stid, quantity",
    //  order_id,
    //  completed_quantity,
    //  stid,
    //   quantity
    //);
    setdataValue({
      order_id: order_id,
      completed_quantity: completed_quantity,
      stid: stid,
      component_weight: component_weight,
      quantity: quantity,
      weight: weight,
      newquantity: "",
    });
    setnewquantity(quantity);
    setupdateModalVisible(true);
  };
  const handleupdateModalOk = () => {
    dataValue.newquantity = newquantity;
    //console.log(Number(dataValue.completed_quantity) <= Number(newquantity));
    //console.log(Number(dataValue.quantity) >= Number(newquantity));
    if (
      Number(dataValue.completed_quantity) <= Number(newquantity) &&
      Number(dataValue.quantity) >= Number(newquantity)
    ) {
      axios
        .post(`${getServerUrl()}/inventory/updateorder`, dataValue, {
          headers: { "x-auth-token": localStorage.getItem("token") },
        })
        .then((res) => {
          const data = res.data;
          if (data.success === 1) {
            window.location.reload();
          } else {
          }
        });
    } else {
      message.error("Please Check the quantity please proceed");
    }

    setupdateModalVisible(false);
  };
  const handleupdateModalCancel = () => {
    setupdateModalVisible(false);
  };
  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Inventory</Breadcrumb.Item>
        <Breadcrumb.Item>Raw Material Stock</Breadcrumb.Item>
        <Breadcrumb.Item>RM Schedule</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background">
        <h2 className="titles">
          RM <span>SCHEDULE</span>
        </h2>
        <Row>
          <Col xs={24} xl={8}>
            <table cellPadding="5">
              <tr>
                <td style={{ fontWeight: "bold" }}>DC</td>
                <td>:</td>
                <td>RM-{id}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Contractor Name</td>
                <td>:</td>
                <td>{responseData.contractor_name}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Raw Material</td>
                <td>:</td>
                <td>{responseData.raw_material_name}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Raw Material Variant</td>
                <td>:</td>
                <td>{responseData.rawvariant}</td>
              </tr>
            </table>
          </Col>
          <Col xs={24} xl={8}>
            <table cellPadding="5">
              <tr>
                <td style={{ fontWeight: "bold" }}>Batch</td>
                <td>:</td>
                <td>{responseData.rawbatch}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>DC Qty</td>
                <td>:</td>
                <td>
                  {responseData.quantity} {responseData.raw_material_type_unit}
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Used Qty</td>
                <td>:</td>
                <td>
                  {Number(responseData.usedquantity).toFixed(2)}{" "}
                  {responseData.raw_material_type_unit}
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Balance Qty</td>
                <td>:</td>
                <td>
                  {Number(responseData.remainquantity).toFixed(2)}{" "}
                  {responseData.raw_material_type_unit}
                </td>
              </tr>
            </table>
          </Col>
          <Col xs={24} xl={8}>
            <table cellPadding="5">
              <tr>
                <td style={{ fontWeight: "bold" }}>Transport Name</td>
                <td>:</td>
                <td>{responseData.transporter_name}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Driver Name</td>
                <td>:</td>
                <td>{responseData.driver_name}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Driver Contact</td>
                <td>:</td>
                <td>{responseData.driver_phone}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Vehicle</td>
                <td>:</td>
                <td>
                  {responseData.vehicle_type} - {responseData.vehicle_number}
                </td>
              </tr>
            </table>
          </Col>
        </Row>
        <br></br>

        <Row>
          <Col xs={24} xl={24}>
            <h2 className="titles">
              SELECT & ADD<span> TASKS</span>
            </h2>
          </Col>
          <Col xs={24} xl={24}>
            <Form layout="vertical" form={form}>
              <Form.Item name="componentfamily" label="Select Component Family">
                <Select
                  onChange={ComponentFamilyChange}
                  placeholder="Select a Component Family"
                >
                  {ComponentFamilyOptions}
                </Select>
              </Form.Item>
            </Form>
            <Form layout="vertical" form={formtwo}>
              <Form.Item name="component" label="Select Component">
                <Select
                  onChange={ComponentChange}
                  placeholder="Select a Component"
                >
                  {ComponentOptions}
                </Select>
              </Form.Item>
            </Form>{" "}
            <p>
              Avail Component Stock : <b>{componentstock}</b>
            </p>
            <Form layout="vertical" form={formthree}>
              <Form.Item name="pochange" label="Select PO process">
                <Select onChange={POchange} placeholder="Select a PO Item">
                  <Option value="">please select</Option>
                  {POOptions}
                </Select>
              </Form.Item>
            </Form>
          </Col>{" "}
          <Col xs={24} xl={24} style={{ paddingTop: "15px" }}>
            <div style={{ paddingTop: "30px" }}>
              {" "}
              <label>Enter Component Quantity:</label>
              <br></br>
              <Input
                name="quantity"
                placeholder="Component Quantity"
                value={userInput.quantity}
                onChange={handleChange}
              ></Input>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            {userInput.quantity >
            (
              Number(userInput.remainquantity) / Number(componentweight)
            ).toFixed(0)
              ? "Component Quantity greater than Raw Material"
              : ""}
            <p>
              Est. Component Qty (based on remaining stock):{" "}
              {Math.floor(
                Number(userInput.remainquantity) / Number(componentweight)
              )}
            </p>

            <p>
              Apporx. Raw Material Usage:{" "}
              {(Number(userInput.quantity) * Number(componentweight)).toFixed(
                2
              )}
            </p>
            <br></br>
            <Button
              type="primary"
              shape="round"
              size="large"
              onClick={insertLineItem}
            >
              Add Task
            </Button>
          </Col>
        </Row>

        <div className="site-layout-background">
          <Row>
            <Col span={24}>
              <h2 className="titles">
                SELECTED<span> TASKS</span>
              </h2>
            </Col>
            <Col span={24}>
              <table
                style={{
                  width: "100%",

                  tableLayout: "auto",
                }}
              >
                <tr>
                  <th>PO Item #</th>
                  <th>Component</th>
                  <th>Process</th>
                  <th>Quantity</th>
                  <th>Component. Weight</th>
                  <th>Approx. Weight</th>
                </tr>

                {list
                  .sort((a, b) => a.order - b.order)
                  .map((item) => (
                    <tr key={item.id}>
                      <td>{item.poitemcode}</td>
                      <td>{item.component_name}</td>
                      <td>{item.process_name}</td>
                      <td>{item.quantity}</td>
                      <td>{item.component_weight}</td>
                      <td>{Number(item.approxweight).toFixed(2)}</td>
                    </tr>
                  ))}

                {}
              </table>
            </Col>
            <Col span={16}>
              <br></br>
              Totol Raw Material used : {userInput.ctotal}
              <br></br>
              Totol Component Quantity : {userInput.cquantity}
            </Col>

            <Col span={16}>
              <br></br>
              <Button
                type="primary"
                shape="round"
                size="large"
                onClick={handleSubmit}
              >
                Update RM Schedule
              </Button>
            </Col>
          </Row>
        </div>
        <div className="site-layout-background">
          <Row>
            <Col span={24}>
              <h2 className="titles">
                EXISTING<span> RM SCHEDULE</span>
              </h2>
            </Col>
            <Col span={24}>
              <p>Tasks</p>
            </Col>
            <table
              style={{
                width: "100%",

                tableLayout: "auto",
              }}
            >
              <tr>
                <th>Order ID</th>
                <th>Component</th>
                <th>Process</th>
                <th>Component Quantity</th>
                <th>Completed Component</th>
                <th>Component. Weight</th>
                <th>Approx. Weight</th>
              </tr>

              {orderlist.map((item) => (
                <tr key={item.id}>
                  <td>{item.order_id}</td>
                  <td>
                    {item.component_no} - {item.component_name}
                  </td>
                  <td>{item.process_name}</td>
                  <td>{item.quantity}</td>
                  <td>{item.completed_quantity}</td>
                  <td>{item.component_weight}</td>
                  <td>{Number(item.weight).toFixed(2)}</td>
                  <td>
                    {item.completed_quantity === "0" ? (
                      <div>
                        <CloseCircleFilled
                          style={{ fontSize: "2em", color: "#bc0067" }}
                          onClick={() =>
                            cancelOrder(item.order_id, item.stid, item.weight)
                          }
                        />{" "}
                        <EditFilled
                          style={{ fontSize: "2em", color: "#bc0067" }}
                          onClick={() =>
                            editOrder(
                              item.order_id,
                              item.completed_quantity,
                              item.quantity,
                              item.component_weight,
                              item.stid,
                              item.weight
                            )
                          }
                        ></EditFilled>
                      </div>
                    ) : (
                      <EditFilled
                        style={{ fontSize: "2em", color: "#bc0067" }}
                        onClick={() =>
                          editOrder(
                            item.order_id,
                            item.completed_quantity,
                            item.quantity,
                            item.component_weight,
                            item.stid,
                            item.weight
                          )
                        }
                      ></EditFilled>
                    )}
                  </td>
                </tr>
              ))}

              {}
            </table>
          </Row>
        </div>
      </div>
      <Modal
        title="Please Confirm"
        visible={isConfirmModalVisible}
        onOk={handleConfirmOk}
        okText="Yes, Proceed"
        onCancel={handleConfirmCancel}
        cancelText="No, Go Back"
      >
        <Row>
          <Col span={24}>
            <p
              style={{
                color: "#303030",
                fontSize: "22px",
                textAlign: "center",
              }}
            >
              <CheckCircleFilled
                style={{ fontSize: "36px", color: "#bc0067" }}
              />
              <br></br>
              Are you sure want to proceed?
            </p>
          </Col>
        </Row>
      </Modal>
      <Modal
        title="Please Confirm"
        visible={cancelModalVisible}
        onOk={handlecancelModalOk}
        okText="Yes, Proceed"
        onCancel={handlecancelModalCancel}
        cancelText="No, Go Back"
      >
        <Row>
          <Col span={24}>
            <p
              style={{
                color: "#303030",
                fontSize: "22px",
                textAlign: "center",
              }}
            >
              <CheckCircleFilled
                style={{ fontSize: "36px", color: "#bc0067" }}
              />
              <br></br>
              Are you sure want to proceed?
            </p>
          </Col>
        </Row>
      </Modal>
      <Modal
        title="Change Order Quantity"
        visible={updateModalVisible}
        onOk={handleupdateModalOk}
        okText="Update"
        onCancel={handleupdateModalCancel}
        cancelText="Cancel"
      >
        <Row>
          <Col span={24}>
            <label>New Component Quantity</label>
            <Input
              name="Confirm Quantity"
              placeholder="Confirm Quantity"
              value={newquantity}
              onChange={(e) => {
                setnewquantity(e.target.value);
              }}
            ></Input>
            {Number(dataValue.quantity) < Number(newquantity) ? (
              <p>Quantity should be less than order quantity</p>
            ) : (
              ""
            )}
            {Number(dataValue.completed_quantity) > Number(newquantity) ? (
              <p>Quantity should be greater than completed quantity</p>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default TaskToContractor;

import React, { useReducer, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { getServerUrl } from "../config";
import axios from "axios";
import {
  Table,
  Space,
  Breadcrumb,
  Select,
  Input,
  Button,
  Row,
  Col,
  Tag,
  Modal,
} from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
const { Option } = Select;
const TripSheet = () => {
  const [data, setdata] = useState([""]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [transporter, settransporter] = useState([""]);
  const [vehicle, setvehicle] = useState([""]);
  const [driver, setdriver] = useState([""]);
  const [searchInput, setsearchInput] = useState([""]);
  const [searchText, setsearchText] = useState([""]);
  const [searchedColumn, setsearchedColumn] = useState([""]);
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      transporter: "",
      vehicle: "",
      driver: "",
      tripid: "",
    }
  );
  const handleChange = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;
    setUserInput({ [name]: newValue });
  };

  const showModal = (tripid) => {
    userInput.tripid = tripid;
    setIsModalVisible(true);
  };

  const handleOk = () => {
    console.log(userInput);
    if (userInput.tripid !== "" && userInput.driver !== "") {
      axios
        .post(`${getServerUrl()}/transcation/updatetripsheet/`, userInput, {
          headers: { "x-auth-token": localStorage.getItem("token") },
        })
        .then((res) => {
          let responsevalue = Object.values(res.data.data);
          const details = responsevalue;
          setdata(details);
        });
      setIsModalVisible(false);
      window.location.reload();
    }
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  useEffect(() => {
    axios
      .get(
        `${getServerUrl()}/transcation/gettripsheet/${localStorage.getItem(
          "fromDateUnix"
        )}/${localStorage.getItem("toDateUnix")}`,
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      )
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        setdata(details);
      });
  }, []);
  /* Transport */
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/master/transport`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = res.data.data;
        const details = responsevalue;
        settransporter(details);
      });
  }, []);

  const TransporterOptions = transporter.map((dataDrop) => {
    return <Option value={dataDrop.t_id}>{dataDrop.transporter_name}</Option>;
  });
  function transporterChange(value) {
    userInput.transporter = value;
    axios
      .get(`${getServerUrl()}/master/getdriverbytransport/${value}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = res.data.data;
        const details = responsevalue;
        setdriver(details);
      });
    axios
      .get(`${getServerUrl()}/master/vehiclebytransporter/${value}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = res.data.data;
        const details = responsevalue;
        setvehicle(details);
      });
  }
  const VehicleOptions = vehicle.map((dataDrop) => {
    return (
      <Option value={dataDrop.vehicle_id}>
        {dataDrop.vehicle_type} - {dataDrop.vehicle_number}
      </Option>
    );
  });
  const DriverOptions = driver.map((dataDrop) => {
    return <Option value={dataDrop.d_id}>{dataDrop.driver_name}</Option>;
  });
  function vehicleChange(value) {
    userInput.vehicle = value;
  }
  function driverChange(value) {
    userInput.driver = value;
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setsearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        //setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setsearchedColumn(dataIndex);
    setsearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setsearchText("");
  };

  const columns = [
    {
      title: "Trip Date",
      dataIndex: "trip_date",
      key: "trip_date",
      ...getColumnSearchProps("trip_date"),
    },
    {
      title: "Transporter Name",
      dataIndex: "transporter_name",
      key: "transporter_name",
      ...getColumnSearchProps("transporter_name"),
    },
    {
      title: "Vehicle Number",
      dataIndex: "vehicle_number",
      key: "vehicle_number",
      ...getColumnSearchProps("vehicle_number"),
    },
    {
      title: "Driver Name",
      dataIndex: "driver_name",
      key: "driver_name",
      ...getColumnSearchProps("driver_name"),
    },
    {
      title: "Driver Phone",
      dataIndex: "driver_phone",
      key: "driver_phone",
      ...getColumnSearchProps("driver_phone"),
    },
    {
      title: "Assign Trip",
      dataIndex: "tasks",
      key: "tasks",
      ...getColumnSearchProps("driver_name"),
    },
    {
      title: "Completed",
      dataIndex: "completed",
      key: "completed",
    },
    {
      title: "Remain Trip",
      dataIndex: "remain",
      key: "remain",
    },
    {
      title: "Trip Status",
      dataIndex: "tripstatus",
      key: "tripstatus",
    },
    {
      title: "Action",
      dataIndex: "tripid",
      key: "tripid",
      render: (text, record) => (
        <Space size="middle">
          <NavLink to={"/trip-detail/" + record.tripid}>View</NavLink>
        </Space>
      ),
    },
    {
      title: "Reassign",
      dataIndex: "tripid",
      key: "tripid",
      render: (text, record) => (
        <Space size="middle">
          <Button type="secondary" onClick={() => showModal(record.tripid)}>
            Reassign Trip
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Logistics</Breadcrumb.Item>
        <Breadcrumb.Item>Tripsheet</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <h2 className="titles">
          TRIP <span>SHEETS</span>
        </h2>

        <Space></Space>
        <br />
        <Table scroll={{ x: 1300 }} columns={columns} dataSource={data}></Table>
      </div>
      <Modal
        title="Reassign the Trip"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <label>Transporter:</label>
        <br></br>
        <Select
          name="transporter"
          onChange={transporterChange}
          placeholder="Please Select Transporter"
          style={{ width: "250px" }}
        >
          {TransporterOptions}
        </Select>
        <br></br>
        <br></br>
        <label>Vehicle:</label>
        <br></br>
        <Select
          name="vehicle"
          onChange={vehicleChange}
          placeholder="Please Select Vehicle"
          style={{ width: "250px" }}
        >
          {VehicleOptions}
        </Select>
        <br></br>
        <br></br>
        <label>Driver:</label>
        <br></br>
        <Select
          name="driver_name"
          onChange={driverChange}
          placeholder="Please Select Transporter"
          style={{ width: "250px" }}
        >
          {DriverOptions}
        </Select>
        <br></br>
        <br></br>
      </Modal>
    </div>
  );
};

export default TripSheet;

import React, { useState, useEffect, Component } from "react";
import { Layout, Drawer, Button, Avatar, Spin } from "antd";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { getServerUrl } from "./config";
import axios from "axios";
import { UserOutlined, RedoOutlined } from "@ant-design/icons";
import { Breadcrumb, Row, Col, Space, Card, DatePicker } from "antd";
import logo from "./sarang.svg";
import SideBar from "./components/sidebar";
import MobileMenu from "./components/mobile-menu";
import "./App.scss";
import "./style.css";
import "antd/dist/antd.css";
import moment from "moment";
import Home from "./pages/home";
import Inventory from "./pages/inventory/inventory";
import RMReceipt from "./pages/inventory/rm-receipt";
import NewRMReceipt from "./pages/inventory/new-rm-receipts";
import RMReceipts from "./pages/inventory/rm-receipts";
import Logistics from "./pages/logistics";
import Vendors from "./pages/vendors";
import Setting from "./pages/setting";
import InventoryData from "./pages/inventory-data";
import VendorData from "./pages/inventory/vendor-data";
import VendorStockDetails from "./pages/vendor-stock-data";
import ComponentsData from "./pages/inventory/components-data";
import ComponentsDetails from "./pages/components-details";
import StockStatement from "./pages/stock-statement";
import VendorStockStatement from "./pages/vendor-stock-statement";
import VendorComponentStock from "./pages/vendorcomponentstock";
import ViewByComponent from "./pages/viewbycomponent";
import ViewByCSID from "./pages/viewbycsid";
import RawMaterial from "./pages/inventory/raw-material";
import RawMaterialDetail from "./pages/inventory/raw-material-detail";
import RawMaterialByStock from "./pages/inventory/raw-material-by-stock";
import AssignTransport from "./pages/inventory/assign-transport";
import ViewReceipts from "./pages/inventory/view-rm-receipt";
import TaskToContractor from "./pages/inventory/task-to-contractor";
import ViewComponentStock from "./pages/inventory/view-stock-detail";
import StockTransfer from "./pages/stock-transfer";
import InBetweenVendors from "./pages/in-between-vendors";
import DeliveryChallan from "./pages/inventory/delivery-challan";
import InputStock from "./pages/inventory/input-stock";
import PhysicalStock from "./pages/inventory/physical-stock";
import FinishedGoodTransfer from "./pages/finished-goods-transfer";
import FinishedGoodTransferBO from "./pages/finished-goods-transfer-bom";
import ScrapData from "./pages/scrap-data";
import EndBit from "./pages/endbit";
import Rejections from "./pages/rejections";
import AssignVehicle from "./pages/assign-vehicle";
import OnGoingTrip from "./pages/ongoingtrip";
import CompletedTrips from "./pages/completedtrip";
import AssignVehicleToPickup from "./pages/assign-vehicle-to-pickup";
import TripSheet from "./pages/trip-sheet";
import TripDetail from "./pages/trip-detail";
import ContractorsSetting from "./pages/contractor/contractor-setting";
import Reports from "./pages/reports";
import VendorReports from "./pages/vendor-report";
import VendorReportPDF from "./pages/contractor-report-pdf";
import TransportReport from "./pages/transporter-report";
import DriverReport from "./pages/drive-report";
import NewContractor from "./pages/contractor/new-contractor";
import EditContractor from "./pages/contractor/edit-contractor";
import ContractorPO from "./pages/contractor/contractorpo";
import ContractorList from "./pages/contractor/contractor-list";
import ComponentSetting from "./pages/components/components-setting";
import ComponentList from "./pages/components/components-list";
import NewComponent from "./pages/components/new-components";
import ViewComponent from "./pages/components/viewcomponents";
import NewProcess from "./pages/process/new-process";
import EditProcess from "./pages/process/edit-process";
import ProcessList from "./pages/process/process-list";
import ProcessSetting from "./pages/process/process-setting";
import TransportSetting from "./pages/transport/transport-setting";
import NewTransport from "./pages/transport/new-transport";
import TransportList from "./pages/transport/transport-list";
import EditTransport from "./pages/transport/edit-transport";
import NewVehicle from "./pages/transport/new-vehicle";
import VehicleList from "./pages/transport/vehicle-list";
import EditVehicle from "./pages/transport/edit-vehicle";
import NewDriver from "./pages/transport/new-driver";
import DriverList from "./pages/transport/driver-list";
import EditDriver from "./pages/transport/edit-driver";
import NewPurchase from "./pages/purchase/new-purchase";
import PurchaseList from "./pages/purchase/purchase-list";
import EditPurchase from "./pages/purchase/edit-purchaseorder";
import PurchaseSetting from "./pages/purchase/purchase-setting";
import NewRMPurchase from "./pages/purchase/new-rmpurchase";
import RMPurchaseList from "./pages/purchase/rmpurchase-list";
import ViewRMPurchaseOrder from "./pages/purchase/viewrmpurchaseoder";
import NewRawMaterial from "./pages/rawmaterial/new-rawmaterial";
import EditRawMaterial from "./pages/rawmaterial/edit-rawmaterial";
import RawMaterialList from "./pages/rawmaterial/rawmaterial-list";
import RawMaterialSetting from "./pages/rawmaterial/rawmaterial-setting";
import NewRawMaterialType from "./pages/rawmaterial/rawmaterialtype";
import RawMaterialTypeList from "./pages/rawmaterial/rawmaterialtypelist";
import NewComponentFamily from "./pages/components/new-component-family";
import EditComponentFamily from "./pages/components/edit-components-family";
import ComponentFamilyList from "./pages/components/components-family";
import EditRawType from "./pages/rawmaterial/edit-rawtype";
import MoveStock from "./pages/inventory/move-stock";
import FinishedGoods from "./pages/inventory/finished-goods";
import FinishedGoodsStatement from "./pages/inventory/finished-goods-statement";
import Users from "./pages/users";
import AddUser from "./pages/add-user";
import EditUser from "./pages/edit-user";

/* Client Creation */
import NewClient from "./pages/company/new-company";
import EditClient from "./pages/company/edit-company";
import ClientList from "./pages/company/company-list";
import ClientSetting from "./pages/company/company-setting";

import { Switch, Route } from "react-router-dom";
import RawMaterialVariantDetail from "./pages/inventory/raw-material-variant";
import RawMaterialVariantByVendor from "./pages/inventory/raw-material-variant-by-vendor";
import VendorVariantOrderDetails from "./pages/inventory/vendor-variant-order-detail";
import VariantOrderStock from "./pages/inventory/variant-order-stock";
const { RangePicker } = DatePicker;

const { Header, Content, Footer } = Layout;

const Dashboard = ({ location }) => {
  const [loader, setloader] = useState("");
  const curlocation = useLocation();
  const [visible, setVisible] = useState(false);
  const dateFormat = "YYYY/MM/DD";
  const showDrawer = () => {
    setVisible(true);
  };
  const [userState, setuserState] = useState(false);
  const [userLogin, setuserLogin] = useState(false);
  useEffect(() => {
    setuserState(localStorage.getItem("usertype"));
  }, []);
  useEffect(() => {
    setloader("loader");
    if (!localStorage.getItem("fromDate")) {
      let thisMoment = moment();
      let endOfMonth = moment(thisMoment).endOf("month").format(dateFormat);
      let startOfMonth = moment(thisMoment).startOf("month").format(dateFormat);
      localStorage.setItem("fromDate", startOfMonth);
      localStorage.setItem("toDate", endOfMonth);
      localStorage.setItem(
        "fromDateUnix",
        new Date(startOfMonth).getTime() / 1000
      );
      localStorage.setItem(
        "toDateUnix",
        new Date(endOfMonth).getTime() / 1000 + 86400
      );
    }
    setInterval(() => setloader("noloader"), 1000);
  }, []);

  useEffect(() => {
    axios
      .post(`${getServerUrl()}/checkuserlogin`, "", {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        console.log("Check user", res.data);
        if (res.data.success === 1) {
          setuserLogin(true);
        } else {
          localStorage.setItem("token", "");
          localStorage.setItem("isLogin", false);
          localStorage.clear();
          window.location.reload();
        }
      });
  }, []);

  const onClose = () => {
    setVisible(false);
  };

  const DateSelect = (value, dateString) => {
    localStorage.setItem(
      "fromDateUnix",
      new Date(dateString[0]).getTime() / 1000
    );
    localStorage.setItem(
      "toDateUnix",
      new Date(dateString[1]).getTime() / 1000 + 86400
    );
    localStorage.setItem("fromDate", dateString[0]);
    localStorage.setItem("toDate", dateString[1]);
    window.location.reload();
  };
  const history = useHistory();

  const logout = () => {
    localStorage.clear();
    window.location.reload();
  };
  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <div>
      <Layout style={{ minHeight: "100vh" }}>
        <SideBar />
        <Layout className="site-layout">
          <Header className="site-layout-background" style={{ padding: 0 }}>
            <div className="mobile-logo">
              <img src={logo}></img>
            </div>
            {/* <div className="account">
            <Avatar size="90" icon={<UserOutlined />} onClick={showDrawer} />
          </div> */}
          </Header>
          <div className={loader}>
            <Spin size="large" tip="Loading..."></Spin>
          </div>
          <Content style={{ margin: "0 16px", paddingBottom: "200px" }}>
            <div>
              <Row justify="center">
                <Col xs={24} xl={20}>
                  <div className="mobile-only">
                    <Row style={{ cursor: "pointer" }}>
                      <Col xs={4} xl={8} onClick={showDrawer}>
                        <Avatar
                          size="large"
                          style={{ backgroundColor: "#a06488" }}
                          icon={<UserOutlined />}
                        />
                      </Col>
                      <Col xs={16} xl={8} onClick={showDrawer}>
                        <div>
                          <p style={{ color: "#a06488" }}>
                            <b> {localStorage.getItem("username")}</b>
                            <br></br>
                            {localStorage.getItem("Organization")}
                          </p>
                        </div>
                      </Col>
                      <Col xs={4} xl={24} onClick={refreshPage}>
                        <Avatar
                          style={{ backgroundColor: "#fff", color: "#C12026" }}
                          size="large"
                          icon={<RedoOutlined />}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={24} xl={18}></Col>
                <Col xs={24} xl={6}>
                  <div className="mobileCenter">
                    <RangePicker
                      defaultValue={[
                        moment(localStorage.getItem("fromDate"), dateFormat),
                        moment(localStorage.getItem("toDate"), dateFormat),
                      ]}
                      format={dateFormat}
                      onChange={DateSelect}
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>
              </Row>
            </div>

            {curlocation.pathname !== "/" ? (
              <a
                onClick={() => {
                  history.goBack();
                }}
                className="backButton"
              >
                &lt; Back
              </a>
            ) : (
              ""
            )}
            <Switch>
              <Route path="/report" component={Reports} />
              <Route path="/contractor-report" component={VendorReports} />
              <Route
                path="/contractor-report-export/:id"
                component={VendorReportPDF}
              />
              <Route path="/transporter-report" component={TransportReport} />
              <Route path="/driver-report" component={DriverReport} />
              <Route path="/inventory" component={Inventory} />
              <Route path="/logistics" component={Logistics} />
              <Route path="/vendors" component={Vendors} />
              <Route path="/setting" component={Setting} />
              <Route path="/inventory-data" component={InventoryData} />
              <Route path="/vendor-data" component={VendorData} />
              <Route path="/components" component={ComponentsData} />
              <Route path="/move-stock" component={MoveStock} />
              <Route path="/finished-goods" component={FinishedGoods} />
              <Route
                path="/finished-goods-statement/:id"
                component={FinishedGoodsStatement}
              />
              <Route path="/components-details" component={ComponentsDetails} />
              <Route path="/stock-statement" component={StockStatement} />
              <Route
                path="/vendor-stock-statement"
                component={VendorStockStatement}
              />
              <Route
                path="/vendorcomponentstock/:id"
                component={VendorComponentStock}
              />
              <Route path="/viewbycomponent/:id" component={ViewByComponent} />
              <Route
                path="/stockdetail/:id/:component/:process"
                component={ViewByCSID}
              />
              <Route path="/raw-material" component={RawMaterial} />
              <Route
                path="/raw-material-detail/:id"
                render={(props) => <RawMaterialDetail {...props} />}
              />
              <Route
                path="/raw-material-variant/:id/:variant"
                render={(props) => <RawMaterialVariantDetail {...props} />}
              />
              <Route
                path="/raw-material-variant-by-vendor/:vendor/:id/:variant"
                render={(props) => <RawMaterialVariantByVendor {...props} />}
              />
              <Route
                path="/vendor-variant-order-detail/:id"
                render={(props) => <VendorVariantOrderDetails {...props} />}
              />
              <Route
                path="/variant-order-stock/:vendor/:process/:orderid"
                render={(props) => <VariantOrderStock {...props} />}
              />
              <Route
                path="/raw-material-by-stock"
                component={RawMaterialByStock}
              />
              <Route path="/assign-transport" component={AssignTransport} />
              <Route
                path="/task-to-contractor/:id"
                component={TaskToContractor}
              />
              <Route
                path="/view-stock-detail/:id"
                component={ViewComponentStock}
              />

              <Route path="/vendor-stock-data" component={VendorStockDetails} />
              <Route path="/rm-receipt" component={RMReceipt} />
              <Route path="/new-rm-receipts" component={NewRMReceipt} />
              <Route path="/view-rm-receipt/:id" component={ViewReceipts} />
              <Route path="/rm-receipts" component={RMReceipts} />
              <Route path="/stock-transfer" component={StockTransfer} />
              <Route path="/in-between-vendors" component={InBetweenVendors} />
              <Route path="/delivery-challan" component={DeliveryChallan} />
              {/*<Route path="/input-stock" component={InputStock} /> */}
              <Route path="/physical-stock" component={PhysicalStock} />
              <Route
                path="/finished-goods-transfer"
                component={FinishedGoodTransfer}
              />
              <Route
                path="/finished-goods-transfer-bom"
                component={FinishedGoodTransferBO}
              />
              <Route path="/scrap-data" component={ScrapData} />
              <Route path="/endbit" component={EndBit} />
              <Route path="/rejections" component={Rejections} />
              <Route path="/assign-vehicle" component={AssignVehicle} />
              <Route
                path="/assign-vehicle-to-pickup"
                component={AssignVehicleToPickup}
              />
              <Route path="/trip-sheet" component={TripSheet} />
              <Route path="/ongoingtrip" component={OnGoingTrip} />
              <Route path="/completedtrip" component={CompletedTrips} />
              <Route path="/trip-detail/:id" component={TripDetail} />
              <Route
                path="/contractor-setting"
                component={ContractorsSetting}
              />
              <Route path="/new-contractor" component={NewContractor} />
              <Route path="/edit-contractor/:id" component={EditContractor} />
              <Route path="/contractor-list" component={ContractorList} />
              <Route path="/contractorpo" component={ContractorPO} />
              <Route path="/components-setting" component={ComponentSetting} />
              <Route path="/new-component" component={NewComponent} />
              <Route path="/components-list" component={ComponentList} />
              <Route path="/process-setting" component={ProcessSetting} />
              <Route path="/new-process" component={NewProcess} />
              <Route path="/edit-process/:id" component={EditProcess} />
              <Route path="/process-list" component={ProcessList} />
              <Route path="/transport-setting" component={TransportSetting} />
              <Route path="/new-transport" component={NewTransport} />
              <Route path="/transport-list" component={TransportList} />
              <Route path="/edit-transport/:id" component={EditTransport} />
              <Route path="/new-vehicle" component={NewVehicle} />
              <Route path="/vehicle-list" component={VehicleList} />
              <Route path="/edit-vehicle/:id" component={EditVehicle} />
              <Route path="/new-driver" component={NewDriver} />
              <Route path="/driver-list" component={DriverList} />
              <Route path="/edit-driver/:id" component={EditDriver} />
              <Route path="/new-transport" component={NewTransport} />
              <Route path="/transport-list" component={TransportList} />
              <Route path="/edit-transport/:id" component={EditTransport} />
              <Route path="/purchase-setting" component={PurchaseSetting} />
              <Route path="/new-purchase" component={NewPurchase} />
              <Route path="/edit-purchaseoder/:id" component={EditPurchase} />
              <Route path="/purchase-list" component={PurchaseList} />
              <Route path="/new-rmpurchase" component={NewRMPurchase} />
              <Route path="/rmpurchase-list" component={RMPurchaseList} />
              <Route
                path="/viewrmpurchaseoder/:id"
                component={ViewRMPurchaseOrder}
              />
              <Route
                path="/rawmaterial-setting"
                component={RawMaterialSetting}
              />
              <Route path="/new-rawmaterial" component={NewRawMaterial} />
              <Route path="/edit-rawmaterial/:id" component={EditRawMaterial} />
              <Route path="/rawmaterial-list" component={RawMaterialList} />
              <Route path="/rawmaterialtype" component={NewRawMaterialType} />
              <Route path="/edit-rawtype/:id" component={EditRawType} />
              <Route
                path="/rawmaterialtypelist"
                component={RawMaterialTypeList}
              />
              <Route
                path="/components-family"
                component={ComponentFamilyList}
              />
              <Route
                path="/edit-componentsfamily/:id"
                component={EditComponentFamily}
              />
              <Route path="/viewcomponents/:id" component={ViewComponent} />
              <Route
                path="/new-component-family"
                component={NewComponentFamily}
              />
              <Route path="/users" component={Users} />
              <Route path="/add-user" component={AddUser} />
              <Route path="/edit-user/:id" component={EditUser} />
              <Route path="/dashboard" component={Home} />
              <Route path="/new-client" component={NewClient} />
              <Route path="/edit-client/:id" component={EditClient} />
              <Route path="/client-list" component={ClientList} />
              <Route path="/client-setting" component={ClientSetting} />
              <Route path="/" component={Home}></Route>
            </Switch>
          </Content>
          <Footer style={{ textAlign: "center" }}>Sarang Auto © 2021</Footer>
          <MobileMenu />
        </Layout>
        <Drawer
          title="My Account"
          placement="right"
          closable={false}
          onClose={onClose}
          visible={visible}
        >
          <h3>{localStorage.getItem("username")}</h3>
          {localStorage.getItem("Organization")} <br></br>
          <br></br>
          <br></br>
          <a href="#" className="logout" onClick={logout}>
            Logout
          </a>
        </Drawer>{" "}
      </Layout>
    </div>
  );
};
export default Dashboard;

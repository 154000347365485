import React, { useReducer, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { DownloadOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Row,
  Col,
  Input,
  Button,
  Select,
  Checkbox,
  Form,
  Radio,
  notification,
  DatePicker,
  Modal,
  message,
} from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import { getServerUrl } from "../../config";
import axios from "axios";
const { Option } = Select;
const contractorlist = [];
const initialList = [];
const NewRMPurchase = () => {
  const [form] = Form.useForm();
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [contractorList, setcontractorList] = React.useState(contractorlist);
  const [rawmaterial, setrawmaterial] = useState([""]);
  const [rawmaterialvariant, setrawmaterialvariant] = useState([""]);
  const [rawmaterialvariantname, setrawmaterialvariantname] = useState([""]);
  const [rawmaterialunit, setrawmaterialunit] = useState([""]);
  const [list, setList] = React.useState(initialList);

  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      vendorname: "",
      raw_material_diameter: "",
      quantity: "",
      price: "",
      rawmaterial_name: "",
      rawmaterialvariantname: "",
      lineitem: "",
      duedate: "",
    }
  );
  function onChange(date, dateString) {
    console.log(dateString);
    userInput.invoice_date = dateString;
  }
  function onDueChange(date, dateString) {
    console.log(dateString);
    userInput.duedate = dateString;
  }
  const handleChange = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;
    setUserInput({ [name]: newValue });
  };
  function vendorNameChange(value) {
    userInput.vendorname = value;
    axios
      .get(`${getServerUrl()}/inventory/rawmeterialbysupplier/${value}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        setrawmaterial(details);
      });
    setList(initialList);
  }
  /* Contractor List */
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/inventory/rawmaterialsupplier/`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        setcontractorList(details);
      });
  }, []);
  const contractorOptions = contractorList.map((dataDrop) => {
    return (
      <Option value={dataDrop.contractor_id}>{dataDrop.contractor_name}</Option>
    );
  });
  function rawvariantChange(value) {
    userInput.raw_material_diameter = value;
  }
  /* Raw Material */

  /* Raw Change */
  function rawChange(value) {
    axios
      .get(`${getServerUrl()}/master/rawmaterial/${value}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data);
        const details = responsevalue;
        details.map((detail) => {
          if (detail.raw_material_diameter) {
            setrawmaterialunit(detail.raw_material_type_unit);
            setrawmaterialvariantname(detail.raw_material_name);
            setrawmaterialvariant(detail.raw_material_diameter.split(","));
          }
        });
      });

    userInput.rawmaterial_name = value;
    userInput.rawmaterialvariantname = rawmaterialvariantname;
  }
  const rawOptions = rawmaterial.map((dataDrop) => {
    return (
      <Option value={dataDrop.raw_material_id}>
        {dataDrop.raw_material_name}
      </Option>
    );
  });
  const rawvariantOptions = rawmaterialvariant.map((dataDrop) => {
    return <Option value={dataDrop}>{dataDrop}</Option>;
  });

  /* Process List */
  const insertLineItem = () => {
    if (
      userInput.raw_material_diameter !== "" &&
      userInput.quantity !== "" &&
      userInput.duedate !== ""
    ) {
      setList(
        list.concat({
          id: Date.now(),
          materialid: userInput.rawmaterial_name,
          materialname: rawmaterialvariantname,
          variant: userInput.raw_material_diameter,
          unit: rawmaterialunit,
          quantity: userInput.quantity,
          price: userInput.price,
          total: userInput.total,
          duedate: userInput.duedate,
        })
      );
      userInput.quantity = "";
      userInput.raw_material_diameter = "";
      userInput.raw_material_diameter = "";
    }
  };

  const deleteLineItme = (id) => {
    setList(list.filter((item) => item.id !== id));
  };
  const openNotification = (type) => {
    notification[type]({
      message: "Raw Material PO Added",
      description: "Raw Material PO added successfully",
    });
  };
  const FailNotification = (type) => {
    notification[type]({
      message: "Error",
      description: "Please Fill out Required Fields",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(list);
    if (list.length > 0) {
      userInput.lineitem = list;
      setIsConfirmModalVisible(true);
    }
    if (list.length > 0 && userInput.vendorname !== "") {
    } else {
      message.error("Please enter atleast one quantity");
    }
  };

  const handleConfirmOk = () => {
    axios
      .post(`${getServerUrl()}/master/creatermpurchase`, userInput, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const data = res.data;
        //console.log(data);
        if (data.success === 1) {
          openNotification("success");
          window.setTimeout(function () {
            window.location.reload();
          }, 3000);
        } else {
        }
      });
    setIsConfirmModalVisible(false);
  };
  const handleConfirmCancel = () => {
    setIsConfirmModalVisible(false);
  };
  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Master Settings </Breadcrumb.Item>
        <Breadcrumb.Item>Purchase Settings</Breadcrumb.Item>
        <Breadcrumb.Item>Add RM Purchase Order</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background">
        <h2 className="titles">
          CREATE RAW MATERIAL <span>PURCHASE ORDER</span>
        </h2>

        <Row>
          <Col span={16}>
            <h2>I. Add Supplier Details</h2>

            <label>Supplier Name:</label>
            <br></br>
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Supplier Name"
              onChange={vendorNameChange}
            >
              {contractorOptions}
            </Select>
            <br></br>
            <label>Supplier PO Date: </label>
            <br></br>
            <DatePicker format={"DD-MM-YYYY"} onChange={onChange} />
            <br></br>
          </Col>
        </Row>
      </div>
      <div className="site-layout-background">
        <Row>
          <Col span={16}>
            <br></br>
            <h2>II. Raw Material Details</h2>
            <label>Select Raw Material</label>
            <br></br>
            <Select
              name="rawmaterial_name"
              onChange={rawChange}
              placeholder="Please Select Rawmaterial"
              style={{ width: "250px" }}
            >
              {rawOptions}
            </Select>
            <br></br>
            <label>Diameter/ Variant</label>
            <br></br>
            <Select
              name="rawmaterial_variant"
              onChange={rawvariantChange}
              placeholder="Please Select Diameter"
              style={{ width: "250px" }}
            >
              {rawvariantOptions}
            </Select>
            <br></br>
            <label>Price </label>
            <Input
              placeholder="Price"
              name="price"
              value={userInput.price}
              onChange={handleChange}
            />
            <label>Quantity (in {rawmaterialunit}) </label>
            <Input
              placeholder="Quantity "
              name="quantity"
              value={userInput.quantity}
              onChange={handleChange}
            />
            <br></br>
            <label>Due Date: </label>
            <br></br>
            <DatePicker format={"DD-MM-YYYY"} onChange={onDueChange} />
            <br></br>
            <label>Total </label>
            <Input
              placeholder="Total"
              name="total"
              disabled
              value={
                (userInput.total =
                  Number(userInput.quantity) * Number(userInput.price))
              }
            />

            <br></br>
            <Button
              type="primary"
              shape="round"
              size="large"
              onClick={insertLineItem}
            >
              Add Line Item
            </Button>
          </Col>
        </Row>

        <br></br>
        <br></br>
      </div>
      <div className="site-layout-background">
        <Row>
          <table
            style={{
              width: "100%",

              tableLayout: "auto",
            }}
          >
            <tr>
              <th>Material Name</th>
              <th>Diameter</th>
              <th>Quantity</th>
              <th>Due Date</th>
              <th>Price</th>
              <th>Total</th>
            </tr>

            {list
              .sort((a, b) => a.order - b.order)
              .map((item) => (
                <tr key={item.id}>
                  <td>{item.materialname}</td>
                  <td>{item.variant}</td>
                  <td>
                    {item.quantity} {item.unit}
                  </td>
                  <td>{item.duedate}</td>
                  <td>{item.price}</td>
                  <td>{item.total}</td>
                  <td>
                    <button onClick={() => deleteLineItme(item.id)}>X</button>
                  </td>
                </tr>
              ))}
          </table>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <Button
            type="primary"
            shape="round"
            size="large"
            onClick={handleSubmit}
          >
            Add PO
          </Button>
        </Row>
      </div>
      <Modal
        title="Please Confirm"
        visible={isConfirmModalVisible}
        onOk={handleConfirmOk}
        okText="Yes, Proceed"
        onCancel={handleConfirmCancel}
        cancelText="No, Go Back"
      >
        <Row>
          <Col span={24}>
            <p
              style={{
                color: "#303030",
                fontSize: "22px",
                textAlign: "center",
              }}
            >
              <CheckCircleFilled
                style={{ fontSize: "36px", color: "#bc0067" }}
              />
              <br></br>
              Are you sure want to proceed?
            </p>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default NewRMPurchase;

import React, { Component, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { getServerUrl } from "../config";
import axios from "axios";
import { Table, Space, Breadcrumb, Input, Button } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv";
// import ReactExport from "react-export-excel";

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const ViewByCSID = (props) => {
  const { id } = props.match.params;
  const { component } = props.match.params;
  const { process } = props.match.params;
  const [details, setdetails] = useState("");
  const [newdetails, setnewdetails] = useState("");
  const [vendorName, setvendorName] = useState("");
  const [componentName, setcomponentName] = useState("");
  const [searchInput, setsearchInput] = useState([""]);
  const [searchText, setsearchText] = useState([""]);
  const [searchedColumn, setsearchedColumn] = useState([""]);
  const [data, setdata] = useState("");

  useEffect(() => {
    axios
      .get(
        `${getServerUrl()}/inventory/getstockstatementreceipt/${id}/${component}/${process}/${localStorage.getItem(
          "fromDateUnix"
        )}/${localStorage.getItem("toDateUnix")}`,
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      )
      .then((res) => {
        if (res.data) {
          let responsevalue = res.data.data;
          const details = responsevalue;
          setdetails(details);
        }
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        `${getServerUrl()}/inventory/getstockstatementreceiptwr/${id}/${component}/${process}`,
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      )
      .then((res) => {
        if (res.data) {
          let responsevalue = res.data.data;
          const details = responsevalue;
          setvendorName(details[0].contractor_name);
          setcomponentName(
            details[0].component_no + " - " + details[0].component_name
          );
        }
      });
  }, []);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setsearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        //setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setsearchedColumn(dataIndex);
    setsearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setsearchText("");
  };
  const columns = [
    {
      title: "Date",
      dataIndex: "create_date",
      ...getColumnSearchProps("create_date"),
    },
    {
      title: "DC",
      dataIndex: "transid",
      key: "transid",
      render: (text, record) =>
        Number(record.transid) > 0 && record.transcation_type === "Outward"
          ? record.vendor_dc
          : record.transid,
    },
    {
      title: "Transcation",
      dataIndex: "transcation_type",
      key: "transcation_type",
      ...getColumnSearchProps("transcation_type"),
    },
    {
      title: "Opening Stock",
      dataIndex: "opening_stock",
      key: "opening_stock",
      ...getColumnSearchProps("opening_stock"),
    },
    {
      title: "Inward",
      dataIndex: "inward",
      key: "inward",
      ...getColumnSearchProps("inward"),
    },
    {
      title: "Outward",
      dataIndex: "outward",
      key: "outward",
      ...getColumnSearchProps("outward"),
    },
    {
      title: "Closing Stock",
      dataIndex: "closing_stock",
      key: "closing_stock",
    },
  ];
  var s = new Date(
    localStorage.getItem("fromDateUnix") * 1000
  ).toLocaleDateString("en-US");
  var e = new Date(
    localStorage.getItem("toDateUnix") * 1000
  ).toLocaleDateString("en-US");

  const TableHeaders = [
    { label: "Date", key: "create_date" },
    { label: "Inward DC", key: "transid" },
    { label: "Outward DC", key: "vendor_dc" },
    {
      label: "Transcation",
      key: "transcation_type",
    },
    { label: "Opening Stock", key: "opening_stock" },
    { label: "Inward", key: "inward" },
    { label: "Outward", key: "outward" },
    { label: "Closing Stock", key: "closing_stock" },
  ];

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Inventory </Breadcrumb.Item>
        <Breadcrumb.Item>
          {vendorName} / {componentName} / {process}
        </Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <h3>Stock Statement</h3>
        <h3 className="titles">
          {vendorName} / {componentName} / {process}
        </h3>
        <Button type="primary" shape="round" size="large">
          <CSVLink
            filename={(
              componentName +
              " - " +
              vendorName +
              " - " +
              process +
              "-" +
              s +
              "-" +
              e
            ).replaceAll(".", "")}
            data={details}
            headers={TableHeaders}
          >
            Download Data
          </CSVLink>
        </Button>
        {/* <ExcelFile
          filename={
            componentName +
            " -- " +
            vendorName +
            " -- " +
            process +
            "-" +
            s +
            "-" +
            e
          }
          element={
            <Button type="primary" shape="round" size="large">
              Download Data
            </Button>
          }
        >
          <ExcelSheet
            data={details}
            name={componentName + " " + vendorName + "" + process}
          >
            <ExcelColumn label="Date" value="create_date" />
            <ExcelColumn
              label="DC"
              value={(col) =>
                col.transcation_type === "Outward" ? col.vendor_dc : col.transid
              }
            />
            <ExcelColumn label="Transcation" value="transcation_type" />
            <ExcelColumn label="Opening Stock" value="opening_stock" />
            <ExcelColumn label="Inward" value="inward" />
            <ExcelColumn label="Outward" value="outward" />
            <ExcelColumn label="Closing Stock" value="closing_stock" />
          </ExcelSheet>
        </ExcelFile> */}
        <Space></Space>
        <br />
        <Table
          scroll={{ x: 1300 }}
          columns={columns}
          dataSource={details}
        ></Table>
      </div>
    </div>
  );
};

export default ViewByCSID;

const GetVendorDC = (prop) => {
  const [qty, setqty] = useState("");
  if (prop.transcation_type === "Outward") {
    axios
      .get(`${getServerUrl()}/transcation/getdc/${prop.id}/`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        if (res.data.data) {
          let responsevalue = Object.values(res.data.data);
          const details = responsevalue;
          if (details.length !== "") {
            setqty(details[0].vendor_dc);
          } else {
            setqty("Not Assign");
          }
        }
      });
  } else {
    setqty(prop.id);
  }

  return qty;
};

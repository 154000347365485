import React, { useReducer } from "react";
import {
  Breadcrumb,
  Row,
  Col,
  Form,
  Input,
  Button,
  notification,
  Select,
} from "antd";
import { NavLink } from "react-router-dom";
import { getServerUrl } from "../../config";
import axios from "axios";

const NewRawMaterialType = () => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      raw_material_type_name: "",
      raw_material_type_unit: "",
    }
  );
  function vendorNameChange(value) {
    userInput.raw_material_type_unit = value;
  }
  const handleChange = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;
    setUserInput({ [name]: newValue });
  };
  const openNotification = (type) => {
    notification[type]({
      message: "Raw Material type Added",
      description: "Raw Material type added successfully",
    });
  };
  const FailNotification = (type) => {
    notification[type]({
      message: "Error",
      description: "Please Fill out Required Fields",
    });
  };
  const onFinish = (values) => {
    console.log(values);
  };
  const onReset = () => {
    form.resetFields();
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (userInput.raw_material_type_name !== "") {
      axios
        .post(`${getServerUrl()}/master/createrawtype`, userInput, {
          headers: { "x-auth-token": localStorage.getItem("token") },
        })
        .then((res) => {
          const data = res.data;

          if (data.success === 1) {
            setUserInput({
              raw_material_type_name: "",
            });
            openNotification("success");
          } else {
          }
        });
    } else {
      FailNotification("error");
    }
  };
  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Master Settings </Breadcrumb.Item>
        <Breadcrumb.Item>Raw Material Settings</Breadcrumb.Item>
        <Breadcrumb.Item>Create New Raw Material Type</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background">
        <h2 className="titles">
          CREATE NEW <span>RAW MATERIAL TYPE</span>
        </h2>

        <Row>
          <Col span={16}>
            <h2>Raw Material Type</h2>
            <label>RM TYPE</label>
            <Input
              name="raw_material_type_name"
              value={userInput.raw_material_type_name}
              onChange={handleChange}
              rules={[
                {
                  required: true,
                  message: "Please enter username!",
                },
              ]}
            />
            <br></br>
            <label>RM TYPE UNIT</label>
            <br></br>
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="RM TYPE UNIT"
              onChange={vendorNameChange}
            >
              <Option value="Kgs">Kgs</Option>
              <Option value="Nos">Nos</Option>
            </Select>

            <br></br>
            <br></br>

            <Button
              type="primary"
              shape="round"
              size="large"
              onClick={handleSubmit}
            >
              Add
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default NewRawMaterialType;

import React, { useReducer, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { getServerUrl } from "../config";
import axios from "axios";
import {
  Table,
  Space,
  Breadcrumb,
  Select,
  Input,
  Button,
  Row,
  Col,
  Tag,
  Modal,
} from "antd";
const { Option } = Select;
const Users = (props) => {
  const [data, setdata] = useState([""]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [transporter, settransporter] = useState([""]);
  const [vehicle, setvehicle] = useState([""]);
  const [driver, setdriver] = useState([""]);
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      transporter: "",
      vehicle: "",
      driver: "",
      tripid: "",
    }
  );
  const handleChange = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;
    setUserInput({ [name]: newValue });
  };

  const showModal = (tripid) => {
    userInput.tripid = tripid;
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        if (res.data) {
          let responsevalue = Object.values(res.data.data);
          const details = responsevalue;
          setdata(details);
        }
      });
  }, []);

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Contact",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "User Type",
      dataIndex: "userType",
      key: "userType",
    },
    {
      title: "Organization",
      dataIndex: "Organization",
      key: "Organization",
      render: (text, record) => record.linkname,
    },
    {
      title: "Action",
      dataIndex: "contractor",
      key: "id",
      render: (text, record) => (
        <Space size="middle">
          <NavLink to={"/edit-user/" + record.id}>View in details</NavLink>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Master Setting</Breadcrumb.Item>
        <Breadcrumb.Item>Users </Breadcrumb.Item>
      </Breadcrumb>

      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <h2 className="titles">
          USERS <span>DETAIL</span>
        </h2>
        <Space></Space>
        <div style={{ textAlign: "right" }}>
          <NavLink to="/add-user" className="ant-btn sarangButton">
            Add a New User
          </NavLink>
        </div>

        <Space></Space>
        <br />
        <Table columns={columns} dataSource={data}></Table>
      </div>
    </div>
  );
};

export default Users;

import React from "react";
import { Breadcrumb, Row, Col, Space, Card } from "antd";
import { BrowserRouter as Router, NavLink } from "react-router-dom";
function TransportSetting() {
  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Master Settings </Breadcrumb.Item>
        <Breadcrumb.Item>Transport Settings</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background">
        <h2 className="titles">
          TRANSPORT <span>SETTINGS</span>
        </h2>
        <Row>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/new-transport">
                <span className="icons icon-special-trip"></span>
                Add New Transport
              </NavLink>
            </div>
          </Col>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/transport-list">
                <span className="icons icon-view-all-receipt"></span>
                View / Manage Transport
              </NavLink>
            </div>
          </Col>
        </Row>
      </div>
      <div className="site-layout-background">
        <h2 className="titles">
          VEHICLE <span>SETTINGS</span>
        </h2>
        <Row>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/new-vehicle">
                <span className="icons icon-assign-vehicle"></span>
                Add New Vehicle
              </NavLink>
            </div>
          </Col>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/vehicle-list">
                <span className="icons icon-view-all-receipt"></span>
                View / Manage Vehicle
              </NavLink>
            </div>
          </Col>
        </Row>
      </div>
      <div className="site-layout-background">
        <h2 className="titles">
          DRIVER <span>SETTINGS</span>
        </h2>

        <Row>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/new-driver">
                <span className="icons icon-transporter-data"></span>
                Add New Driver
              </NavLink>
            </div>
          </Col>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/driver-list">
                <span className="icons icon-view-all-receipt"></span>
                View / Manage Driver
              </NavLink>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default TransportSetting;

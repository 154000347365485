import React from "react";
import { Breadcrumb, Row, Col, Select } from "antd";

const { Option } = Select;
class ContractorPO extends React.Component {
  state = {
    size: "large",
  };

  render() {
    function onChange(date, dateString) {
      console.log(date, dateString);
    }
    const { size } = this.state;

    return (
      <div>
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>Master Settings </Breadcrumb.Item>
          <Breadcrumb.Item>Contractor Settings</Breadcrumb.Item>
          <Breadcrumb.Item>Contractor Purchase Order</Breadcrumb.Item>
        </Breadcrumb>
        <div className="site-layout-background">
          <h2 className="titles">
            CONTRACTOR <span>PURCHASE ORDER</span>
          </h2>

          <Row>
            <Col span={16}>
              <h1>Contractor Purchase details</h1>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default ContractorPO;

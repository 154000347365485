import React, { useReducer, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Breadcrumb, Row, Col, Input, Button, Select, Table } from "antd";
import { getServerUrl } from "../../config";
import axios from "axios";
const { Option } = Select;

const initialpurchaseList = [];
const ViewRMPurchaseOrder = (props) => {
  const { id } = props.match.params;
  let [responseData, setResponseData] = React.useState("");
  let [purchaseListItem, setpurchaseListItem] =
    React.useState(initialpurchaseList);
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/master/getrmpurchaseorder/${id}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const details = res.data;
        if (res.data.success === 1) {
          console.log("", details);
          setResponseData(details.data);
        } else {
          window.location.href = "/rmpurchase-list";
        }
      });
  }, []);
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/master/getrmpurchaseorderitem/${id}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const details = res.data;
        if (res.data.success === 1) {
          console.log("", details);
          setpurchaseListItem(details.data);
        } else {
          window.location.href = "/rmpurchase-list";
        }
      });
  }, []);

  const columns = [
    {
      title: "Raw Material Type",
      dataIndex: "raw_material_type_name",
      key: "raw_material_type_name",
    },
    {
      title: "Raw Material Name",
      dataIndex: "raw_material_name",
      key: "componentName",
    },
    {
      title: "Diameter",
      dataIndex: "variant",
      key: "variant",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "PO Qty",
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) =>
        record.quantity + " " + record.raw_material_type_unit,
    },
    {
      title: "Due Date",
      dataIndex: "duedate",
      key: "duedate",
    },
    {
      title: "Receipt Qty",
      dataIndex: "pousedquantity",
      key: "pousedquantity",
      render: (text, record) =>
        record.pousedquantity + " " + record.raw_material_type_unit,
    },
    {
      title: "Balance PO Qty",
      dataIndex: "poremainquantity",
      key: "poremainquantity",
      render: (text, record) =>
        record.poremainquantity + " " + record.raw_material_type_unit,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
  ];

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Master Settings </Breadcrumb.Item>
        <Breadcrumb.Item>Purchase Settings</Breadcrumb.Item>
        <Breadcrumb.Item>Raw Material Purchase Order</Breadcrumb.Item>
        <Breadcrumb.Item>{responseData.vendorinvoice}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background">
        <h2 className="titles">
          RAW MATERIAL <span>PURCHASE ORDER</span> :{" "}
          {responseData.vendorinvoice}
        </h2>
        <Row>
          {" "}
          <Col span={24}>
            <table>
              <tr>
                <td>
                  {" "}
                  <b>PO NO</b>
                </td>
                <td>:</td>
                <td>{responseData.vendorinvoice}</td>
              </tr>
              <tr>
                <td>
                  <b>Contractor Name</b>
                </td>
                <td>:</td>
                <td>{responseData.contractor_name}</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <b>PO Date</b>
                </td>
                <td>:</td>
                <td>{responseData.po_date}</td>
              </tr>
            </table>
          </Col>
        </Row>
      </div>
      <div className="site-layout-background">
        <h2 className="titles">
          LIST OF <span>ITEMS</span>
        </h2>
        <Row>
          <div
            className="ant-table-content"
            style={{ overflow: "auto hidden" }}
          >
            <Col span="24">
              <Table
                scroll={{ x: 1300 }}
                columns={columns}
                dataSource={purchaseListItem}
              ></Table>
            </Col>
          </div>
        </Row>
      </div>
    </div>
  );
};

export default ViewRMPurchaseOrder;

import React, { useReducer, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  Breadcrumb,
  Row,
  Form,
  Input,
  Checkbox,
  Button,
  notification,
  Select,
  Col,
} from "antd";
import { getServerUrl } from "../../config";
import axios from "axios";
const { Option } = Select;

const NewClient = () => {
  const [form] = Form.useForm();
  const [contractorType, setcontractorType] = useState("");
  const [checkedValues, setcheckedValues] = useState("");
  const [process, setprocess] = useState([""]);
  const [rawtype, setrawtype] = useState([""]);
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      client_name: "",
      client_nick_name: "",
      client_code: "",
      address: "",
      city: "",
      pin_code: "",
      billing_company_name: "",
      contact_number: "",
      gst_number: "",
      pan_number: "",
    }
  );

  const handleChange = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;
    setUserInput({ [name]: newValue });
    // console.log("Datas", userInput);
  };
  function handleChangeNew(checkedValues) {
    setcheckedValues(checkedValues.toString());
  }
  function handleRawTypeChange(checkedValues) {
    setcheckedValues(checkedValues.toString());
  }
  function selecthandleChange(value) {
    setcontractorType(value);
  }

  const openNotification = (type) => {
    notification[type]({
      message: "Company Added",
      description: "Company added successfully",
    });
  };
  const FailNotification = (type) => {
    notification[type]({
      message: "Error",
      description: "Please Fill out Required Fields",
    });
  };

  const onReset = () => {
    form.resetFields();
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (userInput.contractor_name !== "") {
      axios
        .post(`${getServerUrl()}/client/createclient`, userInput, {
          headers: { "x-auth-token": localStorage.getItem("token") },
        })
        .then((res) => {
          const data = res.data;
          console.log(data);
          if (data.success === 1) {
            setUserInput({
              client_name: "",
              client_nick_name: "",
              client_code: "",
              address: "",
              city: "",
              pin_code: "",
              contact_number: "",
              billing_company_name: "",
              gst_number: "",
              pan_number: "",
            });
            form.resetFields();
            openNotification("success");
          } else {
          }
        });
    } else {
      FailNotification("error");
    }
  };

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Master Settings </Breadcrumb.Item>
        <Breadcrumb.Item>Client Settings</Breadcrumb.Item>
        <Breadcrumb.Item>Create New</Breadcrumb.Item>
      </Breadcrumb>
      <Form form={form}>
        <div className="site-layout-background">
          <h2 className="titles">
            CREATE NEW <span>CLIENT</span>
          </h2>

          <Row>
            <Col span={16}>
              <h2>I. Client Details</h2>
              <label>Client Name</label>
              <Input
                name="client_name"
                value={userInput.client_name}
                onChange={handleChange}
              />
              <label>Nick Name</label>
              <Input
                name="client_nick_name"
                value={userInput.client_nick_name}
                onChange={handleChange}
              />
              <label>Client Code</label>
              <Input
                name="client_code"
                value={userInput.client_code}
                onChange={handleChange}
              />
            </Col>
          </Row>
        </div>

        <div className="site-layout-background">
          <Row>
            <Col span={16}>
              <h2>II. Address & Contact Details</h2>
              <label>Address</label>
              <Input
                name="address"
                value={userInput.address}
                onChange={handleChange}
              />
              <label>City</label>
              <Input
                name="city"
                value={userInput.city}
                onChange={handleChange}
              />
              <label>PIN Code</label>
              <Input
                name="pin_code"
                value={userInput.pin_code}
                onChange={handleChange}
              />
              <label>Contact No.</label>
              <Input
                name="contact_number"
                value={userInput.contact_number}
                onChange={handleChange}
              />
            </Col>
          </Row>
        </div>

        <div className="site-layout-background">
          <Row>
            <Col span={16}>
              <h2>IV. Billing Details</h2>
              <label>Company Name</label>
              <Input
                name="billing_company_name"
                value={userInput.billing_company_name}
                onChange={handleChange}
              />
              <label>GST No.</label>
              <Input
                name="gst_number"
                value={userInput.gst_number}
                onChange={handleChange}
              />
              <label>PAN No.</label>
              <Input
                name="pan_number"
                value={userInput.pan_number}
                onChange={handleChange}
              />

              <br></br>
              <br></br>
              <Button
                type="primary"
                shape="round"
                size="large"
                onClick={handleSubmit}
              >
                Add Client
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};

export default NewClient;

import React from "react";
import { Breadcrumb, Row, Col, Input, Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { BrowserRouter as Router, NavLink } from "react-router-dom";

class InBetweenVendors extends React.Component {
  state = {
    size: "large",
  };

  render() {
    function onChange(date, dateString) {
      console.log(date, dateString);
    }
    const { size } = this.state;

    return (
      <div>
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>Inventory</Breadcrumb.Item>
          <Breadcrumb.Item>Stock Transfer</Breadcrumb.Item>
          <Breadcrumb.Item>In-Between Vendors</Breadcrumb.Item>
        </Breadcrumb>
        <div className="site-layout-background">
          <h2 className="titles">STOCK TRANSFER : IN-BETWEEN VENDORS</h2>
          <Row>
            <Col span={16}>
              <h2>I. Select Component & Vendor (Source) </h2>
              <label>Component Name:</label>
              <Input placeholder="Supplier Name" />
              <label>Vendor (source):</label>
              <Input placeholder="Vendor (source)" />
              <label>Ready Quantity:</label>
              <Input placeholder="Ready Quantity" />
            </Col>
          </Row>
        </div>
        <div className="site-layout-background">
          <Row>
            <Col span={16}>
              <h2>II. Select Transfer Quantity & Vendor (Destination)</h2>
              <label>Transfer Quantity:</label>
              <Input placeholder="Transfer Quantity" />
              <label>Rejection Quantity:</label>
              <Input placeholder="Rejection Quantity" />
              <label>Vendor (destination)</label>
              <Input placeholder="Vendor (destination)" />
              <label>Reason/ Narration </label>
              <Input placeholder="Reason/ Narration" />
              <Button shape="round" size={size}>
                MOVE TO READY FOR DELIVERY
              </Button>
            </Col>
          </Row>
        </div>
        <div className="site-layout-background">
          <Row>
            <Col span={16}>
              <br></br>
              <h2>III. Select Transport Details</h2>
              <label>Pickup Point:</label>
              <Input placeholder="Pickup Point" />
              <label>Pickup Date:</label>
              <Input placeholder="Pickup Date:" />
              <label>Drop Point</label>
              <Input placeholder="Drop Point" />
              <label>Delivery Date</label>
              <Input placeholder="Delivery Date" />
              <label>Transporter</label>
              <Input placeholder="Transporter" />
              <Button type="primary" shape="round" size={size}>
                Transfer
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default InBetweenVendors;

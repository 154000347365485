import React, { useReducer, useState, useEffect } from "react";
import {
  Breadcrumb,
  Row,
  Col,
  Form,
  Input,
  Checkbox,
  Button,
  notification,
  Select,
} from "antd";
import { NavLink } from "react-router-dom";
import { getServerUrl } from "../../config";
import axios from "axios";
const { Option } = Select;
const NewDriver = () => {
  const [form] = Form.useForm();
  const [checkedValues, setcheckedValues] = useState("");
  const [transport, settransport] = useState([""]);
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      driver_name: "",
      driver_phone: "",
      t_id: "",
    }
  );

  const handleChange = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;
    setUserInput({ [name]: newValue });
  };

  function selecthandleChange(value) {
    setcheckedValues(value);
  }
  const openNotification = (type) => {
    notification[type]({
      message: "Driver Added",
      description: "Driver added successfully",
    });
  };
  const FailNotification = (type) => {
    notification[type]({
      message: "Error",
      description: "Please Fill out Required Fields",
    });
  };
  const onFinish = (values) => {
    userInput.t_id = checkedValues;
    //console.log(userInput);
    if (userInput.driver_name !== "") {
      axios
        .post(`${getServerUrl()}/master/createdriver`, userInput, {
          headers: { "x-auth-token": localStorage.getItem("token") },
        })
        .then((res) => {
          const data = res.data;
          //console.log(data);
          if (data.success === 1) {
            setUserInput({
              driver_name: "",
              driver_phone: "",
              t_id: "",
            });
            form.resetFields();
            openNotification("success");
          } else {
          }
        });
    } else {
      FailNotification("error");
    }
  };
  const onReset = () => {
    form.resetFields();
  };
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/master/transport`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        settransport(details);
      });
  }, []);
  const dropDownOptions = transport.map((dataDrop) => {
    return <Option value={dataDrop.t_id}>{dataDrop.transporter_name}</Option>;
  });
  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Master Settings </Breadcrumb.Item>
        <Breadcrumb.Item>Transport Settings</Breadcrumb.Item>
        <Breadcrumb.Item>Create New Driver</Breadcrumb.Item>
      </Breadcrumb>
      <Form form={form} name="control-hooks" onFinish={onFinish}>
        <div className="site-layout-background">
          <h2 className="titles">
            ADD NEW <span>DRIVER</span>
          </h2>
          <Row>
            <Col span={16}>
              <h2> Drivers</h2>
              <label>Driver Name</label>
              <Form.Item
                name="driver_name_label"
                rules={[
                  {
                    required: true,
                    message: "Please enter driver name!",
                  },
                ]}
              >
                <Input
                  name="driver_name"
                  value={userInput.driver_name}
                  onChange={handleChange}
                />
              </Form.Item>
              <label>Driver Phone</label>
              <Form.Item
                name="driver_phone_label"
                rules={[
                  {
                    required: true,
                    message: "Please enter driver phone!",
                  },
                ]}
              >
                <Input
                  name="driver_phone"
                  value={userInput.driver_phone}
                  onChange={handleChange}
                />
              </Form.Item>
              <label>Transporter</label>
              <br></br>
              <Select
                name="raw_material_type"
                onChange={selecthandleChange}
                placeholder="Please Select Type"
                style={{ width: "250px" }}
              >
                {dropDownOptions}
              </Select>
              <br></br> <br></br>
              <Button
                type="primary"
                shape="round"
                htmlType="submit"
                size="large"
              >
                Add Driver
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};

export default NewDriver;

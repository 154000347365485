import React from "react";
import { Table, Tag, Space, Breadcrumb } from "antd";
const { Column, ColumnGroup } = Table;
function InventoryData() {
  const data = [
    {
      key: "1",
      vendorName: "AKS Enterprises",
      noComponents: "4",
      closingStock: "400 units",
      readyQty: "200 units",
    },
    {
      key: "2",
      vendorName: "SV Enterprises",
      noComponents: "4",
      closingStock: "300 units",
      readyQty: "200 units",
    },
    {
      key: "1",
      vendorName: "ACM Pvt Ltd ",
      noComponents: "3",
      closingStock: "100 units",
      readyQty: "150 units",
    },
  ];
  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Vendors</Breadcrumb.Item>
        <Breadcrumb.Item>Vendors List</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <Table dataSource={data}>
          <Column title="Vendor Name" dataIndex="vendorName" key="vendorName" />
          <Column
            title="No. of Components"
            dataIndex="noComponents"
            key="noComponents"
          />
          <Column
            title="Closing Stock"
            dataIndex="closingStock"
            key="closingStock"
          />
          <Column
            title="Closing Stock"
            dataIndex="closingStock"
            key="closingStock"
          />{" "}
          <Column title="Ready Qty" dataIndex="readyQty" key="readyQty" />
          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <Space size="middle">
                <a>View</a>
              </Space>
            )}
          />
        </Table>
      </div>
    </div>
  );
}

export default InventoryData;

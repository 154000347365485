import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { getServerUrl } from "../../config";
import axios from "axios";
import {
  Table,
  Space,
  Breadcrumb,
  Select,
  Input,
  Button,
  Row,
  Col,
  Tag,
  Modal,
} from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined, CheckCircleFilled } from "@ant-design/icons";
const { Option } = Select;
class RawMaterialList extends Component {
  constructor() {
    super();
  }
  state = {
    details: [],
    newdetails: [],
    data: [],
    isConfirmModalVisible: false,
    paramaterValue: "",
  };

  search = async (value) => {
    const newdetails = [];
    axios
      .get(`${getServerUrl()}/master/contractor/${value}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        // this.setState({ details });
      });
  };
  onChange = async (value) => {
    this.search(value);
  };
  componentDidMount() {
    const details = [];
    axios
      .get(`${getServerUrl()}/master/rawmaterial`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        this.setState({ details });
      });
  }
  componentDidUpdate() {
    console.log("New Data");
  }
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  render() {
    const data = this.state.details;
    const dataDropDown = this.state.details;
    const dropDownOptions = dataDropDown.map((dataDrop) => {
      return (
        <Option value={dataDrop.process_id}>{dataDrop.process_name}</Option>
      );
    });
    const columns = [
      {
        title: "Raw Material Name",
        dataIndex: "raw_material_name",
        key: "raw_material_name",
        width: "30%",
        ...this.getColumnSearchProps("raw_material_name"),
      },
      {
        title: "Raw Material Type",
        dataIndex: "raw_material_type_name",
        key: "raw_material_type_name",
        width: "30%",
        ...this.getColumnSearchProps("raw_material_type_name"),
      },
      {
        title: "Raw Material Variant",
        dataIndex: "raw_material_diameter",
        key: "raw_material_diameter",
        width: "20%",
        ...this.getColumnSearchProps("raw_material_diameter"),
        render: (raw_material_diameter) => (
          <>
            {raw_material_diameter.split(",").map((tag) => {
              let color = "blue";

              return (
                <Tag color={color} key={tag}>
                  {tag.toUpperCase()}
                </Tag>
              );
            })}
          </>
        ),
      },
      {
        title: "Action",
        dataIndex: "raw_material_id",
        key: "raw_material_id",
        render: (text, record) => (
          <Space size="middle">
            <NavLink to={"/edit-rawmaterial/" + record.raw_material_id}>
              View / Manage
            </NavLink>
          </Space>
        ),
      },
      {
        title: "Delete",
        dataIndex: "raw_material_id",
        key: "raw_material_id",
        render: (text, record) => (
          <Space size="middle">
            <Button
              onClick={() => {
                deleteRawMaterial(record.raw_material_id);
              }}
            >
              Delete RM
            </Button>
          </Space>
        ),
      },
    ];

    const deleteRawMaterial = (params) => {
      this.setState({
        paramaterValue: params,
        isConfirmModalVisible: true,
      });
    };

    const handleConfirmOk = () => {
      axios
        .post(
          `${getServerUrl()}/master/deleterawmaterial`,
          { id: this.state.paramaterValue },
          {
            headers: { "x-auth-token": localStorage.getItem("token") },
          }
        )
        .then((res) => {
          let responsevalue = Object.values(res.data.data);
          const details = responsevalue;
          window.location.reload();
        });
      this.setState({
        isConfirmModalVisible: false,
      });
    };
    const handleConfirmCancel = () => {
      this.setState({
        isConfirmModalVisible: false,
      });
    };
    return (
      <div>
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>Master Settings </Breadcrumb.Item>
          <Breadcrumb.Item>Raw Material Settings</Breadcrumb.Item>
          <Breadcrumb.Item>Raw Material List</Breadcrumb.Item>
        </Breadcrumb>
        <div
          className="site-layout-background"
          style={{ padding: 24, minHeight: 360 }}
        >
          <h2 className="titles">
            VIEW / MANAGE RAW MATERIAL <span>DATA</span>
          </h2>

          <Space></Space>
          <br />
          <Table columns={columns} dataSource={data}></Table>
        </div>
        <Modal
          title="Please Confirm"
          visible={this.state.isConfirmModalVisible}
          onOk={handleConfirmOk}
          okText="Yes, Proceed"
          onCancel={handleConfirmCancel}
          cancelText="No, Go Back"
        >
          <Row>
            <Col span={24}>
              <p
                style={{
                  color: "#303030",
                  fontSize: "22px",
                  textAlign: "center",
                }}
              >
                <CheckCircleFilled
                  style={{ fontSize: "36px", color: "#bc0067" }}
                />
                <br></br>
                Are you sure want to proceed?
              </p>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

export default RawMaterialList;

import React, { Component, useRef, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Pdf from "react-to-pdf";
import ReactToPrint from "react-to-print";
import { getServerUrl } from "../config";
import axios from "axios";
import { Table, Space, Breadcrumb, Input, Button, Select, Col } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv";

const { Option } = Select;
const ref = React.createRef();
const VendorReportPDF = (props) => {
  const componentRef = useRef();
  const options = {
    orientation: "portrait",
    unit: "in",
    format: "a4",
    page: "number",
  };
  const { id } = props.match.params;
  const [details, setdetails] = useState([]);
  const [contractordetails, setcontractordetails] = useState([""]);
  const [searchInput, setsearchInput] = useState([""]);
  const [searchText, setsearchText] = useState([""]);
  const [searchedColumn, setsearchedColumn] = useState([""]);
  const [contractor, setcontractor] = useState([""]);
  const [contractorid, setcontractorid] = useState();
  const [data, setdata] = useState("");

  useEffect(() => {
    if (id != "All") {
      axios
        .get(`${getServerUrl()}/master/contractor/${id}`, {
          headers: { "x-auth-token": localStorage.getItem("token") },
        })
        .then((res) => {
          let responsevalue = res.data.data;
          const details = responsevalue;
          setcontractordetails(details);
          console.log(contractordetails);
        });
      axios
        .get(
          `${getServerUrl()}/transcation/getcompletedtripbycontractor/${id}/${localStorage.getItem(
            "fromDateUnix"
          )}/${localStorage.getItem("toDateUnix")}`,
          {
            headers: { "x-auth-token": localStorage.getItem("token") },
          }
        )
        .then((res) => {
          let responsevalue = res.data.data;
          const datadetails = responsevalue;
          setdetails(datadetails);
        });
    } else {
      axios
        .get(
          `${getServerUrl()}/transcation/getcompletedtripbycontractor/All/${localStorage.getItem(
            "fromDateUnix"
          )}/${localStorage.getItem("toDateUnix")}`,
          {
            headers: { "x-auth-token": localStorage.getItem("token") },
          }
        )
        .then((res) => {
          let responsevalue = res.data.data;
          const datadetails = responsevalue;
          setdetails(datadetails);
        });
    }
  }, []);

  var s = new Date(
    localStorage.getItem("fromDateUnix") * 1000
  ).toLocaleDateString("ta-IN");
  var e = new Date(
    localStorage.getItem("toDateUnix") * 1000
  ).toLocaleDateString("ta-IN");
  const TableHeaders = [
    { label: "Vendor", key: "source_name" },
    { label: "Component ID & Component Name", key: "componentName" },
    { label: "Process", key: "processName" },
    {
      label: "Quantity",
      key: "destination_confirm_quantity",
    },
    { label: "PO Rate", key: "rate" },
    { label: "Total Price", key: "totalPrice" },
  ];
  let sum = details.reduce(function (prev, current) {
    return prev + +current.destination_confirm_quantity;
  }, 0);
  let sumoftotal = details.reduce(function (prev, current) {
    return prev + +current.totalPrice;
  }, 0);
  const contractorName =
    id !== "All" ? contractordetails.contractor_name : "All";
  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Report </Breadcrumb.Item>
        <Breadcrumb.Item>Contractor Report</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background bookingconfirm"
        style={{ padding: 24, minHeight: 360 }}
      >
        <h2 className="titles">
          {contractordetails ? contractorName : ""} Report
        </h2>
        <br></br>
        {details.length > 0 ? (
          <Button type="primary" shape="round" size="large">
            <CSVLink
              filename={(
                "Contractor Report-" +
                contractorName +
                s +
                "-" +
                e
              ).replaceAll(".", "")}
              data={details}
              headers={TableHeaders}
            >
              Download as Excel
            </CSVLink>
          </Button>
        ) : (
          ""
        )}{" "}
        <ReactToPrint
          type="primary"
          shape="round"
          size="large"
          trigger={() => <button>Print this out!</button>}
          content={() => componentRef.current}
        />
        <br></br>
        <div style={{ padding: "20px" }} ref={componentRef}>
          <table width="766.8" border="1" cellSpacing={2}>
            <tr>
              <td colSpan={6} style={{ textAlign: "center" }}>
                <h1
                  style={{
                    color: "#a61621",
                    margin: "0",
                    fontSize: "1.5em",
                    lineHeight: "1.5",
                  }}
                >
                  SARANG AUTOPARTS (P) LIMITED
                </h1>
                <p
                  style={{
                    fontSize: "12px",
                    color: "#303030",
                    margin: "0",
                    lineHeight: "1.5",
                    textAlign: "center",
                  }}
                >
                  49B, SIDCO Industrial Estate (NP) Ambattur, Chennai – 600098.
                  <br />
                  Phone: 044-26253990 <br /> GST No: 33AAFCS0712HIZT | CIN
                  No.U35999TN1993PTC026437|PAN No.AAFCS0712H <br />
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan={6}>
                <table width={"100%"}>
                  <tr style={{ verticalAlign: "top" }}>
                    <td width={"60%"}>
                      <b>{contractordetails.contractor_name}</b>
                      <br />
                      <p
                        style={{
                          fontSize: "12px",
                          color: "#303030",
                          margin: "0",
                          lineHeight: "1.5",
                        }}
                      >
                        {contractordetails.address}
                      </p>
                      <p
                        style={{
                          fontSize: "12px",
                          color: "#303030",
                          margin: "0",
                          lineHeight: "1.5",
                        }}
                      >
                        {contractordetails.city}
                      </p>
                      <p
                        style={{
                          fontSize: "12px",
                          color: "#303030",
                          margin: "0",
                          lineHeight: "1.5",
                        }}
                      >
                        {contractordetails.pin_code}
                      </p>
                      <p
                        style={{
                          fontSize: "12px",
                          color: "#303030",
                          margin: "0",
                          lineHeight: "1.5",
                        }}
                      >
                        <b>Contact:</b> {contractordetails.contact_number}
                      </p>
                      <p
                        style={{
                          fontSize: "12px",
                          color: "#303030",
                          margin: "0",
                          lineHeight: "1.5",
                        }}
                      >
                        <b>GST:</b> {contractordetails.gst_number}
                      </p>
                    </td>
                    <td width={"40%"}>
                      <p
                        style={{
                          fontSize: "16px",
                          color: "#303030",
                          margin: "0",
                          lineHeight: "1.5",
                        }}
                      >
                        <b>Report:</b> {s} to {e}
                      </p>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <p
                  style={{
                    fontSize: "12px",
                    color: "#303030",
                    margin: "0",
                    fontWeight: "bold",
                  }}
                >
                  Vendor
                </p>
              </td>
              <td>
                <p
                  style={{
                    fontSize: "12px",
                    color: "#303030",
                    margin: "0",
                    fontWeight: "bold",
                  }}
                >
                  Component
                </p>
              </td>
              <td>
                {" "}
                <p
                  style={{
                    fontSize: "12px",
                    color: "#303030",
                    margin: "0",
                    fontWeight: "bold",
                  }}
                >
                  Process Name
                </p>
              </td>
              <td>
                {" "}
                <p
                  style={{
                    fontSize: "12px",
                    color: "#303030",
                    margin: "0",
                    fontWeight: "bold",
                  }}
                >
                  Quantity
                </p>
              </td>
              <td>
                {" "}
                <p
                  style={{
                    fontSize: "12px",
                    color: "#303030",
                    margin: "0",
                    fontWeight: "bold",
                  }}
                >
                  Price
                </p>
              </td>
              <td>
                {" "}
                <p
                  style={{
                    fontSize: "12px",
                    color: "#303030",
                    margin: "0",
                    fontWeight: "bold",
                  }}
                >
                  Sum
                </p>
              </td>
            </tr>
            {details.length > 0
              ? details.map((orderdata) => (
                  <tr>
                    <td>
                      <p
                        style={{
                          fontSize: "12px",
                          color: "#303030",
                          margin: "0",
                        }}
                      >
                        {orderdata.source_name}
                      </p>
                    </td>
                    <td>
                      <p
                        style={{
                          fontSize: "12px",
                          color: "#303030",
                          margin: "0",
                        }}
                      >
                        {orderdata.componentName}
                      </p>
                    </td>
                    <td>
                      <p
                        style={{
                          fontSize: "12px",
                          color: "#303030",
                          margin: "0",
                        }}
                      >
                        {orderdata.processName}
                      </p>
                    </td>
                    <td>
                      <p
                        style={{
                          fontSize: "12px",
                          color: "#303030",
                          margin: "0",
                        }}
                      >
                        {orderdata.destination_confirm_quantity}
                      </p>
                    </td>
                    <td>
                      <p
                        style={{
                          fontSize: "12px",
                          color: "#303030",
                          margin: "0",
                        }}
                      >
                        {orderdata.rate}
                      </p>
                    </td>
                    <td>
                      <p
                        style={{
                          fontSize: "12px",
                          color: "#303030",
                          margin: "0",
                        }}
                      >
                        {orderdata.totalPrice
                          ? orderdata.totalPrice.toFixed(2)
                          : ""}
                      </p>
                    </td>
                  </tr>
                ))
              : ""}
            <tr>
              <td></td>
              <td></td>
              <td>
                <p
                  style={{
                    fontSize: "12px",
                    color: "#303030",
                    margin: "0",
                    lineHeight: "1.5",
                  }}
                >
                  Total
                </p>
              </td>
              <td>
                <p
                  style={{
                    fontSize: "12px",
                    color: "#303030",
                    margin: "0",
                    lineHeight: "1.5",
                    fontWeight: "bold",
                  }}
                >
                  {sum}
                </p>
              </td>
              <td></td>
              <td>
                {" "}
                <p
                  style={{
                    fontSize: "12px",
                    color: "#303030",
                    margin: "0",
                    lineHeight: "1.5",
                    fontWeight: "bold",
                  }}
                >
                  {sumoftotal}
                </p>
              </td>
            </tr>
          </table>
        </div>
        {/* <Pdf
          targetRef={ref}
          filename={contractordetails.contractor_name + ".pdf"}
          options={options}
          paperSize="A4"
          margin="1cm"
          x={0.5}
          y={0.5}
          scale={0.9}
        >
          {({ toPdf }) => (
            <a onClick={toPdf} className="Download">
              Download
            </a>
          )}
        </Pdf> */}
        <br></br>
        <ReactToPrint
          type="primary"
          shape="round"
          size="large"
          trigger={() => <button>Print this out!</button>}
          content={() => componentRef.current}
        />
      </div>
    </div>
  );
};

export default VendorReportPDF;

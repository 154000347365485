import React, { useReducer, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { getServerUrl } from "../config";
import axios from "axios";
import {
  Table,
  Space,
  Breadcrumb,
  Select,
  Input,
  Button,
  Row,
  Col,
  Tag,
  Modal,
} from "antd";
const { Option } = Select;
const TripDetail = (props) => {
  const { id } = props.match.params;
  const [data, setdata] = useState([""]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [transporter, settransporter] = useState([""]);
  const [vehicle, setvehicle] = useState([""]);
  const [driver, setdriver] = useState([""]);
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      transporter: "",
      vehicle: "",
      driver: "",
      tripid: "",
    }
  );
  const handleChange = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;
    setUserInput({ [name]: newValue });
  };

  const showModal = (tripid) => {
    userInput.tripid = tripid;
    setIsModalVisible(true);
  };

  const handleOk = () => {
    console.log(userInput);
    if (userInput.tripid !== "" && userInput.driver !== "") {
      axios
        .post(`${getServerUrl()}/transcation/updatetriptrans/`, userInput, {
          headers: { "x-auth-token": localStorage.getItem("token") },
        })
        .then((res) => {
          let responsevalue = Object.values(res.data.data);
          const details = responsevalue;
          setdata(details);
        });
      setIsModalVisible(false);
      window.location.reload();
    }
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/transcation/gettripdetail/${id}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        setdata(details);
      });
  }, []);
  /* Transport */
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/master/transport`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = res.data.data;
        const details = responsevalue;
        settransporter(details);
      });
  }, []);

  const TransporterOptions = transporter.map((dataDrop) => {
    return <Option value={dataDrop.t_id}>{dataDrop.transporter_name}</Option>;
  });
  function transporterChange(value) {
    userInput.transporter = value;
    axios
      .get(`${getServerUrl()}/master/getdriverbytransport/${value}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = res.data.data;
        const details = responsevalue;
        setdriver(details);
      });
    axios
      .get(`${getServerUrl()}/master/vehiclebytransporter/${value}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = res.data.data;
        const details = responsevalue;
        setvehicle(details);
      });
  }
  const VehicleOptions = vehicle.map((dataDrop) => {
    return (
      <Option value={dataDrop.vehicle_id}>
        {dataDrop.vehicle_type} - {dataDrop.vehicle_number}
      </Option>
    );
  });
  const DriverOptions = driver.map((dataDrop) => {
    return <Option value={dataDrop.d_id}>{dataDrop.driver_name}</Option>;
  });
  function vehicleChange(value) {
    userInput.vehicle = value;
  }
  function driverChange(value) {
    userInput.driver = value;
  }

  const columns = [
    {
      title: "Trip Date",
      dataIndex: "transdate",
      key: "transdate",
    },
    {
      title: "Source",
      dataIndex: "source_name",
      key: "source_name",
    },
    {
      title: "Destination",
      dataIndex: "destination_name",
      key: "destination_name",
    },
    {
      title: "Component no",
      dataIndex: "component_no",
      key: "component_no",
    },
    {
      title: "Component Name",
      dataIndex: "component_name",
      key: "component_name",
    },

    {
      title: "Quantity",
      dataIndex: "instock",
      key: "instock",
    },

    {
      title: "Status",
      dataIndex: "transstatus",
      key: "transstatus",
    },
    {
      title: "Reassign",
      dataIndex: "transid",
      key: "transid",
      render: (text, record) =>
        record.transstatus && record.transstatus === "Assigned" ? (
          <Space size="middle">
            <Button type="secondary" onClick={() => showModal(record.transid)}>
              Reassign Trip
            </Button>
          </Space>
        ) : (
          ""
        ),
    },
  ];

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Logistics</Breadcrumb.Item>
        <Breadcrumb.Item>Tripdetail </Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <h2 className="titles">
          TRIP <span>DETAIL</span>
        </h2>

        <Space></Space>
        <br />
        <Table columns={columns} dataSource={data}></Table>
      </div>
      <Modal
        title="Reassign the Trip"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <label>Transporter:</label>
        <br></br>
        <Select
          name="transporter"
          onChange={transporterChange}
          placeholder="Please Select Transporter"
          style={{ width: "250px" }}
        >
          {TransporterOptions}
        </Select>
        <br></br>
        <br></br>
        <label>Vehicle:</label>
        <br></br>
        <Select
          name="vehicle"
          onChange={vehicleChange}
          placeholder="Please Select Vehicle"
          style={{ width: "250px" }}
        >
          {VehicleOptions}
        </Select>
        <br></br>
        <br></br>
        <label>Driver:</label>
        <br></br>
        <Select
          name="driver_name"
          onChange={driverChange}
          placeholder="Please Select Transporter"
          style={{ width: "250px" }}
        >
          {DriverOptions}
        </Select>
        <br></br>
        <br></br>
      </Modal>
    </div>
  );
};

export default TripDetail;

import React, { useReducer, useState, useEffect } from "react";
import {
  Breadcrumb,
  Row,
  Col,
  Space,
  Card,
  DatePicker,
  Select,
  Input,
  Button,
  notification,
} from "antd";
import { NavLink } from "react-router-dom";
import { getServerUrl } from "../../config";
import axios from "axios";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
const { Option } = Select;
const initialList = [];
const initialrawList = [];
const initialChildComponentList = [];
const NewComponent = () => {
  const [componentType, setcomponentType] = useState("");
  const [process, setprocess] = useState([""]);
  const [rawtype, setrawtype] = useState([""]);
  const [rawmaterial, setrawmaterial] = useState([""]);
  const [componentfamily, setcomponentfamily] = useState([""]);
  const [subcomponent, setsubcomponent] = useState([""]);
  const [component, setcomponent] = useState([""]);
  const [componentfamilyvalue, setcomponentfamilyvalue] = useState([""]);
  const [rawmaterialvariant, setrawmaterialvariant] = useState([""]);
  const [rawmaterialvariantname, setrawmaterialvariantname] = useState([""]);
  const [list, setList] = React.useState(initialList);
  const [rawList, setrawList] = React.useState(initialrawList);
  const [ChildName, setChildName] = useState("");
  const [ChildComponentList, setChildComponentList] = React.useState(
    initialChildComponentList
  );
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      component_name: "",
      component_description: "",
      component_type: "",
      component_number: "",
      component_family: "",
      child_component: "",
      component_price: "",
      process_name: "",
      process_realname: "",
      process_desc: "",
      operation_order: "",
      process: "",
      childcomponent: "",
      rawmaterial_name: "",
      rawmaterial_variant: "",
      rawmaterial_length: "",
      rawmaterial_weight: "",
      rawmaterialvariantname: "",
      raw_material_diameter: "",
      child_component_quantity: "",
    }
  );

  const handleChange = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;
    setUserInput({ [name]: newValue });
  };

  function selecthandleChange(value) {
    setcomponentType(value);
    setChildComponentList([]);
    setList([]);
  }

  function selectComponentFamilyChange(value) {
    setcomponentfamilyvalue(value);
  }

  function processChange(value) {
    userInput.process_name = value;
    axios
      .get(`${getServerUrl()}/master/process/${value}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data);
        const details = responsevalue;
        details.map((detail) => {
          userInput.process_realname = detail.process_name;
        });
      });
  }

  function rawvariantChange(value) {
    userInput.raw_material_diameter = value;
  }

  function childComponentChange(value) {
    userInput.child_component = value;
    axios
      .get(`${getServerUrl()}/master/component/${userInput.child_component}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const details = res.data;
        setChildName(
          details.data.component_no + " | " + details.data.component_name
        );
      });
  }
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/master/rawtype`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        setrawtype(details);
      });
  }, []);
  const RawTypeDownOptions = rawtype.map((dataDrop) => {
    return (
      <Option value={dataDrop.rawtype_id}>
        {dataDrop.raw_material_type_name}
      </Option>
    );
  });

  /* Child Component Change */

  const insertChildComponent = () => {
    if (
      userInput.child_component !== "" &&
      userInput.child_component_quantity
    ) {
      if (
        !ChildComponentList.some(
          (item) => item.child_component === userInput.child_component
        )
      ) {
        setChildComponentList(
          ChildComponentList.concat({
            id: Date.now(),
            child_component: userInput.child_component,
            child_name: ChildName,
            child_component_quantity: userInput.child_component_quantity,
          })
        );
      }

      userInput.child_component = "";
      userInput.child_component_quantity = "";
    }
  };
  const deleteComponent = (id) => {
    setChildComponentList(ChildComponentList.filter((item) => item.id !== id));
  };

  /* Process List */
  const insertProcess = () => {
    if (userInput.process_name !== "" && userInput.operation_order !== "") {
      if (isNaN(userInput.operation_order)) {
        userInput.operation_order = 1;
      }
      setList(
        list.concat({
          id: Date.now(),
          processid: userInput.process_name,
          processname: userInput.process_realname,
          processdesc: userInput.process_desc,
          order: Number(userInput.operation_order),
        })
      );
      userInput.process_name = "";
      userInput.process_desc = "";
      userInput.operation_order = "";
    }
  };

  const handleClick = (id) => {
    setList(list.filter((item) => item.id !== id));
  };
  const rawdelete = (id) => {
    setrawList(rawList.filter((item) => item.id !== id));
  };
  const orderUp = (id, order) => {
    if (Number(order) < 0) {
      order = 1;
    }
    setList(
      list.map((item) => {
        if (item.id === id) {
          return { ...item, order: Number(order) - 10 };
        } else {
          return item;
        }
      })
    );
  };
  const orderDown = (id, order) => {
    if (Number(order) < 0) {
      order = 0;
    }
    setList(
      list.map((item) => {
        if (item.id === id) {
          return { ...item, order: Number(order) + 10 };
        } else {
          return item;
        }
      })
    );
  };
  /* Process List */
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/master/process`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        setprocess(details);
      });
  }, []);

  /* Raw Material */
  function rawChange(value) {
    axios
      .get(`${getServerUrl()}/master/rawmaterial/${value}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data);
        const details = responsevalue;

        details.map((detail) => {
          if (detail.raw_material_diameter) {
            setrawmaterialvariantname(detail.raw_material_name);
            setrawmaterialvariant(detail.raw_material_diameter.split(","));
          }
        });
      });
    userInput.rawmaterial_name = value;
    userInput.rawmaterialvariantname = rawmaterialvariantname;
  }
  function rawTypeChange(value) {
    axios
      .get(`${getServerUrl()}/master/rawmaterialbytype/${value}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        setrawmaterial(details);
      });
  }

  const rawOptions = rawmaterial.map((dataDrop) => {
    if (dataDrop.raw_material_id !== "") {
      return (
        <Option value={dataDrop.raw_material_id}>
          {dataDrop.raw_material_name}
        </Option>
      );
    }
  });
  /* Component Family */
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/master/componentfamily`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;

        setcomponentfamily(details);
      });
  }, []);

  /* Child Component */
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/master/childcomponent`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;

        setsubcomponent(details);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${getServerUrl()}/master/component`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;

        setcomponent(details);
      });
  }, []);

  /* Fill Drop Down */
  const dropDownOptions = process.map((dataDrop) => {
    return <Option value={dataDrop.process_id}>{dataDrop.process_name}</Option>;
  });

  const ComponentFamilyOptions = componentfamily.map((dataDrop) => {
    return <Option value={dataDrop.cf_id}>{dataDrop.component_family}</Option>;
  });

  const childComponentOptions = subcomponent.map((dataDrop) => {
    return (
      <Option value={dataDrop.component_id}>
        {dataDrop.component_no} - {dataDrop.component_name} -{" "}
        {dataDrop.component_type}
      </Option>
    );
  });

  const ComponentOptions = component.map((dataDrop) => {
    return (
      <Option value={dataDrop.component_id}>
        {dataDrop.component_no} - {dataDrop.component_name} -{" "}
        {dataDrop.component_type}
      </Option>
    );
  });

  const rawvariantOptions = rawmaterialvariant.map((dataDrop) => {
    return <Option value={dataDrop}>{dataDrop}</Option>;
  });

  /* Open Notifications */
  const openNotification = (type) => {
    notification[type]({
      message: "Component Added",
      description: "Component added successfully",
    });
  };
  const FailNotification = (type) => {
    notification[type]({
      message: "Error",
      description: "Please Fill out Required Fields",
    });
  };

  /* Submit Form */

  const handleSubmit = (e) => {
    userInput.process = list;
    userInput.component_family = componentfamilyvalue;
    userInput.component_type = componentType;
    userInput.childcomponent = ChildComponentList;
    console.log("formSubmit", userInput);
    e.preventDefault();
    if (userInput.component_type === "Child Component") {
      userInput.childcomponent = "";
      if (userInput.component_family !== "") {
        axios
          .post(`${getServerUrl()}/master/createcomponent`, userInput, {
            headers: { "x-auth-token": localStorage.getItem("token") },
          })
          .then((res) => {
            const data = res.data;
            if (data.success === 1) {
              setUserInput({
                component_family: "",
              });
              openNotification("success");
              window.location.reload();
            } else {
            }
          });
      } else {
        FailNotification("error");
      }
    } else {
      userInput.rawmaterial_name = "";
      userInput.raw_material_diameter = "";
      userInput.rawmaterial_length = "";
      userInput.rawmaterial_weight = "";
      if (
        userInput.component_family !== "" &&
        userInput.component_name !== ""
      ) {
        axios
          .post(`${getServerUrl()}/master/createcomponent`, userInput, {
            headers: { "x-auth-token": localStorage.getItem("token") },
          })
          .then((res) => {
            const data = res.data;
            if (data.success === 1) {
              setUserInput({
                component_family: "",
              });
              openNotification("success");
              window.location.reload();
            } else {
            }
          });
      } else {
        FailNotification("error");
      }
    }
  };

  /* Complete Action */

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Master Settings </Breadcrumb.Item>
        <Breadcrumb.Item>Components Settings</Breadcrumb.Item>
        <Breadcrumb.Item>Create New</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background">
        <h2 className="titles">
          CREATE NEW <span>COMPONENT</span>
        </h2>

        <Row>
          <Col span={16}>
            <h2>Component Name</h2>
            <label>Component Type</label>
            <br></br>
            <Select
              name="contractor_type"
              onChange={selecthandleChange}
              placeholder="Please Select Contractor Type"
              style={{ width: "250px" }}
            >
              <Option value="Parent Component">Parent Component</Option>
              <Option value="Child Component">Child Component</Option>
              <Option value="Kit Component">Kit Component</Option>
            </Select>
            <br></br>
            <label>Component Family</label>
            <br></br>
            <Select
              name="component_family"
              onChange={selectComponentFamilyChange}
              placeholder="Please Select Component"
              style={{ width: "250px" }}
            >
              {ComponentFamilyOptions}
            </Select>
            <br></br>
            <label>Component Name</label>
            <Input
              name="component_name"
              value={userInput.component_name}
              onChange={handleChange}
            />
            <label>Component No.</label>
            <Input
              name="component_number"
              value={userInput.component_number}
              onChange={handleChange}
            />
            <label>Component Description</label>
            <Input
              name="component_description"
              value={userInput.component_description}
              onChange={handleChange}
            />
            <label>Component Price</label>
            <Input
              name="component_price"
              value={userInput.component_price}
              onChange={handleChange}
            />
          </Col>
        </Row>
      </div>

      <div
        className={
          componentType === "Parent Component"
            ? "site-layout-background"
            : "site-layout-background display-none"
        }
      >
        <Row>
          <Col span={24}>
            <h2>Child Component(s)</h2>
            <Select
              name="sub_component"
              placeholder="Please Select Component"
              style={{ width: "250px" }}
              onChange={childComponentChange}
            >
              {childComponentOptions}
            </Select>
            <br></br>
            <label>Quantity</label>
            <Input
              name="child_component_quantity"
              value={userInput.child_component_quantity}
              onChange={handleChange}
            />

            <br></br>
            <Button
              type="primary"
              shape="round"
              size="large"
              onClick={insertChildComponent}
            >
              Add Child Component
            </Button>
            <br></br>
            <ul className="processOrder">
              {ChildComponentList.map((item) => (
                <li key={item.id}>
                  <div className="processBar">
                    <div className="content">{item.child_name}</div>
                    <div className="content">
                      {item.child_component_quantity}
                    </div>
                    <div>
                      <button onClick={() => deleteComponent(item.id)}>
                        X
                      </button>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      </div>

      <div
        className={
          componentType === "Kit Component"
            ? "site-layout-background"
            : "site-layout-background display-none"
        }
      >
        <Row>
          <Col span={24}>
            <h2>Child Component(s)</h2>
            <Select
              name="sub_component"
              placeholder="Please Select Component"
              style={{ width: "250px" }}
              onChange={childComponentChange}
            >
              {ComponentOptions}
            </Select>
            <br></br>
            <label>Quantity</label>
            <Input
              name="child_component_quantity"
              value={userInput.child_component_quantity}
              onChange={handleChange}
            />

            <br></br>
            <Button
              type="primary"
              shape="round"
              size="large"
              onClick={insertChildComponent}
            >
              Add Child Component
            </Button>
            <br></br>
            <ul className="processOrder">
              {ChildComponentList.map((item) => (
                <li key={item.id}>
                  <div className="processBar">
                    <div className="content">{item.child_name}</div>
                    <div className="content">
                      {item.child_component_quantity}
                    </div>
                    <div>
                      <button onClick={() => deleteComponent(item.id)}>
                        X
                      </button>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      </div>

      <div
        className={
          componentType === "Child Component"
            ? "site-layout-background"
            : "site-layout-background display-none"
        }
      >
        <Row>
          <Col span={24}>
            <h2>Raw Material Details</h2>
            <label>Select Raw Material Type</label>
            <br></br>
            <Select
              name="rawmaterial_type"
              onChange={rawTypeChange}
              placeholder="Please Select Rawmaterial Type"
              style={{ width: "250px" }}
            >
              {RawTypeDownOptions}
            </Select>
            <br></br>
            <label>Select Raw Material</label>
            <br></br>
            <Select
              name="rawmaterial_name"
              onChange={rawChange}
              placeholder="Please Select Rawmaterial"
              style={{ width: "250px" }}
            >
              {rawOptions}
            </Select>
            <br></br>
            <label>Diameter</label>
            <br></br>
            <Select
              name="rawmaterial_variant"
              onChange={rawvariantChange}
              placeholder="Please Select Diameter"
              style={{ width: "250px" }}
            >
              {rawvariantOptions}
            </Select>
            <br></br>
            <label>Component Length</label>
            <Input
              name="rawmaterial_length"
              value={userInput.rawmaterial_length}
              onChange={handleChange}
            />
            <label>Component Weight </label>
            <Input
              name="rawmaterial_weight"
              value={userInput.rawmaterial_weight}
              onChange={handleChange}
            />
          </Col>
        </Row>
      </div>
      <div className="site-layout-background">
        <Row>
          <Col span={16}>
            <h2>Component Process Flow</h2>
            <label>Add List of Processes</label>
            <br></br>
            <label>Process </label>
            <br></br>
            <Select
              name="process_name"
              onChange={processChange}
              placeholder="Please Select Process Name"
              style={{ width: "250px" }}
            >
              {dropDownOptions}
            </Select>
            <br></br>
            <label>Component Process Description</label>
            <Input
              name="process_desc"
              value={userInput.process_desc}
              onChange={handleChange}
            />

            <label>Operation No.</label>
            <Input
              name="operation_order"
              value={userInput.operation_order}
              onChange={handleChange}
            />
            <br></br>
            <br></br>
            <Button
              type="primary"
              shape="round"
              size="large"
              onClick={insertProcess}
            >
              Add Process
            </Button>
            <br></br>
            <ul className="processOrder">
              {list
                .sort((a, b) => a.order - b.order)
                .map((item) => (
                  <li key={item.id}>
                    <div className="processBar">
                      <div>
                        <button onClick={() => orderUp(item.id, item.order)}>
                          &uarr;
                        </button>
                      </div>
                      <div className="content">{item.processname}</div>
                      <div className="content">{item.processdesc}</div>
                      <div>
                        <button onClick={() => orderDown(item.id, item.order)}>
                          &darr;
                        </button>
                      </div>
                      <div>
                        <span
                          style={{
                            padding: "5px",
                            border: "1px solid #303030",
                            borderRadius: "3px",
                          }}
                        >
                          {item.order}
                        </span>
                      </div>
                      <div>
                        <button onClick={() => handleClick(item.id)}>X</button>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
            <br></br>
            <br></br>
            <br></br>
            <Button
              type="primary"
              shape="round"
              size="large"
              onClick={handleSubmit}
            >
              Add Component
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default NewComponent;

import React from "react";
import { NavLink } from "react-router-dom";
import {
  Table,
  Tag,
  Space,
  Breadcrumb,
  Select,
  DatePicker,
  Row,
  Col,
  Input,
  Button,
} from "antd";
const { Column, ColumnGroup } = Table;
const { Option } = Select;
const { RangePicker } = DatePicker;
class FinishedGoodTransferBO extends React.Component {
  state = {
    size: "large",
  };
  render() {
    function onChange(date, dateString) {
      console.log(date, dateString);
    }
    const { size } = this.state;
    const data = [
      {
        key: "1",
        vendorName: "AKS Enterprises",
        noComponents: "Rod",
        invoiceNo: "12345",
        invoiceDate: "11-09-20",
      },
      {
        key: "2",
        vendorName: "AKS Enterprises",
        noComponents: "Rod",
        invoiceNo: "12345",
        invoiceDate: "11-09-20",
      },
      {
        key: "3",
        vendorName: "AKS Enterprises",
        noComponents: "Rod",
        invoiceNo: "12345",
        invoiceDate: "11-09-20",
      },
    ];
    function onChange(value) {
      console.log(`selected ${value}`);
    }

    function onBlur() {
      console.log("blur");
    }

    function onFocus() {
      console.log("focus");
    }

    function onSearch(val) {
      console.log("search:", val);
    }
    return (
      <div>
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>Inventory</Breadcrumb.Item>
          <Breadcrumb.Item>Stock Transfer</Breadcrumb.Item>
          <Breadcrumb.Item>Finished Goods Transfer</Breadcrumb.Item>
        </Breadcrumb>
        <div className="site-layout-background">
          <h2 className="titles">
            STOCK TRANSFER : FINISHED GOODS TRANSFER (Normal Part Transfer)
          </h2>
          <Row>
            <Col span={16}>
              <h2>I. Select Component </h2>
              <label>Component Name:</label>
              <Input placeholder="Supplier Name" />
              <label>Customer Name:</label>
              <Input placeholder="Vendor (source)" />
              <label>Invoice No:</label>
              <Input placeholder="Ready Quantity" />
            </Col>
          </Row>
        </div>
        <div className="site-layout-background">
          <Row>
            <Col span={16}>
              <h2>II. Select Quantity </h2>
              <label>FG Quantity:</label>
              <Input placeholder="Transfer Quantity" />
              <label>Delivery Quantity:</label>
              <Input placeholder="Rejection Quantity" />
              <label>Narration</label>
              <Input placeholder="Vendor (destination)" />
              <Button shape="round" size={size}>
                MOVE TO READY FOR DELIVERY
              </Button>
            </Col>
          </Row>
        </div>
        <div className="site-layout-background">
          <Row>
            <Col span={16}>
              <br></br>
              <h2>III. Select Transport Details</h2>
              <label>Pickup Point:</label>
              <Input placeholder="Pickup Point" />
              <label>Pickup Date:</label>
              <Input placeholder="Pickup Date:" />
              <label>Drop Point</label>
              <Input placeholder="Drop Point" />
              <label>Delivery Date</label>
              <Input placeholder="Delivery Date" />
              <label>Transporter</label>
              <Input placeholder="Transporter" />
              <Button type="primary" shape="round" size={size}>
                CREATE DELIVERY
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default FinishedGoodTransferBO;

import React from "react";
import { Breadcrumb, Row, Col, Space, Card } from "antd";
import { BrowserRouter as Router, NavLink } from "react-router-dom";
function Vendors() {
  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Vendors</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background" style={{ padding: 24 }}>
        <h2 className="titles">
          VENDOR <span>MANAGEMENT</span>
        </h2>
        <p>Select Task</p>
        <Row>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/vendor-data">
                <span className="icons icon-vendors"></span>
                VENDOR TASKS
              </NavLink>
            </div>
          </Col>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/raw-material-by-stock">
                <span className="icons icon-raw-matrial"></span>
                VENDOR RM SCHEDULE
              </NavLink>
            </div>
          </Col>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/vendor-stock-statement">
                <span className="icons icon-inventory"></span>
                VENDOR STOCK STATEMENT
              </NavLink>
            </div>
          </Col>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/purchase-setting">
                <span className="icons icon-purchase-order"></span>
                PURCHASE ORDERS
              </NavLink>
            </div>
          </Col>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/assign-vehicle">
                <span className="icons icon-vendor-invoice"></span>
                VENDOR INVOICES
              </NavLink>
            </div>
          </Col>
        </Row>
      </div>
   
    </div>
  );
}

export default Vendors;

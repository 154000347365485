import React from "react";
import { Breadcrumb, Row, Col } from "antd";
import { NavLink } from "react-router-dom";
function Reports() {
  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Master Settings </Breadcrumb.Item>
        <Breadcrumb.Item>Reports</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <h2 className="titles">REPORTS</h2>
        <p>Select View</p>
        <Row>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/contractor-report">
                <span className="icons icon-vendors"></span>
                Contractor Report
              </NavLink>
            </div>
          </Col>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/transporter-report">
                <span className="icons icon-special-trip"></span>
                Transporter Report
              </NavLink>
            </div>
          </Col>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/driver-report">
                <span className="icons icon-transporter-data"></span>
                Driver Report
              </NavLink>
            </div>
          </Col>
        </Row>
        <br></br>
        <br></br>
      </div>
    </div>
  );
}

export default Reports;

import React, { useReducer, useState, useEffect } from "react";
import {
  Breadcrumb,
  Row,
  Col,
  Form,
  Input,
  Button,
  notification,
  Select,
} from "antd";
import { NavLink } from "react-router-dom";
import { getServerUrl } from "../../config";
import axios from "axios";
const { Option } = Select;
const EditDriver = (props) => {
  const { id } = props.match.params;
  const [form] = Form.useForm();
  const [checkedValues, setcheckedValues] = useState("");
  const [transport, settransport] = useState([""]);

  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      driver_name: "",
      driver_phone: "",
      t_id: "",
      id: "",
      transporter_name: "",
    }
  );

  useEffect(() => {
    axios
      .get(`${getServerUrl()}/master/driver/${id}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const details = res.data;
        //console.log(details.data.t_id);
        if (res.data.success === 1) {
          setUserInput({
            id: details.data.d_id,
            driver_name: details.data.driver_name,
            driver_phone: details.data.driver_phone,
            t_id: details.data.t_id,
            transporter_name: details.data.transporter_name,
          });
          setcheckedValues(details.data.t_id);
        } else {
          window.location.href = "/transport-list";
        }
      });
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;
    setUserInput({ [name]: newValue });
  };

  function selecthandleChange(value) {
    setcheckedValues(value);
    userInput.transporter_name = value;
  }
  const openNotification = (type) => {
    notification[type]({
      message: "Driver Updated",
      description: "Driver update successfully",
    });
  };
  const FailNotification = (type) => {
    notification[type]({
      message: "Error",
      description: "Please Fill out Required Fields",
    });
  };
  const onFinish = (values) => {
    //console.log(values);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (userInput.driver_name !== "" && userInput.driver_phone !== "") {
      userInput.t_id = checkedValues;
      axios
        .post(`${getServerUrl()}/master/updatedriver`, userInput, {
          headers: { "x-auth-token": localStorage.getItem("token") },
        })
        .then((res) => {
          const data = res.data;
          //console.log(data);
          if (data.success === 1) {
            openNotification("success");
          } else {
          }
        });
    } else {
      FailNotification("error");
    }
  };
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/master/transport`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        settransport(details);
      });
  }, []);

  const dropDownOptions = transport.map((dataDrop) => {
    return <Option value={dataDrop.t_id}>{dataDrop.transporter_name}</Option>;
  });
  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Master Settings </Breadcrumb.Item>
        <Breadcrumb.Item>Transport Settings</Breadcrumb.Item>
        <Breadcrumb.Item>Update Driver</Breadcrumb.Item>
      </Breadcrumb>
      <Form form={form} name="control-hooks" onFinish={onFinish}>
        <div className="site-layout-background">
          <h2 className="titles">
            UPDATE <span>DRIVER</span>
          </h2>
          <Row>
            <Col span={16}>
              <h2> Drivers</h2>
              <label>Driver Name</label>
              <input type="hidden" name="id" value={userInput.id} />
              <Input
                name="driver_name"
                value={userInput.driver_name}
                onChange={handleChange}
              />
              <label>Driver Phone</label>
              <Input
                name="driver_phone"
                value={userInput.driver_phone}
                onChange={handleChange}
              />
              <label>RM Type</label>
              <br></br>
              <Select
                name="raw_material_type"
                onChange={selecthandleChange}
                placeholder="Please Select Type"
                style={{ width: "250px" }}
                value={userInput.transporter_name}
              >
                {dropDownOptions}
              </Select>
              <br></br> <br></br>
              <Button
                type="primary"
                shape="round"
                htmlType="submit"
                size="large"
                onClick={handleSubmit}
              >
                Update
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};

export default EditDriver;

import React, { useEffect, useState, useReducer } from "react";
import {
  Breadcrumb,
  Row,
  Col,
  Form,
  Input,
  Button,
  notification,
  Select,
} from "antd";
import { NavLink } from "react-router-dom";
import { getServerUrl } from "../../config";
import axios from "axios";
const EditRawType = (props) => {
  const { Option } = Select;
  const { id } = props.match.params;
  const [form] = Form.useForm();
  const [TypeName, setTypeName] = useState("");
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      id: "",
      raw_material_type_name: "",
      raw_material_type_unit: "",
    }
  );
  function vendorNameChange(value) {
    userInput.raw_material_type_unit = value;
  }
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/master/rawtype/${id}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const details = res.data;
        if (res.data.success === 1) {
          setUserInput({
            id: details.data.rawtype_id,
            raw_material_type_name: details.data.raw_material_type_name,
            raw_material_type_unit: details.data.raw_material_type_unit,
          });
          setTypeName(details.data.raw_material_type_unit);
          console.log("", userInput);
        } else {
          window.location.href = "/rawmaterialtypelist";
        }
      });
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;
    setUserInput({ [name]: newValue });
  };
  const openNotification = (type) => {
    notification[type]({
      message: "Raw Material Type Updated",
      description: "Raw Material Type update successfully",
    });
  };
  const FailNotification = (type) => {
    notification[type]({
      message: "Error",
      description: "Please Fill out Required Fields",
    });
  };
  const onFinish = (values) => {
    console.log(values);
  };
  const onReset = () => {
    form.resetFields();
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (userInput.raw_material_type_name !== "") {
      axios
        .post(`${getServerUrl()}/master/updaterawtype`, userInput, {
          headers: { "x-auth-token": localStorage.getItem("token") },
        })
        .then((res) => {
          const data = res.data;
          //console.log(data);
          if (data.success === 1) {
            openNotification("success");
          } else {
          }
        });
    } else {
      FailNotification("error");
    }
  };
  const deleteSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${getServerUrl()}/master/deleterawtype`, userInput, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        window.location.href = "/rawmaterialtypelist";
      });
  };
  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Master Settings </Breadcrumb.Item>
        <Breadcrumb.Item>Raw Material Settings</Breadcrumb.Item>
        <Breadcrumb.Item>Edit Raw Material Type</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background">
        <h2 className="titles">
          EDIT <span>RAW MATERIAL TYPE</span>
        </h2>

        <Row>
          <Col span={16}>
            <h2>Raw Material Type</h2>
            <label>RM TYPE</label>
            <input type="hidden" name="id" value={userInput.id} />
            <Input
              name="raw_material_type_name"
              value={userInput.raw_material_type_name}
              onChange={handleChange}
              rules={[
                {
                  required: true,
                  message: "Please enter Raw Material Type Name!",
                },
              ]}
            />
            <br></br>
            <label>RM TYPE UNIT</label>
            <br></br>
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="RM TYPE UNIT"
              onChange={vendorNameChange}
              value={TypeName}
            >
              <Option value="Kgs">Kgs</Option>
              <Option value="Nos">Nos</Option>
            </Select>
            <br></br>
            <br></br>
            <Button
              type="primary"
              shape="round"
              size="large"
              onClick={handleSubmit}
            >
              Update
            </Button>
            &nbsp;
            <Button
              type="danger"
              shape="round"
              size="large"
              onClick={deleteSubmit}
            >
              Delete
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default EditRawType;

import React from "react";
import { Breadcrumb, Row, Col, Space, Card } from "antd";
import { BrowserRouter as Router, NavLink } from "react-router-dom";
function StockTransfer() {
  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Inventory</Breadcrumb.Item>
        <Breadcrumb.Item>Finished Good Management</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <h2 className="titles">
          FINISHED GOODS <span>MANAGEMENT</span>
        </h2>
        <p>Select type of transfer</p>
        <Row>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/move-stock">
                <span className="icons icon-in-between"></span>
                STOCK TO FINISHED GOODS
              </NavLink>
            </div>
          </Col>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/finished-goods">
                <span className="icons icon-finished-goods"></span>
                FINISHED GOODS TRANSFER
              </NavLink>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default StockTransfer;

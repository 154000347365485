import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { getServerUrl } from "../../config";
import axios from "axios";
import {
  Table,
  Space,
  Breadcrumb,
  Select,
  Input,
  Button,
  Row,
  Col,
} from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
const { Option } = Select;
class RMReceipts extends Component {
  constructor() {
    super();
  }
  state = {
    details: [],
    newdetails: [],
    data: [],
  };

  onChange = async (value) => {
    this.search(value);
  };
  componentDidMount() {
    const details = [];
    axios
      .get(`${getServerUrl()}/inventory/rmreceipts`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        this.setState({ details });
      });
  }
  componentDidUpdate() {
    console.log("New Data");
  }
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  render() {
    const data = this.state.details;
    const dataDropDown = this.state.details;
    const dropDownOptions = dataDropDown.map((dataDrop) => {
      return (
        <Option value={dataDrop.process_id}>{dataDrop.process_name}</Option>
      );
    });
    const columns = [
      {
        title: "Receipt No",
        dataIndex: "rmi_id",
        key: "rmi_id",

        ...this.getColumnSearchProps("rmi_id"),
      },
      {
        title: "Supplier Name",
        dataIndex: "vendor_name",
        key: "vendor_name",
        width: "30%",
        ...this.getColumnSearchProps("vendor_name"),
      },
      {
        title: "Invoice No",
        dataIndex: "invoice_no",
        key: "invoice_no",
        width: "20%",
        ...this.getColumnSearchProps("invoice_no"),
      },
      {
        title: "Invoice Date",
        dataIndex: "invoice_date",
        key: "invoice_date",
        width: "20%",
        ...this.getColumnSearchProps("invoice_date"),
      },
      {
        title: "Material",
        dataIndex: "raw_material_name",
        key: "raw_material_name",
        render: (text, record) => <div>{record.raw_material_name}</div>,
        ...this.getColumnSearchProps("raw_material_name"),
      },
      {
        title: "Material",
        dataIndex: "raw_material_name",
        key: "raw_material_name",
        render: (text, record) => (
          <div>
            {record.variant}{" "}
            <b>
              {record.quantity} {record.raw_material_type_unit}
            </b>
          </div>
        ),
      },

      {
        title: "Action",
        dataIndex: "rmi_id",
        key: "rmi_id",
        render: (text, record) => (
          <Space size="middle">
            <NavLink to={"/view-rm-receipt/" + record.rmi_id}>View</NavLink>
          </Space>
        ),
      },
    ];

    return (
      <div>
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>Inventory</Breadcrumb.Item>
          <Breadcrumb.Item>RM Receipt Note</Breadcrumb.Item>
          <Breadcrumb.Item>RM Receipt</Breadcrumb.Item>
        </Breadcrumb>
        <div
          className="site-layout-background"
          style={{ padding: 24, minHeight: 360 }}
        >
          <h2 className="titles">
            VIEW ALL RECEIPT <span>NOTES</span>
          </h2>
          <Row></Row>
          <Space></Space>
          <br />
          <Table
            scroll={{ x: 1300 }}
            columns={columns}
            dataSource={data}
          ></Table>
        </div>
      </div>
    );
  }
}

export default RMReceipts;

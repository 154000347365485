import React, { useReducer, useState, useEffect } from "react";
import {
  Breadcrumb,
  Row,
  Form,
  Input,
  Checkbox,
  Button,
  notification,
  Select,
  Col,
} from "antd";
import { NavLink } from "react-router-dom";
import { getServerUrl } from "../../config";
import axios from "axios";
const { Option } = Select;

const NewRawMaterial = () => {
  const [form] = Form.useForm();
  function onChange(date, dateString) {
    console.log(date, dateString);
  }
  const [TypeName, setTypeName] = useState("");

  const [process, setprocess] = useState([""]);
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      raw_material_name: "",
      raw_material_description: "",
      raw_material_diameter: "",
      raw_material_length: "",
      raw_material_type: "",
      raw_material_length: "",
      raw_material_constant_factor: "",
      raw_material_weight: "",
      raw_material_identifier_name: "",
    }
  );

  const handleChange = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;
    setUserInput({ [name]: newValue });
    // console.log("Datas", userInput);
  };

  function selecthandleChange(value) {
    setTypeName(value);
  }

  const openNotification = (type) => {
    notification[type]({
      message: "Raw Material Added",
      description: "Raw Material added successfully",
    });
  };
  const FailNotification = (type) => {
    notification[type]({
      message: "Error",
      description: "Please Fill out Required Fields",
    });
  };

  const onReset = () => {
    form.resetFields();
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    userInput.raw_material_type = TypeName;
    if (userInput.raw_material_diameter === "") {
      userInput.raw_material_diameter = userInput.raw_material_name;
    }
    //console.log(userInput);
    if (userInput.raw_material_name !== "") {
      axios
        .post(`${getServerUrl()}/master/createrawmaterial`, userInput, {
          headers: { "x-auth-token": localStorage.getItem("token") },
        })
        .then((res) => {
          const data = res.data;
          //console.log(data);
          if (data.success === 1) {
            setUserInput({
              raw_material_name: "",
              raw_material_description: "",
              raw_material_diameter: "",
              raw_material_length: "",
              raw_material_type: "",
              raw_material_length: "",
              raw_material_constant_factor: "",
              raw_material_weight: "",
              raw_material_identifier_name: "",
            });
            form.resetFields();
            openNotification("success");
          } else {
          }
        });
    } else {
      FailNotification("error");
    }
  };
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/master/rawtype`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        setprocess(details);
      });
  }, []);
  const dropDownOptions = process.map((dataDrop) => {
    return (
      <Option value={dataDrop.rawtype_id}>
        {dataDrop.raw_material_type_name}
      </Option>
    );
  });
  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Master Settings </Breadcrumb.Item>
        <Breadcrumb.Item>Raw Material Settings</Breadcrumb.Item>
        <Breadcrumb.Item>Create New Raw Material</Breadcrumb.Item>
      </Breadcrumb>
      <Form form={form}>
        <div className="site-layout-background">
          <h2 className="titles">
            CREATE NEW <span>RAW MATERIAL</span>
          </h2>

          <Row>
            <Col span={16}>
              <h2>I. Raw Material Name</h2>
              <label>RM Name</label>
              <Input
                name="raw_material_name"
                value={userInput.raw_material_name}
                onChange={handleChange}
              />
              <label>RM Description</label>
              <Input
                name="raw_material_description"
                value={userInput.raw_material_description}
                onChange={handleChange}
              />
            </Col>
          </Row>
        </div>
        <div className="site-layout-background">
          <Row>
            <Col span={16}>
              <h2>II. Raw Material Details</h2>
              <label>Diameter</label>
              <Input
                name="raw_material_diameter"
                value={userInput.raw_material_diameter}
                onChange={handleChange}
              />
              <label>Length</label>
              <Input
                name="raw_material_length"
                value={userInput.raw_material_length}
                onChange={handleChange}
              />
              <label>RM Type</label>
              <br></br>
              <Select
                name="raw_material_type"
                onChange={selecthandleChange}
                placeholder="Please Select Type"
                style={{ width: "250px" }}
              >
                {dropDownOptions}
              </Select>
              <br></br>
              <label>Constant Factor</label>
              <Input
                name="raw_material_constant_factor"
                value={userInput.raw_material_constant_factor}
                onChange={handleChange}
              />
              <label>Weight</label>
              <Input
                name="raw_material_weight"
                value={userInput.raw_material_weight}
                onChange={handleChange}
              />
              <label>Identifier Name</label>
              <Input
                name="raw_material_identifier_name"
                value={userInput.raw_material_identifier_name}
                onChange={handleChange}
              />
              <br></br>
              <br></br>
              <Button
                type="primary"
                shape="round"
                size="large"
                onClick={handleSubmit}
              >
                Add Raw Material
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};

export default NewRawMaterial;

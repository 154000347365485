import React from "react";

import {
  Table,
  Tag,
  Space,
  Breadcrumb,
  Select,
  DatePicker,
  Row,
  Col,
} from "antd";
const { Column, ColumnGroup } = Table;
const { Option } = Select;
const { RangePicker } = DatePicker;
class AssignVehicle extends React.Component {
  render() {
    const data = [
      {
        key: "1",
        date: "31-10-2020",
        pickupLocation: "AKS Enterprises Guindy",
        dropLocation: "SV Enterprises Ambattur",
        component: "IC201",
        readyQty: "1 ton | 100 units",
      },
      {
        key: "2",
        date: "31-10-2020",
        pickupLocation: "AKS Enterprises Guindy",
        dropLocation: "SV Enterprises Ambattur",
        component: "IC202",
        readyQty: "1 ton | 100 units",
      },
      {
        key: "3",
        date: "31-10-2020",
        pickupLocation: "AKS Enterprises Guindy",
        dropLocation: "SV Enterprises Ambattur",
        component: "IC203",
        readyQty: "1 ton | 100 units",
      },
    ];
    return (
      <div>
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>Logistics</Breadcrumb.Item>
          <Breadcrumb.Item>Assign Vehicle</Breadcrumb.Item>
        </Breadcrumb>
        <div className="site-layout-background">
          <Row>
            <Col xs={12} xl={4}>
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Pickup Location"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="AKS Enterprises">AKS Enterprises</Option>
                <Option value="SV Enterprises">SV Enterprises</Option>
                <Option value="ACM Pvt Ltd">ACM Pvt Ltd</Option>
              </Select>
            </Col>
            <Col xs={12} xl={4}>
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Drop Location"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="AKS Enterprises">AKS Enterprises</Option>
                <Option value="SV Enterprises">SV Enterprises</Option>
                <Option value="ACM Pvt Ltd">ACM Pvt Ltd</Option>
              </Select>
            </Col>
            <Col xs={24} xl={4}>
              <RangePicker />
            </Col>
          </Row>
          <Space></Space>
          <br />
          <Table dataSource={data}>
            <Column title="Date" dataIndex="date" key="date" />
            <Column
              title="Pickup Location"
              dataIndex="pickupLocation"
              key="pickupLocation"
            />
            <Column
              title="Drop Location"
              dataIndex="dropLocation"
              key="dropLocation"
            />
            <Column title="Component" dataIndex="component" key="component" />
            <Column
              title="Ready Quantity"
              dataIndex="readyQty"
              key="readyQty"
            />

            <Column
              title="Action"
              key="action"
              render={(text, record) => (
                <Space size="middle">
                  <a>Assign</a>
                </Space>
              )}
            />
          </Table>
        </div>
      </div>
    );
  }
}

export default AssignVehicle;

import React, { useReducer, useState } from "react";
import {
  Breadcrumb,
  Row,
  Col,
  Form,
  Input,
  Checkbox,
  Button,
  notification,
  Select,
} from "antd";
import { NavLink } from "react-router-dom";
import { getServerUrl } from "../../config";
import axios from "axios";
const { Option } = Select;
const NewTransport = () => {
  const [form] = Form.useForm();
  const [checkedValues, setcheckedValues] = useState("");
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      transporter_name: "",
      transporter_contact_name: "",
      transporter_contact_phone: "",

      billing_company_name: "",
      gst_number: "",
      pan_number: "",
      bank_account_number: "",
      bank_name: "",
      bank_ifsc_code: "",
      bank_branch_name: "",
    }
  );

  const handleChange = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;
    setUserInput({ [name]: newValue });
  };
  console.log(checkedValues);
  const openNotification = (type) => {
    notification[type]({
      message: "Transport Added",
      description: "Transport added successfully",
    });
  };
  const FailNotification = (type) => {
    notification[type]({
      message: "Error",
      description: "Please Fill out Required Fields",
    });
  };
  const onFinish = (values) => {
    userInput.logistics_type = checkedValues;
    //console.log(userInput);
    if (userInput.transporter_name !== "") {
      axios
        .post(`${getServerUrl()}/master/createtransport`, userInput, {
          headers: { "x-auth-token": localStorage.getItem("token") },
        })
        .then((res) => {
          const data = res.data;
          //console.log(data);
          if (data.success === 1) {
            setUserInput({
              transporter_name: "",
              transporter_contact_name: "",
              transporter_contact_phone: "",
              billing_company_name: "",
              gst_number: "",
              pan_number: "",
              bank_account_number: "",
              bank_name: "",
              bank_ifsc_code: "",
              bank_branch_name: "",
            });
            form.resetFields();
            openNotification("success");
          } else {
          }
        });
    } else {
      FailNotification("error");
    }
  };
  const onReset = () => {
    form.resetFields();
  };

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Master Settings </Breadcrumb.Item>
        <Breadcrumb.Item>Transport Settings</Breadcrumb.Item>
        <Breadcrumb.Item>Create New</Breadcrumb.Item>
      </Breadcrumb>
      <Form form={form} name="control-hooks" onFinish={onFinish}>
        <div className="site-layout-background">
          <h2 className="titles">
            ADD NEW <span>TRANSPORT</span>
          </h2>

          <Row>
            <Col span={16}>
              <h2>I. Transporter Name & Details</h2>
              <label>Transporter Name</label>
              <Form.Item
                name="transporter_name_label"
                rules={[
                  {
                    required: true,
                    message: "Please enter Transporter name!",
                  },
                ]}
              >
                <Input
                  name="transporter_name"
                  value={userInput.transporter_name}
                  onChange={handleChange}
                />
              </Form.Item>
              <label>Owner Name</label>
              <Form.Item
                name="transporter_contact_name_label"
                rules={[
                  {
                    required: true,
                    message: "Please enter Owner name!",
                  },
                ]}
              >
                <Input
                  name="transporter_contact_name"
                  value={userInput.transporter_contact_name}
                  onChange={handleChange}
                />
              </Form.Item>

              <label>Owner Phone</label>
              <Form.Item
                name="transporter_contact_phone_label"
                rules={[
                  {
                    required: true,
                    message: "Please enter Owner Phone!",
                  },
                ]}
              >
                <Input
                  name="transporter_contact_phone"
                  value={userInput.transporter_contact_phone}
                  onChange={handleChange}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="site-layout-background">
          <Row>
            <Col span={16}>
              <h2>II. Billing Details</h2>
              <label>Company Name</label>
              <Input
                name="billing_company_name"
                value={userInput.billing_company_name}
                onChange={handleChange}
              />
              <label>GST No.</label>
              <Input
                name="gst_number"
                value={userInput.gst_number}
                onChange={handleChange}
              />
              <label>PAN No.</label>
              <Input
                name="pan_number"
                value={userInput.pan_number}
                onChange={handleChange}
              />
              <label>Bank A/c No.</label>
              <Input
                name="bank_account_number"
                value={userInput.bank_account_number}
                onChange={handleChange}
              />
              <label>Bank Name</label>
              <Input
                name="bank_name"
                value={userInput.bank_name}
                onChange={handleChange}
              />
              <label>Bank IFSC</label>
              <Input
                name="bank_ifsc_code"
                value={userInput.bank_ifsc_code}
                onChange={handleChange}
              />
              <label>Bank Branch</label>
              <Input
                name="bank_branch_name"
                value={userInput.bank_branch_name}
                onChange={handleChange}
              />
              <br></br>
              <br></br>
              <Button
                type="primary"
                shape="round"
                htmlType="submit"
                size="large"
              >
                Add Transport
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};

export default NewTransport;

import React, { useReducer, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  Breadcrumb,
  Row,
  Form,
  Input,
  Checkbox,
  Button,
  notification,
  Select,
  Col,
  Modal,
  message,
} from "antd";
import { getServerUrl } from "../../config";
import { PlusCircleFilled, CheckCircleFilled } from "@ant-design/icons";
import axios from "axios";
const { Option } = Select;

const EditContractor = (props) => {
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const { id } = props.match.params;
  const [form] = Form.useForm();
  const [contractorType, setcontractorType] = useState("");
  const [processValues, setprocessValues] = useState("");
  const [rawValues, setrawValues] = useState("");
  const [selectValues, setselectValues] = useState("");
  const [rawSelectValues, setrawSelectValues] = useState("");
  const [rawtype, setrawtype] = useState([""]);
  const [process, setprocess] = useState([""]);
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      contractor_name: "",
      contractor_type: "",
      address: "",
      city: "",
      pin_code: "",
      google_location: "",
      contact_number: "",
      process_name: "",
      rawmaterialtype: "",
      billing_company_name: "",
      gst_number: "",
      pan_number: "",
      bank_account_number: "",
      bank_name: "",
      bank_ifsc_code: "",
      bank_branch_name: "",
    }
  );

  const handleChange = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;
    setUserInput({ [name]: newValue });
  };

  const openNotification = (type) => {
    notification[type]({
      message: "Contractor Updated",
      description: "Contractor update successfully",
    });
  };

  const FailNotification = (type) => {
    notification[type]({
      message: "Error",
      description: "Please Fill out Required Fields",
    });
  };

  useEffect(() => {
    axios
      .get(`${getServerUrl()}/master/contractor/${id}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const details = res.data;

        if (res.data.success === 1) {
          setUserInput({
            id: details.data.contractor_id,
            contractor_name: details.data.contractor_name,
            contractor_type: details.data.contractor_type,
            address: details.data.address,
            city: details.data.city,
            pin_code: details.data.pin_code,
            google_location: details.data.google_location,
            contact_number: details.data.contact_number,
            process_name: details.data.process_name,
            rawmaterialtype: details.data.rawmaterialtype,
            billing_company_name: details.data.billing_company_name,
            gst_number: details.data.gst_number,
            pan_number: details.data.pan_number,
            bank_account_number: details.data.bank_account_number,
            bank_name: details.data.bank_name,
            bank_ifsc_code: details.data.bank_ifsc_code,
            bank_branch_name: details.data.bank_branch_name,
          });
          setcontractorType(details.data.contractor_type);
          setprocessValues(details.data.process_name);
          setrawValues(details.data.rawmaterialtype);
          setselectValues(details.data.process_name);
          setrawSelectValues(details.data.rawmaterialtype);
        } else {
          window.location.href = "/contractor-list";
        }
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${getServerUrl()}/master/process`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        setprocess(details);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${getServerUrl()}/master/rawtype`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        setrawtype(details);
      });
  }, []);

  function selecthandleChange(value) {
    setcontractorType(value);
  }
  function handleChangeNew(checkedValues) {
    setprocessValues(checkedValues.toString());
    setselectValues(checkedValues);
  }
  function NewValue(checkedValues) {}

  function handleRawTypeChange(checkedValues) {
    setrawValues(checkedValues.toString());
    setrawSelectValues(checkedValues);
  }
  const dropDownOptions = process.map((dataDrop) => {
    return (
      <Checkbox value={dataDrop.process_id}>{dataDrop.process_name}</Checkbox>
    );
  });

  const RawTypeOptions = rawtype.map((dataDrop) => {
    return (
      <Checkbox value={dataDrop.rawtype_id}>
        {dataDrop.raw_material_type_name}
      </Checkbox>
    );
  });
  /* Process Dropdown End */

  /* Submit Form start */
  const handleSubmit = (e) => {
    e.preventDefault();
    userInput.contractor_type = contractorType;
    if (contractorType === "Raw Material Supplier") {
      userInput.process_name = "";
      userInput.rawmaterialtype = rawValues;
    } else {
      userInput.process_name = processValues;
      userInput.rawmaterialtype = "";
    }
    if (userInput.contractor_name !== "") {
      setIsConfirmModalVisible(true);
    } else {
      message.error("please fill out all required fields");
    }
  };

  const handleConfirmOk = () => {
    axios
      .post(`${getServerUrl()}/master/updatecontractor`, userInput, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const data = res.data;
        if (data.success === 1) {
          openNotification("success");
        } else {
        }
      });
    setIsConfirmModalVisible(false);
  };
  const handleConfirmCancel = () => {
    setIsConfirmModalVisible(false);
  };

  /* Submit Form end */
  const deleteSubmit = (e) => {
    setIsDeleteModalVisible(true);
  };
  const handleDeleteConfirm = () => {
    axios
      .post(`${getServerUrl()}/master/deletecontractor`, userInput, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        window.location.href = "/contractor-list";
      });
    setIsDeleteModalVisible(false);
  };
  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
  };

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Master Settings </Breadcrumb.Item>
        <Breadcrumb.Item>Contractor Settings</Breadcrumb.Item>
        <Breadcrumb.Item>Create New</Breadcrumb.Item>
      </Breadcrumb>
      <Form form={form}>
        <div className="site-layout-background">
          <h2 className="titles">
            CREATE NEW <span>CONTRACTOR</span>
          </h2>

          <Row>
            <Col span={16}>
              <h2>I. Contractor Details</h2>
              <label>Contractor Name</label>
              <input type="hidden" name="contractor__id" value={userInput.id} />
              <Input
                name="contractor_name"
                value={userInput.contractor_name}
                onChange={handleChange}
              />
              <label>Contractor Type</label>
              <br></br>
              <Select
                name="contractor_type"
                onChange={selecthandleChange}
                placeholder="Please Select Contractor Type"
                style={{ width: "250px" }}
                value={contractorType}
                disabled
              >
                <Option value="Contractor">Contractor</Option>
                <Option value="Sub Contractor">Sub Contractor</Option>
                <Option value="Raw Material Supplier">
                  Raw Material Supplier
                </Option>
              </Select>
            </Col>
          </Row>
        </div>
        <div className="site-layout-background">
          <Row>
            <Col span={16}>
              <h2>II. Address & Contact Details</h2>
              <label>Address</label>
              <Input
                name="address"
                value={userInput.address}
                onChange={handleChange}
              />
              <label>City</label>
              <Input
                name="city"
                value={userInput.city}
                onChange={handleChange}
              />
              <label>PIN Code</label>
              <Input
                name="pin_code"
                value={userInput.pin_code}
                onChange={handleChange}
              />
              <label>Google Location</label>
              <Input
                name="google_location"
                value={userInput.google_location}
                onChange={handleChange}
              />
              <label>Contact No.</label>
              <Input
                name="contact_number"
                value={userInput.contact_number}
                onChange={handleChange}
              />
            </Col>
          </Row>
        </div>
        <div
          className={
            contractorType === "Contractor" ||
            contractorType === "Sub Contractor"
              ? "site-layout-background"
              : "site-layout-background display-none"
          }
        >
          <Row>
            <Col span={16}>
              <h2>III. Process Undertaken</h2>
              <label>Add List of Processes</label>
              <br></br>
              <Checkbox.Group
                name="process_value"
                onChange={handleChangeNew}
                value={selectValues}
              >
                {dropDownOptions}
              </Checkbox.Group>
            </Col>
          </Row>
        </div>
        <div
          className={
            contractorType === "Raw Material Supplier"
              ? "site-layout-background"
              : "site-layout-background display-none"
          }
        >
          <Row>
            <Col span={16}>
              <h2>III. Raw Material Supply</h2>
              <label>Add List of Raw Material</label>
              <br></br>
              <Checkbox.Group
                name="process_value"
                onChange={handleRawTypeChange}
                value={rawSelectValues}
              >
                {RawTypeOptions}
              </Checkbox.Group>
            </Col>
          </Row>
        </div>
        <div className="site-layout-background">
          <Row>
            <Col span={16}>
              <h2>IV. Billing Details</h2>
              <label>Company Name</label>
              <Input
                name="billing_company_name"
                value={userInput.billing_company_name}
                onChange={handleChange}
              />
              <label>GST No.</label>
              <Input
                name="gst_number"
                value={userInput.gst_number}
                onChange={handleChange}
              />
              <label>PAN No.</label>
              <Input
                name="pan_number"
                value={userInput.pan_number}
                onChange={handleChange}
              />
              <label>Bank A/c No.</label>
              <Input
                name="bank_account_number"
                value={userInput.bank_account_number}
                onChange={handleChange}
              />
              <label>Bank Name</label>
              <Input
                name="bank_name"
                value={userInput.bank_name}
                onChange={handleChange}
              />
              <label>Bank IFSC</label>
              <Input
                name="bank_ifsc_code"
                value={userInput.bank_ifsc_code}
                onChange={handleChange}
              />
              <label>Bank Branch</label>
              <Input
                name="bank_branch_name"
                value={userInput.bank_branch_name}
                onChange={handleChange}
              />
              <br></br>
              <br></br>
              <Button
                type="primary"
                shape="round"
                size="large"
                onClick={handleSubmit}
              >
                Update
              </Button>
              &nbsp;
              <Button
                type="danger"
                shape="round"
                size="large"
                onClick={deleteSubmit}
              >
                Delete
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
      <Modal
        title="Please Confirm"
        visible={isConfirmModalVisible}
        onOk={handleConfirmOk}
        okText="Yes, Proceed"
        onCancel={handleConfirmCancel}
        cancelText="No, Go Back"
      >
        <Row>
          <Col span={24}>
            <p
              style={{
                color: "#303030",
                fontSize: "22px",
                textAlign: "center",
              }}
            >
              <CheckCircleFilled
                style={{ fontSize: "36px", color: "#bc0067" }}
              />
              <br></br>
              Are you sure want to proceed?
            </p>
          </Col>
        </Row>
      </Modal>
      <Modal
        title="Please Confirm"
        visible={isDeleteModalVisible}
        onOk={handleDeleteConfirm}
        okText="Yes, Proceed"
        onCancel={handleDeleteCancel}
        cancelText="No, Go Back"
      >
        <Row>
          <Col span={24}>
            <p
              style={{
                color: "#303030",
                fontSize: "22px",
                textAlign: "center",
              }}
            >
              <CheckCircleFilled
                style={{ fontSize: "36px", color: "#bc0067" }}
              />
              <br></br>
              Are you sure want to proceed?
            </p>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default EditContractor;

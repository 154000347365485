import React, { useReducer, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  Breadcrumb,
  Row,
  Form,
  Input,
  Checkbox,
  Button,
  notification,
  Select,
  Col,
} from "antd";
import { getServerUrl } from "../../config";
import axios from "axios";
const { Option } = Select;

const NewContractor = () => {
  const [form] = Form.useForm();
  function onChange(date, dateString) {
    console.log(date, dateString);
  }
  const [contractorType, setcontractorType] = useState("");
  const [checkedValues, setcheckedValues] = useState("");
  const [process, setprocess] = useState([""]);
  const [rawtype, setrawtype] = useState([""]);
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      contractor_name: "",
      contractor_type: "",
      address: "",
      city: "",
      pin_code: "",
      google_location: "",
      contact_number: "",
      process_name: "",
      rawmaterialtype: "",
      billing_company_name: "",
      gst_number: "",
      pan_number: "",
      bank_account_number: "",
      bank_name: "",
      bank_ifsc_code: "",
      bank_branch_name: "",
    }
  );

  const handleChange = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;
    setUserInput({ [name]: newValue });
    // console.log("Datas", userInput);
  };
  function handleChangeNew(checkedValues) {
    setcheckedValues(checkedValues.toString());
  }
  function handleRawTypeChange(checkedValues) {
    setcheckedValues(checkedValues.toString());
  }
  function selecthandleChange(value) {
    setcontractorType(value);
  }

  const openNotification = (type) => {
    notification[type]({
      message: "Contractor Added",
      description: "Contractor added successfully",
    });
  };
  const FailNotification = (type) => {
    notification[type]({
      message: "Error",
      description: "Please Fill out Required Fields",
    });
  };

  const onReset = () => {
    form.resetFields();
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    userInput.contractor_type = contractorType;

    if (contractorType === "Raw Material Supplier") {
      userInput.process_name = "";
      userInput.rawmaterialtype = checkedValues;
    } else {
      userInput.process_name = checkedValues;
      userInput.rawmaterialtype = "";
    }
    console.log(userInput);
    //console.log(userInput);
    if (userInput.contractor_name !== "") {
      axios
        .post(`${getServerUrl()}/master/createcontractor`, userInput, {
          headers: { "x-auth-token": localStorage.getItem("token") },
        })
        .then((res) => {
          const data = res.data;
          //console.log(data);
          if (data.success === 1) {
            setUserInput({
              contractor_name: "",
              contractor_type: "",
              address: "",
              city: "",
              pin_code: "",
              google_location: "",
              contact_number: "",
              process_name: "",
              rawmaterialtype: "",
              billing_company_name: "",
              gst_number: "",
              pan_number: "",
              bank_account_number: "",
              bank_name: "",
              bank_ifsc_code: "",
              bank_branch_name: "",
            });
            form.resetFields();
            openNotification("success");
          } else {
          }
        });
    } else {
      FailNotification("error");
    }
  };
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/master/process`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        setprocess(details);
      });
  }, []);
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/master/rawtype`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        setrawtype(details);
      });
  }, []);
  const dropDownOptions = process.map((dataDrop) => {
    return (
      <Checkbox value={dataDrop.process_id}>{dataDrop.process_name}</Checkbox>
    );
  });
  const RawTypeOptions = rawtype.map((dataDrop) => {
    return (
      <Checkbox value={dataDrop.rawtype_id}>
        {dataDrop.raw_material_type_name}
      </Checkbox>
    );
  });
  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Master Settings </Breadcrumb.Item>
        <Breadcrumb.Item>Contractor Settings</Breadcrumb.Item>
        <Breadcrumb.Item>Create New</Breadcrumb.Item>
      </Breadcrumb>
      <Form form={form}>
        <div className="site-layout-background">
          <h2 className="titles">
            CREATE NEW <span>CONTRACTOR</span>
          </h2>

          <Row>
            <Col span={16}>
              <h2>I. Contractor Details</h2>
              <label>Contractor Name</label>
              <Input
                name="contractor_name"
                value={userInput.contractor_name}
                onChange={handleChange}
              />
              <label>Contractor Type</label>
              <br></br>
              <Select
                name="contractor_type"
                onChange={selecthandleChange}
                placeholder="Please Select Contractor Type"
                style={{ width: "250px" }}
              >
                <Option value="Contractor">Contractor</Option>
                <Option value="Sub Contractor">Sub Contractor</Option>
                <Option value="Raw Material Supplier">
                  Raw Material Supplier
                </Option>
              </Select>
            </Col>
          </Row>
        </div>

        <div className="site-layout-background">
          <Row>
            <Col span={16}>
              <h2>II. Address & Contact Details</h2>
              <label>Address</label>
              <Input
                name="address"
                value={userInput.address}
                onChange={handleChange}
              />
              <label>City</label>
              <Input
                name="city"
                value={userInput.city}
                onChange={handleChange}
              />
              <label>PIN Code</label>
              <Input
                name="pin_code"
                value={userInput.pin_code}
                onChange={handleChange}
              />
              <label>Google Location</label>
              <Input
                name="google_location"
                value={userInput.google_location}
                onChange={handleChange}
              />
              <label>Contact No.</label>
              <Input
                name="contact_number"
                value={userInput.contact_number}
                onChange={handleChange}
              />
            </Col>
          </Row>
        </div>
        <div
          className={
            contractorType === "Contractor" ||
            contractorType === "Sub Contractor"
              ? "site-layout-background"
              : "site-layout-background display-none"
          }
        >
          <Row>
            <Col span={16}>
              <h2>III. Process Undertaken</h2>
              <label>Add List of Processes</label>
              <br></br>
              <Checkbox.Group onChange={handleChangeNew}>
                {dropDownOptions}
              </Checkbox.Group>
            </Col>
          </Row>
        </div>
        <div
          className={
            contractorType === "Raw Material Supplier"
              ? "site-layout-background"
              : "site-layout-background display-none"
          }
        >
          <Row>
            <Col span={16}>
              <h2>III. Raw Material Supply</h2>
              <label>Add List of Raw Material</label>
              <br></br>
              <Checkbox.Group
                name="process_value"
                onChange={handleRawTypeChange}
              >
                {RawTypeOptions}
              </Checkbox.Group>
            </Col>
          </Row>
        </div>
        <div className="site-layout-background">
          <Row>
            <Col span={16}>
              <h2>IV. Billing Details</h2>
              <label>Company Name</label>
              <Input
                name="billing_company_name"
                value={userInput.billing_company_name}
                onChange={handleChange}
              />
              <label>GST No.</label>
              <Input
                name="gst_number"
                value={userInput.gst_number}
                onChange={handleChange}
              />
              <label>PAN No.</label>
              <Input
                name="pan_number"
                value={userInput.pan_number}
                onChange={handleChange}
              />
              <label>Bank A/c No.</label>
              <Input
                name="bank_account_number"
                value={userInput.bank_account_number}
                onChange={handleChange}
              />
              <label>Bank Name</label>
              <Input
                name="bank_name"
                value={userInput.bank_name}
                onChange={handleChange}
              />
              <label>Bank IFSC</label>
              <Input
                name="bank_ifsc_code"
                value={userInput.bank_ifsc_code}
                onChange={handleChange}
              />
              <label>Bank Branch</label>
              <Input
                name="bank_branch_name"
                value={userInput.bank_branch_name}
                onChange={handleChange}
              />
              <br></br>
              <br></br>
              <Button
                type="primary"
                shape="round"
                size="large"
                onClick={handleSubmit}
              >
                Add Contractor
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};

export default NewContractor;

import React from "react";
import { NavLink } from "react-router-dom";
import {
  Table,
  Tag,
  Space,
  Breadcrumb,
  Select,
  DatePicker,
  Row,
  Col,
} from "antd";
const { Column, ColumnGroup } = Table;
const { Option } = Select;
const { RangePicker } = DatePicker;
function EndBit() {
  const data = [
    {
      stage: "1",
      process: "Cutting",
      vendor: "AKS",
      closingStock: "400 units",
      receivedStock: "200 units",
      readyStock: "100 units",
      dispatchQty: "100 units",
      dispatchStatus: "Vehicle Assigned",
    },
    {
      stage: "2",
      process: "Hobbing",
      vendor: "SV",
      closingStock: "400 units",
      receivedStock: "200 units",
      readyStock: "100 units",
      dispatchQty: "100 units",
      dispatchStatus: "No Request",
    },
    {
      stage: "3",
      process: "Welding",
      vendor: "NV",
      closingStock: "400 units",
      receivedStock: "200 units",
      readyStock: "100 units",
      dispatchQty: "100 units",
      dispatchStatus: "No Request",
    },
  ];
  function onChange(value) {
    console.log(`selected ${value}`);
  }

  function onBlur() {
    console.log("blur");
  }

  function onFocus() {
    console.log("focus");
  }

  function onSearch(val) {
    console.log("search:", val);
  }
  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Inventory</Breadcrumb.Item>
        <Breadcrumb.Item>Component Data</Breadcrumb.Item>
        <Breadcrumb.Item>End Bit</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background">
        <h2 className="titles">
          SCRAP DATA: <span>END-BIT DATA</span>
        </h2>
        <Row>
          <Col xs={24} xl={8}>
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Select Vendor"
              optionFilterProp="children"
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="AKS Enterprises">AKS Enterprises</Option>
              <Option value="SV Enterprises">SV Enterprises</Option>
              <Option value="ACM Pvt Ltd">ACM Pvt Ltd</Option>
            </Select>
          </Col>
          <Col xs={24} xl={8}>
            <RangePicker />
          </Col>
        </Row>
        <Space></Space>
        <br />
        <Row>
          <Col xs={12} xl={6}>
            <div className="DetailCard">
              <p>End-bit Qty.</p>
              <h3>2 tons</h3>
              <span>&nbsp;</span>
            </div>
          </Col>
          <Col xs={12} xl={6}>
            <div className="DetailCard">
              <p>AKS</p>
              <h3>0.5</h3>
              <span>Tons</span>
            </div>
          </Col>
          <Col xs={12} xl={6}>
            <div className="DetailCard">
              <p>SV</p>
              <h3>0.5</h3>
              <span>Tons</span>
            </div>
          </Col>
          <Col xs={12} xl={6}>
            <div className="DetailCard">
              <p>Delivered</p>
              <h3>0</h3>
              <span>Delivered to client</span>
            </div>
          </Col>
        </Row>
        <br />
        <Table dataSource={data}>
          <Column title="S. No." dataIndex="stage" key="stage" />
          <Column title="RM Type" dataIndex="process" key="process" />
          <Column title="Vendor" dataIndex="vendor" key="vendor" />
          <Column
            title="Received"
            dataIndex="closingStock"
            key="closingStock"
          />
          <Column
            title="Dispatched"
            dataIndex="receivedStock"
            key="receivedStock"
          />
          <Column title="End-Bit Qty" dataIndex="readyStock" key="readyStock" />
          <Column title="Status" dataIndex="dispatchQty" key="dispatchQty" />
          <Column
            title="Dispatch Status"
            dataIndex="dispatchStatus"
            key="dispatchStatus"
          />
        </Table>
      </div>
    </div>
  );
}

export default EndBit;

import React, { useState, useReducer, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { getServerUrl } from "../../config";
import axios from "axios";
import {
  Table,
  Space,
  Breadcrumb,
  Select,
  Input,
  Button,
  Row,
  notification,
  Form,
  Col,
  Tag,
  DatePicker,
  Result,
  message,
  Modal,
} from "antd";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { PlusCircleFilled, CheckCircleFilled } from "@ant-design/icons";
import { SearchOutlined } from "@ant-design/icons";
const { Option } = Select;

const FinishedGoods = (props) => {
  const [formtwo] = Form.useForm();
  const [details, setdetails] = useState("");
  const [newdetails, setnewdetails] = useState("");
  const [data, setdata] = useState("");
  const [searchInput, setsearchInput] = useState([""]);
  const [searchText, setsearchText] = useState([""]);
  const [searchedColumn, setsearchedColumn] = useState([""]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      cs_id: "",
      quantity: "",
      available: "",
      entered_quantity: "",
    }
  );
  const handleChange = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;
    setUserInput({ [name]: newValue });
  };
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/inventory/getfinishedgood/`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = res.data.data;
        const details = responsevalue;
        setdata(details);
      });
  }, []);

  const moveStock = (cs_id, quantity) => {
    setIsModalVisible(true);
    userInput.cs_id = cs_id;
    userInput.available = quantity;
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setsearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        //setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setsearchedColumn(dataIndex);
    setsearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setsearchText("");
  };

  const columns = [
    {
      title: "Componenent Family",
      dataIndex: "component_family",
      key: "component_family",
      ...getColumnSearchProps("component_family"),
    },
    {
      title: "Componenent Name",
      dataIndex: "component_name",
      key: "component_name",
      ...getColumnSearchProps("component_name"),
    },
    {
      title: "Componenent No",
      dataIndex: "component_no",
      key: "component_no",
      ...getColumnSearchProps("component_no"),
    },
    {
      title: "Avail Stock",
      dataIndex: "stock",
      key: "stock",
    },

    {
      title: "Action",
      dataIndex: "component_id",
      key: "component_id",
      fixed: "right",
      render: (text, record) => (
        <Space size="middle">
          <NavLink to={"/finished-goods-statement/" + record.component_id}>
            View in details
          </NavLink>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Inventory </Breadcrumb.Item>
        <Breadcrumb.Item>Finished Stock</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <h2 className="titles">
          FINISHED <span>STOCK</span>
        </h2>

        <Space></Space>
        <br />
        <Table scroll={{ x: 1300 }} columns={columns} dataSource={data}></Table>
      </div>
    </div>
  );
};

export default FinishedGoods;

import React, { useReducer, useState, useEffect } from "react";
import {
  Breadcrumb,
  Row,
  Col,
  Form,
  Input,
  Checkbox,
  Button,
  notification,
  Select,
} from "antd";
import { NavLink } from "react-router-dom";
import { getServerUrl } from "../../config";
import axios from "axios";
const { Option } = Select;
const EditVehicle = (props) => {
  const { id } = props.match.params;
  const [form] = Form.useForm();
  const [logischeckedValues, setlogischeckedValues] = useState("");
  const [checkedValues, setcheckedValues] = useState("");
  const [selectValues, setselectValues] = useState("");
  const [transport, settransport] = useState([""]);
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      logistics_type: "",
      vehicle_type: "",
      vehicle_number: "",
      load_capacity: "",
      t_id: "",
      id: "",
      transporter_name: "",
    }
  );

  useEffect(() => {
    axios
      .get(`${getServerUrl()}/master/vehicle/${id}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const details = res.data;
        //console.log(details.data.t_id);
        if (res.data.success === 1) {
          setUserInput({
            t_id: details.data.t_id,
            id: details.data.vehicle_id,
            logistics_type: details.data.logistics_type,
            vehicle_type: details.data.vehicle_type,
            vehicle_number: details.data.vehicle_number,
            transporter_name: details.data.transporter_name,
            load_capacity: details.data.load_capacity,
          });
          setcheckedValues(details.data.t_id);
          setlogischeckedValues(details.data.logistics_type);
          setselectValues(details.data.logistics_type.split(","));
        } else {
          window.location.href = "/transport-list";
        }
      });
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;
    setUserInput({ [name]: newValue });
  };

  function selecthandleChange(value) {
    setcheckedValues(value);
    userInput.transporter_name = value;
  }
  function handleChangeNew(checkedValues) {
    setlogischeckedValues(checkedValues.toString());
    setselectValues(checkedValues);
  }

  const openNotification = (type) => {
    notification[type]({
      message: "Vehicle Updated",
      description: "Driver added successfully",
    });
  };
  const FailNotification = (type) => {
    notification[type]({
      message: "Error",
      description: "Please Fill out Required Fields",
    });
  };
  const onhandleSubmit = () => {
    userInput.logistics_type = logischeckedValues;
    userInput.t_id = checkedValues;
    //console.log(userInput);
    if (userInput.vehicle_type !== "") {
      axios
        .post(`${getServerUrl()}/master/updatevehicle`, userInput, {
          headers: { "x-auth-token": localStorage.getItem("token") },
        })
        .then((res) => {
          const data = res.data;
          //console.log(data);
          if (data.success === 1) {
            openNotification("success");
          } else {
          }
        });
    } else {
      FailNotification("error");
    }
  };
  const onReset = () => {
    form.resetFields();
  };
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/master/transport`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        settransport(details);
      });
  }, []);
  const dropDownOptions = transport.map((dataDrop) => {
    return <Option value={dataDrop.t_id}>{dataDrop.transporter_name}</Option>;
  });

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Master Settings </Breadcrumb.Item>
        <Breadcrumb.Item>Transport Settings</Breadcrumb.Item>
        <Breadcrumb.Item>Create New Vechicle</Breadcrumb.Item>
      </Breadcrumb>

      <div className="site-layout-background">
        <h2 className="titles">
          ADD NEW <span>VEHICLE</span>
        </h2>
        <Row>
          <Col span={16}>
            <h2>Vehicle</h2>
            <label>Vehicle Type</label>
            <input type="hidden" name="id" value={userInput.id} />
            <Input
              name="vehicle_type"
              value={userInput.vehicle_type}
              onChange={handleChange}
            />
            <label>Vehicle Number</label>
            <Input
              name="vehicle_number"
              value={userInput.vehicle_number}
              onChange={handleChange}
            />
            <label>Transporter</label>
            <br></br>
            <Select
              name="transporter_name"
              onChange={selecthandleChange}
              placeholder="Please Select Type"
              style={{ width: "250px" }}
              value={userInput.transporter_name}
            >
              {dropDownOptions}
            </Select>
            <br></br>
            <label>Load Capacity</label>
            <Input
              name="load_capacity"
              value={userInput.load_capacity}
              onChange={handleChange}
            />
            <br></br>
            <label>Type of Logistics</label>
            <br></br>
            <Checkbox.Group
              name="logistics_type_label"
              onChange={handleChangeNew}
              value={selectValues}
            >
              <Checkbox value="Internal Vendor Logistics">
                Internal Vendor Logistics
              </Checkbox>
              <Checkbox value="External Vendor Logistics">
                External Vendor Logistics
              </Checkbox>
            </Checkbox.Group>
            <br></br>
            <br></br>
            <Button
              type="primary"
              shape="round"
              htmlType="submit"
              size="large"
              onClick={onhandleSubmit}
            >
              Update
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default EditVehicle;

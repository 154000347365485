import React, { useReducer, useState, useEffect } from "react";
import {
  Breadcrumb,
  Row,
  Col,
  Form,
  Input,
  Checkbox,
  Button,
  notification,
  Select,
} from "antd";
import { NavLink } from "react-router-dom";
import { getServerUrl } from "../../config";
import axios from "axios";
const { Option } = Select;
const EditTransport = (props) => {
  const { id } = props.match.params;
  const [form] = Form.useForm();
  const [selectValues, setselectValues] = useState("");
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      id: "",
      transporter_name: "",
      transporter_contact_name: "",
      transporter_contact_phone: "",
      billing_company_name: "",
      gst_number: "",
      pan_number: "",
      bank_account_number: "",
      bank_name: "",
      bank_ifsc_code: "",
      bank_branch_name: "",
    }
  );

  useEffect(() => {
    axios
      .get(`${getServerUrl()}/master/transport/${id}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const details = res.data;
        //console.log(details.data.t_id);
        if (res.data.success === 1) {
          setUserInput({
            id: details.data.t_id,
            transporter_name: details.data.transporter_name,
            transporter_contact_name: details.data.transporter_contact_name,
            transporter_contact_phone: details.data.transporter_contact_phone,
            billing_company_name: details.data.billing_company_name,
            gst_number: details.data.gst_number,
            pan_number: details.data.pan_number,
            bank_account_number: details.data.bank_account_number,
            bank_name: details.data.bank_name,
            bank_ifsc_code: details.data.bank_ifsc_code,
            bank_branch_name: details.data.bank_branch_name,
          });
        } else {
          window.location.href = "/transport-list";
        }
      });
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;
    setUserInput({ [name]: newValue });
  };

  function handleChangeNew(checkedValues) {
    setselectValues(checkedValues.toString());
  }
  const openNotification = (type) => {
    notification[type]({
      message: "Transport Updated",
      description: "Transport update successfully",
    });
  };
  const FailNotification = (type) => {
    notification[type]({
      message: "Error",
      description: "Please Fill out Required Fields",
    });
  };
  const onFinish = (values) => {
    //console.log(values);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      userInput.transporter_name !== "" &&
      userInput.transporter_contact_name !== ""
    ) {
      userInput.logistics_type = selectValues;
      axios
        .post(`${getServerUrl()}/master/updatetransport`, userInput, {
          headers: { "x-auth-token": localStorage.getItem("token") },
        })
        .then((res) => {
          const data = res.data;
          //console.log(data);
          if (data.success === 1) {
            openNotification("success");
          } else {
          }
        });
    } else {
      FailNotification("error");
    }
  };

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Master Settings </Breadcrumb.Item>
        <Breadcrumb.Item>Transport Settings</Breadcrumb.Item>
        <Breadcrumb.Item>Create New</Breadcrumb.Item>
      </Breadcrumb>
      <Form form={form} name="control-hooks" onFinish={onFinish}>
        <div className="site-layout-background">
          <h2 className="titles">
            UPDATE <span>TRANSPORT</span>
          </h2>

          <Row>
            <Col span={16}>
              <h2>I. Transporter Name & Details</h2>
              <label>Transporter Name</label>

              <input type="hidden" name="t_id" value={userInput.id} />
              <Input
                name="transporter_name"
                value={userInput.transporter_name}
                onChange={handleChange}
              />

              <label>Owner Name</label>

              <Input
                name="transporter_contact_name"
                value={userInput.transporter_contact_name}
                onChange={handleChange}
              />

              <label>Owner Phone</label>

              <Input
                name="transporter_contact_phone"
                value={userInput.transporter_contact_phone}
                onChange={handleChange}
              />
            </Col>
          </Row>
        </div>

        <div className="site-layout-background">
          <Row>
            <Col span={16}>
              <h2>II. Billing Details</h2>
              <label>Company Name</label>
              <Input
                name="billing_company_name"
                value={userInput.billing_company_name}
                onChange={handleChange}
              />
              <label>GST No.</label>
              <Input
                name="gst_number"
                value={userInput.gst_number}
                onChange={handleChange}
              />
              <label>PAN No.</label>
              <Input
                name="pan_number"
                value={userInput.pan_number}
                onChange={handleChange}
              />
              <label>Bank A/c No.</label>
              <Input
                name="bank_account_number"
                value={userInput.bank_account_number}
                onChange={handleChange}
              />
              <label>Bank Name</label>
              <Input
                name="bank_name"
                value={userInput.bank_name}
                onChange={handleChange}
              />
              <label>Bank IFSC</label>
              <Input
                name="bank_ifsc_code"
                value={userInput.bank_ifsc_code}
                onChange={handleChange}
              />
              <label>Bank Branch</label>
              <Input
                name="bank_branch_name"
                value={userInput.bank_branch_name}
                onChange={handleChange}
              />
              <br></br>
              <br></br>
              <Button
                type="primary"
                shape="round"
                onClick={handleSubmit}
                size="large"
              >
                Update Transport
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};

export default EditTransport;

import React, { useReducer, useState, useEffect } from "react";
import {
  Breadcrumb,
  Row,
  Col,
  Input,
  Button,
  Select,
  Checkbox,
  Form,
  Radio,
  notification,
  DatePicker,
} from "antd";
import { NavLink } from "react-router-dom";
import { getServerUrl } from "../../config";
import axios from "axios";
const { Option } = Select;
const contractorlist = [];
const initialChildComponentList = [];
const initialpurchaseList = [];
const InputStock = () => {
  const [form] = Form.useForm();
  const [contractorList, setcontractorList] = React.useState(contractorlist);
  const [CurrentStock, setCurrentStock] = useState("");
  const [ComponentList, setComponentList] = React.useState(
    initialChildComponentList
  );
  const [POenable, setPOenable] = useState([""]);
  const [component, setcomponent] = useState([""]);
  const [componentfamily, setcomponentfamily] = useState([""]);
  const [PricingType, setPricingType] = useState("");
  const [process, setprocess] = useState([]);
  const [value, setValue] = React.useState(1);
  const [ChildName, setChildName] = useState("");
  const [vendorPOlist, setvendorPOlist] = useState([""]);
  const [CPOlist, setCPOlist] = useState([""]);
  const [PurchaseList, setPurchaseList] = React.useState(initialpurchaseList);
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      vendorname: "",
      pricing_type: "",
      component: "",
      openstock: "",
      bulkprocess: "",
      indiviualprocess: "",
      rate: "",
      effectivefrom: "",
      effectiveto: "",
      orders: "",
      process_desc: "",
      rorder: "",
      process_id: "",
      componentName: "",
      process: "",
      no_of_process: "",
      starting_process_id: "",
      start_process_rorder: "",
      end_process_id: "",
      end_process_rorder: "",
    }
  );
  const handleChange = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;
    setUserInput({ [name]: newValue });
  };

  function handleChangeNew(value) {
    console.log(value);
    var newlist = vendorPOlist.filter(
      (List) => Number(List.purchase_item_id) === Number(value)
    );
    newlist.map((item) => {
      userInput.componentName = item.componentName;
      userInput.component = item.component;
      userInput.purchase_item_id = item.purchase_item_id;
      userInput.rate = item.rate;
      userInput.process = item.process;
      userInput.no_of_process = item.no_of_process;
      userInput.starting_process_id = item.starting_process_id;
      userInput.start_process_rorder = item.start_process_rorder;
      userInput.end_process_id = item.end_process_id;
      userInput.end_process_rorder = item.end_process_rorder;
      userInput.pricing_type = item.pricing_type;
    });

    /* axios
      .get(`${getServerUrl()}/master/componentprocessdetail/${value}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        userInput.process_desc =
          details[0].rorder +
          " | " +
          details[0].processname +
          " | " +
          details[0].processdesc;
        //console.log(details)
        userInput.process_id = details[0].processid;
        userInput.rorder = details[0].rorder;
      });*/
  }

  const onChange = (e) => {
    //console.log("radio checked", e.target.value);
    setValue(e.target.value);
    userInput.indiviualprocess = e.target.value;
  };

  function onFromDateChange(date, dateString) {
    userInput.stockdate = dateString;
  }
  function onToDateChange(date, dateString) {
    //console.log(date, dateString);
    date = dateString.split("-");
    var newDate = new Date(dateString);
    userInput.effectiveto = dateString;
  }
  const openNotification = (type) => {
    notification[type]({
      message: "Purchase Order Created",
      description: "Purchase Order Created successfully",
    });
  };
  const FailNotification = (type) => {
    notification[type]({
      message: "Error",
      description: "Please Fill out Required Fields",
    });
  };

  /* Contractor List */
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/master/contractor`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        setcontractorList(details);
      });
  }, []);

  /*  Component */
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/master/componentfamily`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;

        setcomponentfamily(details);
      });
  }, []);

  function vendorNameChange(value) {
    form.resetFields();
    userInput.vendorname = value;
    axios
      .get(`${getServerUrl()}/master/purchaseorderbyvendor/${value}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        if (details.length) {
          userInput.p_id = details[0].p_id;
          userInput.poc_id = details[0].poc_id;
        }
      });
    if (Number(userInput.p_id) > 0) {
      axios
        .get(
          `${getServerUrl()}/master/activepurchaseorderchanges/${
            userInput.p_id
          }`,
          {
            headers: { "x-auth-token": localStorage.getItem("token") },
          }
        )
        .then((res) => {
          let responsevalue = Object.values(res.data.data);
          const details = responsevalue;
          axios
            .get(
              `${getServerUrl()}/master/purchaseorderitem/${userInput.p_id}/${
                userInput.poc_id
              }`,
              {
                headers: { "x-auth-token": localStorage.getItem("token") },
              }
            )
            .then((res) => {
              let responsevalue = Object.values(res.data.data);
              const details = responsevalue;

              setvendorPOlist(details);
            });
        });
    }
  }
  /*  Component Process */

  function ComponentFamilyChange(value) {
    axios
      .get(`${getServerUrl()}/master/componentbyfamily/${value}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;

        setcomponent(details);
      });
  }
  function ComponentChange(value) {
    userInput.component = value;
    axios
      .get(
        `${getServerUrl()}/master/getpocompoent/${userInput.vendorname}/${
          userInput.component
        }`,
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      )
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        console.log("details", details);
        setprocess(details);
      });
    axios
      .get(`${getServerUrl()}/master/component/${userInput.component}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const details = res.data;
        setChildName(
          details.data.component_name + " " + details.data.component_no
        );
      });
    setCPOlist(
      vendorPOlist.filter((List) => Number(List.component) === Number(value))
    );
  }
  console.log(CPOlist);
  /*  Add Purchase List */
  /* Child Component Change */

  const insertPuchaseList = () => {
    setPurchaseList(
      PurchaseList.concat({
        id: Date.now(),
        component: userInput.component,
        process: userInput.process,
        process_id: userInput.process_id,
        rorder: userInput.rorder,
        stock: userInput.openstock,
        stockdate: userInput.stockdate,
        componentName: userInput.componentName,
        no_of_process: userInput.no_of_process,
        starting_process_id: userInput.starting_process_id,
        start_process_rorder: userInput.start_process_rorder,
        end_process_id: userInput.end_process_id,
        end_process_rorder: userInput.end_process_rorder,
        pricing_type: userInput.pricing_type,
        purchase_item_id: userInput.purchase_item_id,
        rate: userInput.rate,
      })
    );
  };
  console.log("PurchaseList", PurchaseList);
  const deletePurchase = (id) => {
    setPurchaseList(PurchaseList.filter((item) => item.id !== id));
  };

  const contractorOptions = contractorList.map((dataDrop) => {
    return (
      <Option value={dataDrop.contractor_id}>{dataDrop.contractor_name}</Option>
    );
  });

  const ComponentFamilyOptions = componentfamily.map((dataDrop) => {
    return <Option value={dataDrop.cf_id}>{dataDrop.component_family}</Option>;
  });

  const ComponentOptions = component.map((dataDrop) => {
    return (
      <Option value={dataDrop.component_id}>
        {dataDrop.component_name} - {dataDrop.component_no}
      </Option>
    );
  });
  const processOptions = CPOlist.map((dataDrop) => {
    return (
      <Option value={dataDrop.purchase_item_id}>
        {dataDrop.pricing_type} - {dataDrop.process}
      </Option>
    );
  });

  const handleSubmit = (e) => {
    userInput.orders = PurchaseList;
    console.log("formSubmit", userInput);
    e.preventDefault();
    axios
      .post(`${getServerUrl()}/inventory/inputstock`, userInput, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const data = res.data;
        ////console.log(data);
        if (data.success === 1) {
          openNotification("success");
          window.location.reload();
        } else {
        }
      });
  };
  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Master Settings </Breadcrumb.Item>
        <Breadcrumb.Item>Stock</Breadcrumb.Item>
        <Breadcrumb.Item>Physical Stock</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background">
        <h2 className="titles">
          PHYSICAL STOCK <span>UPDATE</span>
        </h2>
        <Row>
          <Col span={16}>
            <h2>I. Select Vendors</h2>
            <label>Vendor Name</label>
            <br></br>
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Select a Vendor"
              onChange={vendorNameChange}
            >
              {contractorOptions}
            </Select>
          </Col>
        </Row>
      </div>

      <div className="site-layout-background">
        <Row>
          <Col span={16}>
            <h2>II. Add Component</h2>
            <Form layout="vertical" form={form}>
              <Form.Item
                name="componentfamily"
                label="Select a Component Family"
              >
                <Select
                  onChange={ComponentFamilyChange}
                  placeholder="Select a Component Family"
                >
                  {ComponentFamilyOptions}
                </Select>
              </Form.Item>

              <Form.Item name="component" label="Select Component">
                <Select
                  onChange={ComponentChange}
                  placeholder="Select a Component"
                >
                  {ComponentOptions}
                </Select>
              </Form.Item>
              <p>
                {CurrentStock
                  ? "Current Component Stock at Vendor " + CurrentStock
                  : ""}
              </p>

              {POenable === "no"
                ? "Component does not have PO. please create PO before add stock."
                : ""}
              <Form.Item name="process" label="Select Process">
                <Select onChange={handleChangeNew}>
                  <option value="">Select Process</option>
                  {processOptions}
                </Select>
              </Form.Item>
              <Form.Item name="openstock" label="Physcial Stock">
                <Input
                  placeholder="Physcial Stock Value"
                  name="openstock"
                  value={userInput.openstock}
                  onChange={handleChange}
                />
              </Form.Item>
              <Form.Item name="stockdate" label="Stock Date">
                <DatePicker onChange={onFromDateChange} showNow />
              </Form.Item>
            </Form>
            <Button
              type="primary"
              shape="round"
              size="large"
              onClick={insertPuchaseList}
            >
              Add Stock
            </Button>
          </Col>
        </Row>
      </div>

      <div className="site-layout-background">
        <Row>
          <Col span={16}>
            <h2>Process</h2>
            <div
              className="ant-table-content"
              style={{ overflow: "auto hidden" }}
            >
              <table
                style={{
                  width: "100%",

                  tableLayout: "auto",
                }}
              >
                <tr>
                  <th>Component</th>
                  <th>Process</th>
                  <th>Stock</th>
                </tr>
                {PurchaseList.map((item) => (
                  <tr key={item.id}>
                    <td>{item.componentName}</td>
                    <td>{item.process}</td>
                    <td>{item.stock}</td>
                    <td>
                      <button onClick={() => deletePurchase(item.id)}>X</button>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
            <br></br>
            <Button
              type="primary"
              shape="round"
              size="large"
              onClick={handleSubmit}
            >
              Update Stock
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default InputStock;

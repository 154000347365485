import React, { useReducer, useState, useEffect } from "react";
import {
  Breadcrumb,
  Row,
  Form,
  Input,
  Checkbox,
  Button,
  notification,
  Select,
  Col,
} from "antd";
import { NavLink } from "react-router-dom";
import { getServerUrl } from "../../config";
import axios from "axios";
const { Option } = Select;

const EditRawMaterial = (props) => {
  const { id } = props.match.params;
  const [form] = Form.useForm();
  function onChange(date, dateString) {
    console.log(date, dateString);
  }
  const [TypeName, setTypeName] = useState("");
  const [Type, setType] = useState("");
  const [process, setprocess] = useState([""]);
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      id: "",
      raw_material_name: "",
      raw_material_description: "",
      raw_material_diameter: "",
      raw_material_length: "",
      raw_material_type_name: "",
      raw_material_length: "",
      raw_material_constant_factor: "",
      raw_material_weight: "",
      raw_material_identifier_name: "",
    }
  );
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/master/rawmaterial/${id}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const details = res.data;
        if (res.data.success === 1) {
          setUserInput({
            id: details.data.raw_material_id,
            raw_material_name: details.data.raw_material_name,
            raw_material_description: details.data.raw_material_description,
            raw_material_diameter: details.data.raw_material_diameter,
            raw_material_length: details.data.raw_material_length,
            raw_material_type_name: details.data.raw_material_type,
            raw_material_length: details.data.raw_material_length,
            raw_material_constant_factor:
              details.data.raw_material_constant_factor,
            raw_material_weight: details.data.raw_material_weight,
            raw_material_identifier_name:
              details.data.raw_material_identifier_name,
          });
          setType(details.data.raw_material_type);
          setTypeName(details.data.raw_material_type_name);
        } else {
          window.location.href = "/rawmaterial-list";
        }
      });
  }, []);
  console.log("TypeName", TypeName);
  const handleChange = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;
    setUserInput({ [name]: newValue });
    // console.log("Datas", userInput);
  };

  function selecthandleChange(value) {
    setType(value);
    setTypeName(value);
  }

  const openNotification = (type) => {
    notification[type]({
      message: "Raw Material Updated",
      description: "Raw Material Updated successfully",
    });
  };
  const FailNotification = (type) => {
    notification[type]({
      message: "Error",
      description: "Please Fill out Required Fields",
    });
  };

  const onReset = () => {
    form.resetFields();
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    userInput.raw_material_type = Type;
    //console.log(userInput);
    if (
      userInput.raw_material_name !== "" &&
      userInput.raw_material_diameter !== ""
    ) {
      axios
        .post(`${getServerUrl()}/master/updaterawmaterial`, userInput, {
          headers: { "x-auth-token": localStorage.getItem("token") },
        })
        .then((res) => {
          const data = res.data;
          //console.log(data);
          if (data.success === 1) {
            setUserInput({});
            form.resetFields();
            openNotification("success");
          } else {
          }
        });
    } else {
      FailNotification("error");
    }
  };
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/master/rawtype`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        setprocess(details);
      });
  }, []);
  const dropDownOptions = process.map((dataDrop) => {
    return (
      <Option value={dataDrop.rawtype_id}>
        {dataDrop.raw_material_type_name}
      </Option>
    );
  });
  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Master Settings </Breadcrumb.Item>
        <Breadcrumb.Item>Raw Material Settings</Breadcrumb.Item>
        <Breadcrumb.Item>Edit Raw Material</Breadcrumb.Item>
      </Breadcrumb>
      <Form form={form}>
        <div className="site-layout-background">
          <h2 className="titles">
            CREATE NEW <span>RAW MATERIAL</span>
          </h2>

          <Row>
            <Col span={16}>
              <h2>I. Raw Material Name</h2>
              <label>RM Name</label>
              <input type="hidden" name="id" value={userInput.id} />
              <Input
                name="raw_material_name"
                value={userInput.raw_material_name}
                onChange={handleChange}
              />
              <label>RM Description</label>
              <Input
                name="raw_material_description"
                value={userInput.raw_material_description}
                onChange={handleChange}
              />
            </Col>
          </Row>
        </div>
        <div className="site-layout-background">
          <Row>
            <Col span={16}>
              <h2>II. Raw Material Details</h2>
              <label>Diameter</label>
              <Input
                name="raw_material_diameter"
                value={userInput.raw_material_diameter}
                onChange={handleChange}
              />
              <label>Length</label>
              <Input
                name="raw_material_length"
                value={userInput.raw_material_length}
                onChange={handleChange}
              />
              <label>RM Type</label>
              <br></br>
              <Select
                name="raw_material_type_name"
                onChange={selecthandleChange}
                placeholder="Please Select Type"
                style={{ width: "250px" }}
                value={TypeName}
              >
                {dropDownOptions}
              </Select>
              <br></br>
              <label>Constant Factor</label>
              <Input
                name="raw_material_constant_factor"
                value={userInput.raw_material_constant_factor}
                onChange={handleChange}
              />
              <label>Weight</label>
              <Input
                name="raw_material_weight"
                value={userInput.raw_material_weight}
                onChange={handleChange}
              />
              <label>Identifier Name</label>
              <Input
                name="raw_material_identifier_name"
                value={userInput.raw_material_identifier_name}
                onChange={handleChange}
              />
              <br></br>
              <br></br>
              <Button
                type="primary"
                shape="round"
                size="large"
                onClick={handleSubmit}
              >
                Update Raw Material
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};

export default EditRawMaterial;

import React from "react";
import { Breadcrumb, Row, Col } from "antd";
import { NavLink } from "react-router-dom";
function RawMaterialSetting() {
  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Master Settings </Breadcrumb.Item>
        <Breadcrumb.Item>Raw Material Settings</Breadcrumb.Item>
      </Breadcrumb>

      <div className="site-layout-background">
        <h2 className="titles">
          RAW MATERIAL <span>SETTINGS</span>
        </h2>
        <p>Select View</p>
        <Row>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/rawmaterialtype">
                <span className="icons icon-raw-matrial"></span>
                Add Raw Material Type
              </NavLink>
            </div>
          </Col>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/rawmaterialtypelist">
                <span className="icons icon-view-all-receipt"></span>
                View / Manage Raw Material Types
              </NavLink>
            </div>
          </Col>
        </Row>
      </div>
      <div className="site-layout-background">
        <Row>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/new-rawmaterial">
                <span className="icons icon-new-receipt"></span>
                Add New Raw Material
              </NavLink>
            </div>
          </Col>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/rawmaterial-list">
                <span className="icons icon-view-all-receipt"></span>
                View / Manage Raw Materials
              </NavLink>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default RawMaterialSetting;

import React, { useReducer, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  Breadcrumb,
  Row,
  Form,
  Input,
  Checkbox,
  Button,
  notification,
  Select,
  Col,
  Modal,
  message,
} from "antd";
import { getServerUrl } from "../../config";
import { PlusCircleFilled, CheckCircleFilled } from "@ant-design/icons";
import axios from "axios";
const { Option } = Select;

const EditClient = (props) => {
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const { id } = props.match.params;
  const [form] = Form.useForm();

  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      client_name: "",
      client_nick_name: "",
      client_code: "",
      address: "",
      city: "",
      pin_code: "",
      billing_company_name: "",
      contact_number: "",
      gst_number: "",
      pan_number: "",
    }
  );

  const handleChange = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;
    setUserInput({ [name]: newValue });
  };

  const openNotification = (type) => {
    notification[type]({
      message: "Contractor Updated",
      description: "Contractor update successfully",
    });
  };

  const FailNotification = (type) => {
    notification[type]({
      message: "Error",
      description: "Please Fill out Required Fields",
    });
  };

  useEffect(() => {
    axios
      .get(`${getServerUrl()}/client/client/${id}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const details = res.data;
        console.log("details.data.client_name", details.data);
        if (res.data.success === 1) {
          setUserInput({
            client_id: details.data.client_id,
            client_name: details.data.client_name,
            client_nick_name: details.data.client_nick_name,
            client_code: details.data.client_code,
            address: details.data.address,
            city: details.data.city,
            pin_code: details.data.pin_code,
            billing_company_name: details.data.billing_company_name,
            contact_number: details.data.contact_number,
            gst_number: details.data.gst_number,
            pan_number: details.data.pan_number,
          });
        } else {
          window.location.href = "/client-list";
        }
      });
  }, []);

  function NewValue(checkedValues) {}

  /* Submit Form start */
  const handleSubmit = (e) => {
    e.preventDefault();
    if (userInput.client_name !== "") {
      setIsConfirmModalVisible(true);
    } else {
      message.error("please fill out all required fields");
    }
  };

  const handleConfirmOk = () => {
    axios
      .post(`${getServerUrl()}/client/updateclient`, userInput, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const data = res.data;
        if (data.success === 1) {
          openNotification("success");
        } else {
        }
      });
    setIsConfirmModalVisible(false);
  };
  const handleConfirmCancel = () => {
    setIsConfirmModalVisible(false);
  };

  /* Submit Form end */
  const deleteSubmit = (e) => {
    setIsDeleteModalVisible(true);
  };
  const handleDeleteConfirm = () => {
    axios
      .post(`${getServerUrl()}/client/deleteclient`, userInput, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        window.location.href = "/client-list";
      });
    setIsDeleteModalVisible(false);
  };
  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
  };

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Master Settings </Breadcrumb.Item>
        <Breadcrumb.Item>Client Settings</Breadcrumb.Item>
        <Breadcrumb.Item>Create New</Breadcrumb.Item>
      </Breadcrumb>
      <Form form={form}>
        <div className="site-layout-background">
          <h2 className="titles">
            UPDATE <span>CLIENT</span>
          </h2>

          <Row>
            <Col span={16}>
              <h2>I. Client Details</h2>
              <label>Client Name</label>
              <Input
                name="client_name"
                value={userInput.client_name}
                onChange={handleChange}
              />
              <label>Nick Name</label>
              <Input
                name="client_nick_name"
                value={userInput.client_nick_name}
                onChange={handleChange}
              />
              <label>Client Code</label>
              <Input
                name="client_code"
                value={userInput.client_code}
                onChange={handleChange}
              />
            </Col>
          </Row>
        </div>

        <div className="site-layout-background">
          <Row>
            <Col span={16}>
              <h2>II. Address & Contact Details</h2>
              <label>Address</label>
              <Input
                name="address"
                value={userInput.address}
                onChange={handleChange}
              />
              <label>City</label>
              <Input
                name="city"
                value={userInput.city}
                onChange={handleChange}
              />
              <label>PIN Code</label>
              <Input
                name="pin_code"
                value={userInput.pin_code}
                onChange={handleChange}
              />
              <label>Contact No.</label>
              <Input
                name="contact_number"
                value={userInput.contact_number}
                onChange={handleChange}
              />
            </Col>
          </Row>
        </div>

        <div className="site-layout-background">
          <Row>
            <Col span={16}>
              <h2>IV. Billing Details</h2>
              <label>Company Name</label>
              <Input
                name="billing_company_name"
                value={userInput.billing_company_name}
                onChange={handleChange}
              />
              <label>GST No.</label>
              <Input
                name="gst_number"
                value={userInput.gst_number}
                onChange={handleChange}
              />
              <label>PAN No.</label>
              <Input
                name="pan_number"
                value={userInput.pan_number}
                onChange={handleChange}
              />
              <br></br>
              <br></br>
              <Button
                type="primary"
                shape="round"
                size="large"
                onClick={handleSubmit}
              >
                Update Client
              </Button>
              &nbsp;
              <Button
                type="danger"
                shape="round"
                size="large"
                onClick={deleteSubmit}
              >
                Delete
              </Button>
            </Col>
          </Row>
        </div>
      </Form>

      <Modal
        title="Please Confirm"
        visible={isConfirmModalVisible}
        onOk={handleConfirmOk}
        okText="Yes, Proceed"
        onCancel={handleConfirmCancel}
        cancelText="No, Go Back"
      >
        <Row>
          <Col span={24}>
            <p
              style={{
                color: "#303030",
                fontSize: "22px",
                textAlign: "center",
              }}
            >
              <CheckCircleFilled
                style={{ fontSize: "36px", color: "#bc0067" }}
              />
              <br></br>
              Are you sure want to proceed?
            </p>
          </Col>
        </Row>
      </Modal>
      <Modal
        title="Please Confirm"
        visible={isDeleteModalVisible}
        onOk={handleDeleteConfirm}
        okText="Yes, Proceed"
        onCancel={handleDeleteCancel}
        cancelText="No, Go Back"
      >
        <Row>
          <Col span={24}>
            <p
              style={{
                color: "#303030",
                fontSize: "22px",
                textAlign: "center",
              }}
            >
              <CheckCircleFilled
                style={{ fontSize: "36px", color: "#bc0067" }}
              />
              <br></br>
              Are you sure want to proceed?
            </p>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default EditClient;

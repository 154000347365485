import React, { useEffect, useState } from "react";
import { Breadcrumb, Row, Col, Statistic, Card, Space } from "antd";
import { BrowserRouter as Router, NavLink } from "react-router-dom";
import { getServerUrl } from "../config";
import axios from "axios";
import CompletedTrips from "./completedtrip";
function Logistics() {
  const [unassigned, setunassigned] = useState(0);
  const [completed, setcompleted] = useState(0);
  const [ongoingtrip, setongoingtrip] = useState(0);
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/inventory/getunassigned/`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        if (res.data.data) {
          let responsevalue = Object.values(res.data.data);
          const details = responsevalue;
          setunassigned(details[0].unassignedtrip);
        }
      });
  }, []);
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/transcation/getongoingtrip/`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        if (res.data.data) {
          let responsevalue = Object.values(res.data.data);
          const details = responsevalue;
          setongoingtrip(details.length);
        }
      });
  }, []);
  useEffect(() => {
    axios
      .get(
        `${getServerUrl()}/transcation/getcompletedtrip/${localStorage.getItem(
          "fromDateUnix"
        )}/${localStorage.getItem("toDateUnix")}`,
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      )
      .then((res) => {
        if (res.data.data) {
          let responsevalue = Object.values(res.data.data);
          const details = responsevalue;
          setcompleted(details.length);
        }
      });
  }, []);
  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Logistics</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background" style={{ padding: 24 }}>
        <h2 className="titles">
          LOGISTICS <span>MANAGEMENT</span>
        </h2>
        <p>Logistics Summary</p>
        <Row>
          <Col xs={24} xl={4}>
            <NavLink to="/assign-transport">
              <Card>
                <Statistic
                  title="Unassigned Trips"
                  value={unassigned}
                  valueStyle={{ color: "#ff0000" }}
                />
              </Card>
            </NavLink>
          </Col>
          <Col xs={24} xl={4}>
            <NavLink to="/ongoingtrip">
              <Card>
                <Statistic
                  title="Ongoing Trips"
                  value={ongoingtrip}
                  valueStyle={{ color: "#1167b1" }}
                />
              </Card>
            </NavLink>
          </Col>
          <Col xs={24} xl={4}>
            <NavLink to="/completedtrip">
              <Card>
                <Statistic
                  title="Completed Trips"
                  value={completed}
                  valueStyle={{ color: "#76ba1b" }}
                />
              </Card>
            </NavLink>
          </Col>
        </Row>
      </div>
      <div className="site-layout-background" style={{ padding: 24 }}>
        <p>Vendor Logistics </p>
        <Row>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/assign-transport">
                <span className="icons icon-assign-vehicle"></span>
                ASSIGN VEHICLE
              </NavLink>
            </div>
          </Col>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/trip-sheet">
                <span className="icons icon-trip-sheet"></span>
                TRIPSHEETS
              </NavLink>
            </div>
          </Col>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/transport-setting">
                <span className="icons icon-transporter-data"></span>
                TRANSPORTER DATA
              </NavLink>
            </div>
          </Col>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/ongoingtrip">
                <span className="icons icon-assign-vehicle"></span>
                ONGOING TRIPS
              </NavLink>
            </div>
          </Col>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/completedtrip">
                <span className="icons icon-assign-vehicle"></span>
                COMPLETED TRIPS
              </NavLink>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Logistics;

import React, { Component, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { getServerUrl } from "../config";
import axios from "axios";
import { Table, Space, Breadcrumb, Input, Button } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
const StockStatement = (props) => {
  const { id } = props.match.params;
  const [details, setdetails] = useState("");
  const [newdetails, setnewdetails] = useState("");
  const [componentNo, setcomponentNo] = useState("");
  const [componentName, setcomponentName] = useState("");
  const [searchInput, setsearchInput] = useState([""]);
  const [searchText, setsearchText] = useState([""]);
  const [searchedColumn, setsearchedColumn] = useState([""]);
  const [data, setdata] = useState("");
  useEffect(() => {
    axios
      .get(
        `${getServerUrl()}/inventory/getcomponentstockstatementbycomponent/${id}`,
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      )
      .then((res) => {
        let responsevalue = res.data.data;
        const details = responsevalue;
        setcomponentNo(details[0].component_no);
        setcomponentName(details[0].component_name);
        setdetails(details);
      });
  }, []);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setsearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        //setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setsearchedColumn(dataIndex);
    setsearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setsearchText("");
  };

  const columns = [
    {
      title: "Vendor",
      dataIndex: "contractor_name",
      key: "contractor_name",
      ...getColumnSearchProps("contractor_name"),
    },
    {
      title: "Componenent No",
      dataIndex: "component_no",
      key: "component_no",
      ...getColumnSearchProps("component_no"),
    },
    {
      title: "Componenent Name",
      dataIndex: "component_name",
      key: "component_name",
      ...getColumnSearchProps("component_name"),
    },
    {
      title: "For Process",
      dataIndex: "process_name",
      key: "process_name",
      ...getColumnSearchProps("process_name"),
    },
    {
      title: "Inward",
      dataIndex: "instock",
      key: "instock",
    },
    {
      title: "Outward",
      dataIndex: "completedstock",
      key: "completedstock",
    },
    {
      title: "Closing Stock",
      dataIndex: "completedstock",
      key: "completedstock",
      render: (text, record) =>
        Number(record.instock) - Number(record.completedstock),
    },
    {
      title: "Action",
      dataIndex: "contractorid",
      key: "contractorid",
      render: (text, record) => (
        <Space size="middle">
          <NavLink
            to={
              "/stockdetail/" +
              record.contractor +
              "/" +
              record.component_id +
              "/" +
              record.process_name
            }
          >
            View in details
          </NavLink>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Inventory </Breadcrumb.Item>
        <Breadcrumb.Item>Stock Statement</Breadcrumb.Item>
        <Breadcrumb.Item>Component</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <h2 className="titles">
          STOCK STATEMENT <span>BY COMPONENT</span> - {componentNo} -{" "}
          {componentName}
        </h2>

        <Space></Space>
        <br />
        <Table
          scroll={{ x: 1300 }}
          columns={columns}
          dataSource={details}
        ></Table>
      </div>
    </div>
  );
};

export default StockStatement;

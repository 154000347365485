import React from "react";
import "./sidebar.css";
import { Layout, Menu, Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import logo from "../sarang.svg";

import { BrowserRouter as Router, NavLink } from "react-router-dom";
const { Sider } = Layout;

class SideBar extends React.Component {
  state = {
    collapsed: false,
  };

  onCollapse = (collapsed) => {
    console.log(collapsed);
    this.setState({ collapsed });
  };

  render() {
    const { collapsed } = this.state;
    const logout = () => {
      localStorage.clear();
      window.location.reload();
    };
    return (
      // collapsible collapsed={collapsed} onCollapse={this.onCollapse}
      <Sider>
        <div className="logo">
          <a href="/">
            <img src={logo}></img>
          </a>
        </div>
        <div className="accountLink">
          <Avatar size="90" icon={<UserOutlined />} />
          <p>
            <b>{localStorage.getItem("username")}</b>
            <br></br>
            {localStorage.getItem("Organization")}
          </p>
          <a href="#" className="logout" onClick={logout}>
            Logout
          </a>
        </div>

        <Menu
          mode="inline"
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub1"]}
          style={{ height: "100%", borderRight: 0 }}
        >
          <Menu.Item key="1">
            <span className="icons icon-home"></span>
            <NavLink to="/" exact>
              HOME
            </NavLink>
          </Menu.Item>
          <Menu.Item key="2">
            <span className="icons icon-inventory"></span>
            <NavLink to="/inventory/" exact>
              INVENTORY
            </NavLink>
          </Menu.Item>
          <Menu.Item key="3">
            <span className="icons icon-logistics"></span>
            <NavLink to="/logistics/" exact>
              LOGISTICS
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4">
            <span className="icons icon-vendors"></span>
            <NavLink to="/vendors/" exact>
              VENDORS
            </NavLink>
          </Menu.Item>
          <Menu.Item key="5">
            <span className="icons icon-setting"></span>
            <NavLink to="/setting/" exact>
              MASTER SETTINGS
            </NavLink>
          </Menu.Item>
        </Menu>
      </Sider>
    );
  }
}
export default SideBar;

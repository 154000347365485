import React from "react";
import "./sidebar.css";

import { NavLink } from "react-router-dom";

class MobileMenu extends React.Component {
  state = {
    collapsed: false,
  };

  onCollapse = (collapsed) => {
    console.log(collapsed);
    this.setState({ collapsed });
  };
  render() {
    const { collapsed } = this.state;
    return (
      // collapsible collapsed={collapsed} onCollapse={this.onCollapse}
      <div className="mobile-navbar">
        <ul>
          <li>
            <NavLink to="/inventory/" exact>
              <span className="icons icon-mobile-inventory"></span>
              <span className="menu-text">Inventory</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/logistics/" exact>
              <span className="icons icon-mobile-logistics"></span>
              <span className="menu-text">Logistics</span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/" exact>
              <span className="icons icon-mobile-home"></span>
              <span className="menu-text">Home</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/vendors/" exact>
              <span className="icons icon-mobile-vendor"></span>
              <span className="menu-text">Vendors</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/setting/" exact>
              <span className="icons icon-mobile-setting"></span>
              <span className="menu-text">Setting</span>
            </NavLink>
          </li>
        </ul>
      </div>
    );
  }
}
export default MobileMenu;

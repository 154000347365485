import React from "react";
import { Table, Tag, Space, Breadcrumb } from "antd";
import { NavLink } from "react-router-dom";
const { Column, ColumnGroup } = Table;
function VendorStockDetails() {
  const data = [];
  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Inventory</Breadcrumb.Item>
        <Breadcrumb.Item>Vendor Data</Breadcrumb.Item>
        <Breadcrumb.Item>AKS Enterprises</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <h2 className="titles">
          AKS ENTERPRISES INVENTORY <span>DATA</span>
        </h2>
        <h3>Component Name: IC201</h3>
        <Table dataSource={data}>
          <Column title="Date" dataIndex="process" key="process" />
          <Column title="Opening Stock" dataIndex="process" key="process" />
          <Column title="Received Stock" dataIndex="received" key="received" />

          <Column title="Ready Stock" dataIndex="readyStock" key="readyStock" />
          <Column
            title="Dispatch Stock"
            dataIndex="dispatchQty"
            key="dispatchQty"
          />
          <Column
            title="Dispatch Stock"
            dataIndex="dispatchQty"
            key="dispatchQty"
          />
          <Column
            title="Closing Stock"
            dataIndex="closingstock"
            key="closingstock"
          />
          <Column
            title="Activity"
            dataIndex="closingstock"
            key="closingstock"
          />
          <Column
            title="View "
            key="action"
            render={(text, record) => (
              <Space size="middle">
                <NavLink to="/vendor-stock-data">DC SLIP</NavLink>
              </Space>
            )}
          />
        </Table>
      </div>
    </div>
  );
}

export default VendorStockDetails;

import React, { useReducer, useState, useEffect } from "react";
import {
  Breadcrumb,
  Row,
  Col,
  Input,
  Button,
  Select,
  Checkbox,
  Form,
  Radio,
  notification,
  DatePicker,
  Table,
  Space,
  Modal,
  message,
} from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import { getServerUrl } from "../../config";
import axios from "axios";
const { Option } = Select;
const contractorlist = [];
const initialChildComponentList = [];
const initialpurchaseList = [];
const initialItemList = [];
const PhysicalStock = () => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [contractorList, setcontractorList] = React.useState(contractorlist);
  const [CurrentStock, setCurrentStock] = useState("");
  const [ItemList, setItemList] = useState(initialItemList);
  const [ComponentList, setComponentList] = React.useState(
    initialChildComponentList
  );
  const [POenable, setPOenable] = useState([""]);
  const [component, setcomponent] = useState([""]);
  const [componentfamily, setcomponentfamily] = useState([""]);
  const [processValues, setprocessValues] = useState("");
  const [bulkValues, setbulkValues] = React.useState("");
  const [processNameValues, setprocessNameValues] = useState("");
  const [PricingType, setPricingType] = useState("");
  const [process, setprocess] = useState([]);
  const [value, setValue] = React.useState(1);
  const [ChildName, setChildName] = useState("");
  const [checkedValues, setcheckedValues] = useState("");
  const [Bulkvalue, setBulkvalue] = useState("");
  const [PurchaseList, setPurchaseList] = React.useState(initialpurchaseList);
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      vendorname: "",
      pricing_type: "",
      component: "",
      openstock: "",
      bulkprocess: "",
      indiviualprocess: "",
      rate: "",
      effectivefrom: "",
      effectiveto: "",
      orders: "",
      process_desc: "",
      rorder: "",
      process_id: "",
      componentName: "",
      process: "",
      stockdate: "",
      no_of_process: "",
      starting_process_id: "",
      start_process_rorder: "",
      end_process_id: "",
      end_process_rorder: "",
    }
  );
  const handleChange = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;
    setUserInput({ [name]: newValue });
  };

  function handleChangeNew(value) {
    userInput.process = value;
    axios
      .get(`${getServerUrl()}/master/componentprocessdetail/${value}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        userInput.process_desc =
          details[0].rorder +
          " | " +
          details[0].processname +
          " | " +
          details[0].processdesc;
        //console.log(details)
        userInput.process_id = details[0].processid;
        userInput.rorder = details[0].rorder;
      });
  }
  function onFromDateChange(date, dateString) {
    userInput.stockdate = dateString;
  }
  const openNotification = (type) => {
    notification[type]({
      message: "Purchase Order Created",
      description: "Purchase Order Created successfully",
    });
  };
  const FailNotification = (type) => {
    notification[type]({
      message: "Error",
      description: "Please Fill out Required Fields",
    });
  };

  /* Contractor List */
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/master/contractor`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        setcontractorList(details);
      });
  }, []);

  /*  Component */
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/master/componentfamily`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        setcomponentfamily(details);
      });
  }, []);

  function vendorNameChange(value) {
    setPurchaseList(initialpurchaseList);
    form.resetFields();
    setItemList([""]);
    userInput.vendorname = value;
    axios
      .get(`${getServerUrl()}/master/purchaseorderbyvendor/${value}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        if (details.length) {
          userInput.p_id = details[0].p_id;
          userInput.poc_id = details[0].poc_id;
        } else {
          userInput.p_id = "0";
          userInput.poc_id = "0";
        }
        if (Number(userInput.p_id) > 0) {
          axios
            .get(
              `${getServerUrl()}/master/activepurchaseorderchanges/${
                userInput.p_id
              }`,
              {
                headers: { "x-auth-token": localStorage.getItem("token") },
              }
            )
            .then((res) => {
              let responsevalue = Object.values(res.data.data);
              const details = responsevalue;
              axios
                .get(
                  `${getServerUrl()}/master/purchaseorderitem/${
                    userInput.p_id
                  }/${userInput.poc_id}`,
                  {
                    headers: { "x-auth-token": localStorage.getItem("token") },
                  }
                )
                .then((res) => {
                  let responsevalue = Object.values(res.data.data);
                  const details = responsevalue;
                  setItemList(details);
                });
            });
        }
      });
  }
  /*  Component Process */
  /*  Modal Process */
  const showModal = (component, purchase_item_id) => {
    userInput.component = component;
    setIsModalVisible(true);
    axios
      .get(
        `${getServerUrl()}/master/getpocompoent/${userInput.vendorname}/${
          userInput.component
        }`,
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      )
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        setprocess(details);
      });
    axios
      .get(`${getServerUrl()}/master/component/${userInput.component}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const details = res.data;
        setChildName(
          details.data.component_name + " " + details.data.component_no
        );
      });
    axios
      .get(
        `${getServerUrl()}/inventory/getcomponentstockbyvendor/${
          userInput.component
        }/${userInput.vendorname}/${purchase_item_id}`,
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      )
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        setCurrentStock(details[0].total);
      });

    var newlist = ItemList.filter(
      (List) => Number(List.purchase_item_id) === Number(purchase_item_id)
    );
    newlist.map((item) => {
      userInput.componentName = item.componentName;
      userInput.component = item.component;
      userInput.purchase_item_id = item.purchase_item_id;
      userInput.rate = item.rate;
      userInput.process = item.process;
      userInput.no_of_process = item.no_of_process;
      userInput.starting_process_id = item.starting_process_id;
      userInput.start_process_rorder = item.start_process_rorder;
      userInput.end_process_id = item.end_process_id;
      userInput.end_process_rorder = item.end_process_rorder;
      userInput.pricing_type = item.pricing_type;
    });
  };

  function ComponentFamilyChange(value) {
    axios
      .get(`${getServerUrl()}/master/componentbyfamily/${value}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        setcomponent(details);
      });
  }

  /*  Add Purchase List */
  /* Child Component Change */

  const handleOk = () => {
    if (Number(userInput.openstock) > 0) {
      if (
        userInput.component !== "" &&
        userInput.openstock !== "" &&
        userInput.stockdate !== ""
      ) {
        if (
          !PurchaseList.some(
            (item) =>
              item.component === userInput.component &&
              item.rorder === userInput.rorder
          )
        ) {
          setPurchaseList(
            PurchaseList.concat({
              id: Date.now(),
              component: userInput.component,
              process: userInput.process,
              process_id: userInput.process_id,
              rorder: userInput.rorder,
              stock: userInput.openstock,
              stockdate: userInput.stockdate,
              componentName: userInput.componentName,
              no_of_process: userInput.no_of_process,
              starting_process_id: userInput.starting_process_id,
              start_process_rorder: userInput.start_process_rorder,
              end_process_id: userInput.end_process_id,
              end_process_rorder: userInput.end_process_rorder,
              pricing_type: userInput.pricing_type,
              purchase_item_id: userInput.purchase_item_id,
              rate: userInput.rate,
            })
          );
        } else {
          message.error("Stock for Component & Process already exist");
        }
      }
      setIsModalVisible(false);
      form.resetFields();
    } else {
      message.error("Opening stock entries should be positive values");
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    userInput.openstock = "";
  };

  const deletePurchase = (id) => {
    setPurchaseList(PurchaseList.filter((item) => item.id !== id));
  };

  const contractorOptions = contractorList.map((dataDrop) => {
    return (
      <Option value={dataDrop.contractor_id}>{dataDrop.contractor_name}</Option>
    );
  });

  const handleSubmit = (e) => {
    userInput.orders = PurchaseList;
    e.preventDefault();
    if (userInput.orders.length > 0) {
      setIsConfirmModalVisible(true);
    } else {
      message.error("Please enter atleast one quantity");
    }
  };
  const handleConfirmOk = () => {
    axios
      .post(`${getServerUrl()}/inventory/inputstock`, userInput, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const data = res.data;
        if (data.success === 1) {
          openNotification("success");
          window.location.reload();
        } else {
        }
      });
    setIsConfirmModalVisible(false);
  };
  const handleConfirmCancel = () => {
    setIsConfirmModalVisible(false);
  };
  const columns = [
    {
      title: "Component",
      dataIndex: "componentName",
      key: "componentName",
    },
    {
      title: "Process",
      dataIndex: "process",
      key: "process",
    },

    {
      title: "Enter Stock",
      dataIndex: "component",
      key: "component",
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="secondary"
            onClick={() => showModal(record.component, record.purchase_item_id)}
          >
            Enter Stock
          </Button>
        </Space>
      ),
    },
  ];
  function disabledDate(current) {
    // Can not select days before today and today
    return current && current.valueOf() > Date.now();
  }
  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Master Settings </Breadcrumb.Item>
        <Breadcrumb.Item>Stock</Breadcrumb.Item>
        <Breadcrumb.Item>Opening Stock</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background">
        <h2 className="titles">
          OPENING STOCK <span>UPDATE</span>
        </h2>
        <Row>
          <Col span={16}>
            <h2>I. Select Vendors</h2>
            <label>Vendor Name</label>
            <br></br>
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Select a Vendor"
              onChange={vendorNameChange}
            >
              <Option>Please Select</Option>
              {contractorOptions}
            </Select>
          </Col>
        </Row>
      </div>

      <div className="site-layout-background">
        <Row>
          <Col span={24}>
            {ItemList.length >= 2 ? (
              <Table columns={columns} dataSource={ItemList}></Table>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </div>

      <div className="site-layout-background">
        <Row>
          <Col span={16}>
            <h2>Process</h2>
            <div
              className="ant-table-content"
              style={{ overflow: "auto hidden" }}
            >
              <table
                style={{
                  width: "100%",

                  tableLayout: "auto",
                }}
              >
                <tr>
                  <th>Component</th>
                  <th>Process</th>
                  <th>Stock</th>
                  <th>Date</th>
                </tr>
                {PurchaseList.map((item) => (
                  <tr key={item.id}>
                    <td>{item.componentName}</td>
                    <td>{item.process}</td>
                    <td>{item.stock}</td>
                    <td>{item.stockdate}</td>
                    <td>
                      <button onClick={() => deletePurchase(item.id)}>X</button>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
            <br></br>
            <Button
              type="primary"
              shape="round"
              size="large"
              onClick={handleSubmit}
            >
              Update Stock
            </Button>
          </Col>
        </Row>
      </div>
      <Modal
        title="Physical Stock Input"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Row>
          <Col span={16}>
            <Form layout="vertical" form={form}>
              <p>
                {CurrentStock
                  ? "Current Component Stock at Vendor " + CurrentStock
                  : ""}
              </p>

              {POenable === "no"
                ? "Component does not have PO. please create PO before add stock."
                : ""}

              <Form.Item name="openstock" label="Physcial Stock">
                <Input
                  placeholder="Physcial Stock Value"
                  name="openstock"
                  value={userInput.openstock}
                  onChange={handleChange}
                />
              </Form.Item>
              {Number(userInput.openstock) < 0 ? (
                <p style={{ color: "red" }}>Negative Stock Not Allowed</p>
              ) : (
                ""
              )}
              <Form.Item name="stockdate" label="Stock Date">
                <DatePicker
                  onChange={onFromDateChange}
                  showNow
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>

      <Modal
        title="Please Confirm"
        visible={isConfirmModalVisible}
        onOk={handleConfirmOk}
        okText="Yes, Proceed"
        onCancel={handleConfirmCancel}
        cancelText="No, Go Back"
      >
        <Row>
          <Col span={24}>
            <p
              style={{
                color: "#303030",
                fontSize: "22px",
                textAlign: "center",
              }}
            >
              <CheckCircleFilled
                style={{ fontSize: "36px", color: "#bc0067" }}
              />
              <br></br>
              Are you sure want to proceed?
            </p>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default PhysicalStock;

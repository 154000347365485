import React, { Component, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { getServerUrl } from "../config";
import axios from "axios";
import { Table, Space, Breadcrumb, Input, Button, Select } from "antd";

import { CSVLink } from "react-csv";

const { Option } = Select;

const DriverReport = (props) => {
  const [details, setdetails] = useState("");
  const [newdetails, setnewdetails] = useState("");
  const [componentNo, setcomponentNo] = useState("");
  const [componentName, setcomponentName] = useState("");
  const [searchInput, setsearchInput] = useState([""]);
  const [searchText, setsearchText] = useState([""]);
  const [searchedColumn, setsearchedColumn] = useState([""]);
  const [driver, setdriver] = useState([""]);
  const [data, setdata] = useState("");
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/master/driver`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        if (res.data.data) {
          let responsevalue = Object.values(res.data.data);
          const details = responsevalue;
          setdriver(details);
        }
      });
  }, []);
  const driverDownOptions = driver.map((dataDrop) => {
    return <Option value={dataDrop.d_id}>{dataDrop.driver_name}</Option>;
  });

  const columns = [
    {
      title: "Trip Date",
      dataIndex: "transdate",
      key: "transdate",
    },
    { title: "Trip", dataIndex: "tripid", key: "tripid" },
    {
      title: "DC No.",
      dataIndex: "transid",
      key: "transid",
    },
    {
      title: "Vendor DC",
      dataIndex: "vendor_dc",
      key: "vendor_dc",
    },
    {
      title: "Source ",
      dataIndex: "source_name",
      key: "source_name",
    },
    {
      title: "Destination",
      dataIndex: "destination_name",
      key: "destination_name",
    },
    {
      title: "Component",
      dataIndex: "component_no",
      render: (text, record) =>
        record.component_no + "\n" + record.component_name,
    },
    {
      title: "Process Name",
      dataIndex: "processName",
      key: "processName",
    },
    {
      title: "Source Confirm Quantity",
      dataIndex: "source_confirm_quantity",
      key: "source_confirm_quantity",
    },
    {
      title: "Destination Confirm Quantity",
      dataIndex: "destination_confirm_quantity",
      key: "destination_confirm_quantity",
    },
    {
      title: "Total. Weight",
      dataIndex: "rawmaterial_weight",
      key: "rawmaterial_weight",
      render: (text, record) => (
        <Space size="middle">
          {record.destination_confirm_quantity
            ? (
                Number(record.destination_confirm_quantity) *
                Number(record.rawmaterial_weight)
              ).toFixed(2)
            : ""}
        </Space>
      ),
    },
  ];

  function driverChange(value) {
    if (localStorage.getItem("fromDateUnix")) {
      axios
        .get(
          `${getServerUrl()}/transcation/getcompletedtripbydriver/${value}/${localStorage.getItem(
            "fromDateUnix"
          )}/${localStorage.getItem("toDateUnix")}`,
          {
            headers: { "x-auth-token": localStorage.getItem("token") },
          }
        )
        .then((res) => {
          let responsevalue = res.data.data;
          const details = responsevalue;
          setdetails(details);
        });
    }
  }
  var s = new Date(
    localStorage.getItem("fromDateUnix") * 1000
  ).toLocaleDateString("en-US");
  var e = new Date(
    localStorage.getItem("toDateUnix") * 1000
  ).toLocaleDateString("en-US");

  const TableHeaders = [
    { label: "Trip Date", key: "transdate" },
    { label: "Sarang DC", key: "transid" },
    { label: "DC No.", key: "transid" },
    { label: "Vendor DC", key: "vendor_dc" },
    { label: "Source", key: "source_name" },
    { label: "Destination", key: "destination_name" },
    { label: "Component No & Name", key: "componentName" },
    { label: "Process", key: "processName" },
    { label: "Quantity", key: "destination_confirm_quantity" },
    { label: "Weight", key: "tripWeight" },
  ];

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Report </Breadcrumb.Item>
        <Breadcrumb.Item>Driver Report</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <h2 className="titles">Driver Report</h2>
        <br></br>
        <Select
          onChange={driverChange}
          placeholder="Please Select Type"
          style={{ width: "250px" }}
        >
          {driverDownOptions}
        </Select>
        <br></br> <br></br>
        <Space></Space>
        <br />
        {details.length > 0 ? "Driver Name : " + details[0].driver_name : ""}
        <br />
        <br />
        {details.length > 0 ? (
          <Button type="primary" shape="round" size="large">
            <CSVLink
              filename={(
                "Driver Report " +
                details[0].driver_name +
                "-" +
                s +
                "-" +
                e
              ).replaceAll(".", "")}
              data={details}
              headers={TableHeaders}
            >
              Download Data
            </CSVLink>
          </Button>
        ) : (
          ""
        )}
        {/* {details.length > 0 ? (
          <ExcelFile
            filename={
              "Driver Report " + details[0].driver_name + "-" + s + "-" + e
            }
            element={
              <Button type="primary" shape="round" size="large">
                Download Data
              </Button>
            }
          >
            <ExcelSheet
              data={details}
              name={
                "Driver Report " + details[0].driver_name + "-" + s + "-" + e
              }
            >
              <ExcelColumn label="Trip Date" value="transdate" />
              <ExcelColumn label="Trip ID" value="tripid" />
              <ExcelColumn label="DC No." value="transid" />
              <ExcelColumn label="Vendor DC" value="vendor_dc" />
              <ExcelColumn label="Source" value="source_name" />
              <ExcelColumn label="Destination" value="destination_name" />
              <ExcelColumn label="Component ID" value="component_no" />
              <ExcelColumn label="Component" value="component_name" />
              <ExcelColumn label="Process" value="processName" />
              <ExcelColumn
                label="Quantity"
                value="destination_confirm_quantity"
              />
              <ExcelColumn
                label="Weight"
                value={(col) =>
                  Number(col.destination_confirm_quantity) *
                  Number(col.rawmaterial_weight)
                }
              />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          ""
        )} */}
        <br></br>
        <br />
        <Table
          scroll={{ x: 1300 }}
          columns={columns}
          dataSource={details}
        ></Table>
      </div>
    </div>
  );
};

export default DriverReport;

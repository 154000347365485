import React, { useReducer, useState, useEffect } from "react";
import {
  Breadcrumb,
  Row,
  Col,
  Form,
  Input,
  Checkbox,
  Button,
  notification,
  Select,
} from "antd";
import { NavLink } from "react-router-dom";
import { getServerUrl } from "../config";
import axios from "axios";
const { Option } = Select;
const AddUser = () => {
  const [form] = Form.useForm();
  const [checkedValues, setcheckedValues] = useState("");
  const [transport, settransport] = useState([""]);
  const [contractor, setcontractor] = useState([""]);
  const [driver, setdriver] = useState([""]);
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      first_name: "",
      last_name: "",
      usertype: "",
      email: "",
      number: "",
      password: "",
      linkto: "",
      linkname: "",
    }
  );

  const handleChange = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;
    setUserInput({ [name]: newValue });
  };

  function selecthandleChange(value) {
    setcheckedValues(value);
    userInput.usertype = value;
  }
  function selectlinktohandleChange(value) {
    userInput.linkto = value;

    if (userInput.usertype === "driver") {
      axios
        .get(`${getServerUrl()}/master/driver/${value}`, {
          headers: { "x-auth-token": localStorage.getItem("token") },
        })
        .then((res) => {
          const details = res.data;
          if (details.data) {
            userInput.linkname = details.data.transporter_name;
          }
        });
    } else if (userInput.usertype === "contractor") {
      axios
        .get(`${getServerUrl()}/master/contractor/${value}`, {
          headers: { "x-auth-token": localStorage.getItem("token") },
        })
        .then((res) => {
          const details = res.data;
          if (details.data) {
            userInput.linkname = details.data.contractor_name;
          }
        });
    }
  }
  const openNotification = (type) => {
    notification[type]({
      message: "User Added",
      description: "User added successfully",
    });
  };
  const FailNotification = (type) => {
    notification[type]({
      message: "Error",
      description: "Please Fill out Required Fields",
    });
  };
  console.log(userInput);
  const onFinish = (values) => {
    userInput.t_id = checkedValues;

    if (userInput.first_name !== "") {
      axios
        .post(`${getServerUrl()}/`, userInput, {
          headers: { "x-auth-token": localStorage.getItem("token") },
        })
        .then((res) => {
          const data = res.data;
          //console.log(data);
          if (data.success === 1) {
            form.resetFields();
            openNotification("success");
            window.location.reload();
          } else {
          }
        });
    } else {
      FailNotification("error");
    }
  };
  const onReset = () => {
    form.resetFields();
  };
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/master/driver`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        if (res.data.data) {
          let responsevalue = Object.values(res.data.data);
          const details = responsevalue;
          setdriver(details);
        }
      });
  }, []);
  const dropDownOptions = driver.map((dataDrop) => {
    return <Option value={dataDrop.d_id}>{dataDrop.driver_name}</Option>;
  });
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/master/contractor`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        if (res.data.data) {
          let responsevalue = Object.values(res.data.data);
          const details = responsevalue;
          setcontractor(details);
        }
      });
  }, []);
  const ContractordropDownOptions = contractor.map((dataDrop) => {
    return (
      <Option value={dataDrop.contractor_id}>{dataDrop.contractor_name}</Option>
    );
  });
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/master/transport`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        if (res.data.data) {
          let responsevalue = Object.values(res.data.data);
          const details = responsevalue;
          settransport(details);
        }
      });
  }, []);
  const transporterdropDownOptions = transport.map((dataDrop) => {
    return <Option value={dataDrop.t_id}>{dataDrop.transporter_name}</Option>;
  });
  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Master Settings </Breadcrumb.Item>
        <Breadcrumb.Item>Create User</Breadcrumb.Item>
      </Breadcrumb>
      <Form form={form} name="control-hooks" onFinish={onFinish}>
        <div className="site-layout-background">
          <h2 className="titles">
            ADD NEW <span>USER</span>
          </h2>
          <Row>
            <Col span={16}>
              <h2>Users</h2>
              <label>First Name</label>
              <Form.Item
                name="first_name"
                rules={[
                  {
                    required: true,
                    message: "Please enter firstname!",
                  },
                ]}
              >
                <Input
                  name="first_name"
                  value={userInput.first_name}
                  onChange={handleChange}
                />
              </Form.Item>
              <label>Last Name</label>
              <Form.Item
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Please enter lastName!",
                  },
                ]}
              >
                <Input
                  name="last_name"
                  value={userInput.last_name}
                  onChange={handleChange}
                />
              </Form.Item>
              <label>Email</label>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter lastName!",
                  },
                ]}
              >
                <Input
                  name="email"
                  value={userInput.email}
                  onChange={handleChange}
                />
              </Form.Item>
              <label>Phone </label>
              <span>(Only for record purpose. NOT for notification)</span>
              <Form.Item
                name="number"
                rules={[
                  {
                    required: true,
                    message: "Please enter lastName!",
                  },
                ]}
              >
                <Input
                  name="number"
                  value={userInput.number}
                  onChange={handleChange}
                />
              </Form.Item>
              <label>Password</label>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please enter Password!",
                  },
                ]}
              >
                <Input
                  name="password"
                  value={userInput.password}
                  onChange={handleChange}
                />
              </Form.Item>
              <label>User Type</label>
              <Form.Item
                name="usertype"
                rules={[
                  {
                    required: true,
                    message: "Please enter usertype!",
                  },
                ]}
              >
                <Select
                  name="usertype"
                  onChange={selecthandleChange}
                  placeholder="Please Select Type"
                  style={{ width: "250px" }}
                >
                  <Option value="admin">Admin</Option>
                  <Option value="contractor">Contractor</Option>
                  <Option value="driver">Driver</Option>
                  <Option value="transpoter">Transporter</Option>
                  <Option value="notifications">Notifications</Option>
                </Select>
              </Form.Item>
              <label>Link to</label>
              <br></br>
              <Select
                name="linkto"
                onChange={selectlinktohandleChange}
                placeholder="Please Select Type"
                style={{ width: "250px" }}
              >
                {userInput.usertype === "driver" ? dropDownOptions : ""}
                {userInput.usertype === "transpoter"
                  ? transporterdropDownOptions
                  : ""}
                {userInput.usertype === "contractor"
                  ? ContractordropDownOptions
                  : ""}
              </Select>
              <br></br> <br></br>
              <Button
                type="primary"
                shape="round"
                htmlType="submit"
                size="large"
              >
                Add User
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};

export default AddUser;

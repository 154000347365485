import React, { useReducer, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { DownloadOutlined } from "@ant-design/icons";
import { Breadcrumb, Row, Col, Table } from "antd";

import { getServerUrl } from "../../config";
import axios from "axios";
const initialList = [];
const ViewReceipts = (props) => {
  const { id } = props.match.params;
  let [responseData, setResponseData] = React.useState("");
  let [purchaseListItem, setpurchaseListItem] = React.useState(initialList);
  let [mListItem, setmListItem] = React.useState(initialList);
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/inventory/rmreceipt/${id}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        if (res.data.success === 1) {
          // console.log("", details);

          setResponseData(details[0]);
        } else {
          window.location.href = "/rm-receipt";
        }
      });
  }, []);
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/inventory/getinvoicelineitem/${id}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const details = res.data;
        if (res.data.success === 1) {
          console.log("", details);
          setpurchaseListItem(details.data);
        } else {
          window.location.href = "/rmpurchase-list";
        }
      });
  }, []);
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/inventory/getinvoicelinetwo/${id}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const details = res.data;
        if (res.data.success === 1) {
          console.log("", details);
          setmListItem(details.data);
        } else {
          window.location.href = "/rmpurchase-list";
        }
      });
  }, []);
  const columnsManual = [
    {
      title: "Batch",
      dataIndex: "batch",
      key: "batch",
    },
    {
      title: "Raw Material Name",
      dataIndex: "raw_material_name",
      key: "componentName",
    },

    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
  ];

  const columns = [
    {
      title: "Batch",
      dataIndex: "batch",
      key: "batch",
    },
    {
      title: "Raw Material Name",
      dataIndex: "raw_material_name",
      key: "componentName",
    },
    {
      title: "Raw Material Type",
      dataIndex: "raw_material_type_name",
      key: "raw_material_type_name",
    },
    {
      title: "Purchase Order",
      dataIndex: "vendorinvoice",
      key: "vendorinvoice",
    },
    {
      title: "Diameter",
      dataIndex: "variant",
      key: "variant",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },

    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) =>
        record.quantity + " " + record.raw_material_type_unit,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
  ];
  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Inventory</Breadcrumb.Item>
        <Breadcrumb.Item>RM Receipt Note</Breadcrumb.Item>
        <Breadcrumb.Item>Create New</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background">
        <h2 className="titles">
          RECEIPT <span>NOTE</span>
        </h2>
        <Row>
          <Col span={16}>Contractor Name: {responseData.vendor_name}</Col>
          <Col span={16}>Invoice No: {responseData.invoice_no}</Col>
          <Col span={16}>Invoice Date: {responseData.invoice_date}</Col>
          <Col span={16}>DC Number: {responseData.dc_no}</Col>
        </Row>
      </div>

      <div className="site-layout-background">
        <h2 className="titles">RECEIPT NOTE ITEMS</h2>
        <Row>
          <div
            className="ant-table-content"
            style={{ overflow: "auto hidden" }}
          >
            <Table columns={columns} dataSource={purchaseListItem}></Table>
          </div>
        </Row>
      </div>

      <div className="site-layout-background">
        <h2 className="titles">MANUAL ITEMS</h2>
        <Row>
          <div
            className="ant-table-content"
            style={{ overflow: "auto hidden" }}
          >
            <Table columns={columnsManual} dataSource={mListItem}></Table>
          </div>
        </Row>
      </div>
    </div>
  );
};

export default ViewReceipts;

import React, { Component, useState } from "react";
import { NavLink } from "react-router-dom";
import { getServerUrl } from "../../config";
import axios from "axios";
import {
  Table,
  Space,
  Breadcrumb,
  Select,
  Input,
  Button,
  Row,
  Col,
  Tag,
} from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
const { Option } = Select;
class VariantOrderStock extends Component {
  constructor() {
    super();
  }
  state = {
    id: "",
    details: [],
    newdetails: [],
    data: [],
  };

  componentDidMount() {
    const details = [];
    axios
      .get(
        `${getServerUrl()}/inventory/variant-order-stock/${
          this.props.match.params.vendor +
          "/" +
          this.props.match.params.process +
          "/" +
          this.props.match.params.orderid
        }`,
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      )
      .then((res) => {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        this.setState({ details });
      });
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  render() {
    const data = this.state.details;
    const columns = [
      {
        title: "Stock Created Date",
        dataIndex: "create_date",
        key: "create_date",
        ...this.getColumnSearchProps("create_date"),
      },
      {
        title: "Contractor Name",
        dataIndex: "contractor_name",
        key: "contractor_name",
        ...this.getColumnSearchProps("contractor_name"),
      },

      {
        title: "Batch No",
        dataIndex: "batch_no",
        key: "batch_no",
        ...this.getColumnSearchProps("batch_no"),
      },
      {
        title: "Component Name",
        dataIndex: "component_name",
        key: "component_name",
        ...this.getColumnSearchProps("rawvariant"),
      },
      {
        title: "Component No",
        dataIndex: "component_no",
        key: "component_no",
        ...this.getColumnSearchProps("component_no"),
      },
      {
        title: "Component Quantity",
        dataIndex: "quantity",
        key: "quantity",
      },
    ];

    return (
      <div>
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>Inventory </Breadcrumb.Item>
          <Breadcrumb.Item>Raw Material Stock</Breadcrumb.Item>
          <Breadcrumb.Item>Order to Stock</Breadcrumb.Item>
          <Breadcrumb.Item>{this.props.match.params.orderid}</Breadcrumb.Item>
        </Breadcrumb>
        <div
          className="site-layout-background"
          style={{ padding: 24, minHeight: 360 }}
        >
          <h2 className="titles">
            {this.state.type}
            <span> ORDER TO STOCK</span> {this.props.match.params.orderid}
          </h2>

          <Space></Space>
          <br />
          <Table columns={columns} dataSource={data}></Table>
        </div>
      </div>
    );
  }
}

export default VariantOrderStock;

import React, { useReducer } from "react";
import { NavLink } from "react-router-dom";
import { Breadcrumb, Row, Col, Form, Input, Button, notification } from "antd";
import { getServerUrl } from "../../config";
import axios from "axios";
const NewProcess = () => {
  const [form] = Form.useForm();
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      process_name: "",
      process_description: "",
    }
  );

  const handleChange = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;
    setUserInput({ [name]: newValue });
  };
  const openNotification = (type) => {
    notification[type]({
      message: "Process Added",
      description: "Process added successfully",
    });
  };
  const FailNotification = (type) => {
    notification[type]({
      message: "Error",
      description: "Please Fill out Required Fields",
    });
  };
  const onFinish = (values) => {
    console.log(values);
  };
  const onReset = () => {
    form.resetFields();
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (userInput.process_name !== "" && userInput.process_description !== "") {
      axios
        .post(`${getServerUrl()}/master/createprocess`, userInput, {
          headers: { "x-auth-token": localStorage.getItem("token") },
        })
        .then((res) => {
          const data = res.data;
          //console.log(data);
          if (data.success === 1) {
            setUserInput({
              process_name: "",
              process_description: "",
            });
            openNotification("success");
          } else {
          }
        });
    } else {
      FailNotification("error");
    }
  };
  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Master Settings </Breadcrumb.Item>
        <Breadcrumb.Item>Process Settings</Breadcrumb.Item>
        <Breadcrumb.Item>Create New</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background">
        <h2 className="titles">
          CREATE NEW <span>PROCESS</span>
        </h2>

        <Row>
          <Col span={16}>
            <h2>I. New Process Name & Details</h2>
            <label>Process Name</label>
            <Input
              name="process_name"
              value={userInput.process_name}
              onChange={handleChange}
              rules={[
                {
                  required: true,
                  message: "Please enter username!",
                },
              ]}
            />
            <label>Process Description</label>
            <Input
              name="process_description"
              value={userInput.process_description}
              onChange={handleChange}
              rules={[
                {
                  required: true,
                  message: "Please enter username!",
                },
              ]}
            />
            <br></br>
            <br></br>
            <Button
              type="primary"
              shape="round"
              size="large"
              onClick={handleSubmit}
            >
              Add Process
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default NewProcess;

import React from "react";
import { Breadcrumb, Row, Col, Space, Card } from "antd";
import { BrowserRouter as Router, NavLink } from "react-router-dom";
function Setting() {
  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Master Settings </Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background" style={{ padding: 24 }}>
        <h2 className="titles">
          MASTER <span>SETTINGS</span>
        </h2>
        <p>Select Module</p>
        <Row>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/rawmaterial-setting">
                <span className="icons icon-raw-matrial"></span>
                RAW MATERIAL
              </NavLink>
            </div>
          </Col>
          {/*<Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/process-setting">
                <span className="icons icon-process"></span>
                PROCESSES
              </NavLink>
            </div>
  </Col>*/}
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/contractor-setting">
                <span className="icons icon-vendors"></span>
                CONTRACTORS
              </NavLink>
            </div>
          </Col>
        </Row>
      </div>
      <div className="site-layout-background">
        <Row>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/transport-setting">
                <span className="icons icon-assign-vehicle"></span>
                TRANSPORTERS
              </NavLink>
            </div>
          </Col>
          {/* <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/components-setting">
                <span className="icons icon-component-data"></span>
                COMPONENTS
              </NavLink>
            </div>
          </Col>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/purchase-setting">
                <span className="icons icon-purchase-order"></span>
                PURCHASE ORDERS
              </NavLink>
            </div>
  </Col>*/}
        </Row>
      </div>
      <div className="site-layout-background">
        <Row>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="#">
                <span className="icons icon-rm-receipt"></span>
                INVOICES
              </NavLink>
            </div>
          </Col>
          <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="#">
                <span className="icons icon-scrap-data"></span>
                SCRAP
              </NavLink>
            </div>
          </Col>
          {/* <Col xs={12} xl={8}>
            <div className="LinksCard">
              <NavLink to="/users">
                <span className="icons icon-vendor-data"></span>
                Users
              </NavLink>
            </div>
</Col>*/}
        </Row>
      </div>
    </div>
  );
}

export default Setting;
